<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"> </div>
        </nav>
    </div>

    <section class="container-fluid mt-md-5 pt-md-4">
        <div class="row mb-2">
            <div class="col-lg-12 col-md-12 cetifi_img">
                <div class="row p-1">
                    <div class="col-md-4 col-4 mb-md-0 meaimg px-md-3 px-1">
                        <div class="d-lg-flex align-items-center">
                            <img src="assets/images/clients/clients-logo-5.png" class="px-md-0 ml-md-4" alt=""
                                style="height: 50px;">

                            <span class="text-center d-md-block d-none pl-lg-4 pl-md-0 approved">
                                <p class="mb-0 approved">{{ 'company_deatial.ciides1' | translate }}</p>
                                <p class="mb-0 approved">{{ 'company_deatial.ciides2' | translate }}</p>
                            </span>
                        </div>

                    </div>
                    <div class="col-md-4 col-4 mb-md-0 meaimg px-md-3 px-1">
                        <div class="d-lg-flex align-items-center">
                            <img src="assets/images/minioeaft.png" class="px-md-0 ml-md-4" alt="" style="height: 50px;">

                            <span class="text-center d-md-block d-none pl-lg-4 pl-md-0 approved">
                                <p class="mb-0 approved">{{ 'company_deatial.mae' | translate }}</p>
                                <p class="mb-0 approved">{{ 'company_deatial.Regno' | translate }}</p>
                            </span>
                        </div>

                    </div>
                    <div class="col-md-4 col-4 mb-md-0 px-md-3 px-1">
                        <div class="d-lg-flex align-items-center">
                            <img src="assets/images/nsdc_new2.png" class=" ml-md-4" alt="" style="height: 50px;">
                            <span class="text-center d-md-block d-none pl-lg-4 pl-md-0 approved">
                                <p class="mb-0 approved">{{ 'company_deatial.NSDC' | translate }}</p>
                                <p class="mb-0 approved">{{ 'company_deatial.Japan_OTIT' | translate }}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 cetifi_button">

                <div class="neplbuttonshome">

                    <li class="btn_top ">
                        <a routerLink="/schedule_session" class="theme-btn-2 mr-lg-3">{{ 'header-one.Join a Demo Class'
                            | translate }}</a>
                    </li>

                    <li class="btn_top ">
                        <a href="https://ncareers.org/" target="_blank" class="theme-btn-onenepl">Ncareers.org</a>
                    </li>

                </div>
            </div>
        </div>
    </section>

    <!--Banner Silder-->
    <!-- <section class="banner-section style-one">
        <div class="banner-carousel owl-theme owl-carousel owl-dots-none owl-nav-none">
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>{{ slider1_title }}</span>
                                <h1>{{ slider1_desc1 }}</h1>
                                <p>{{ slider1_desc2 }}</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-2.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>Get your dream job in Japan</span>
                                <h1>By learng language and Technical skills</h1>
                                <p>with Nihon Edutech</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-3.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-7 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>Recruit Trained staff for Japan</span>
                                <h1>with language and technical skills</h1>
                                <p>Trained by our experts at Nihon Edutech</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div>  
                        </div>
                    </div> 
                </div>
            </div>
             <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-3a.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>Nihon Edutech offers solutions in niche technologies that can automate</span>
                                <h1>Business processes and provide a superior ROI to customer’s technology investments.</h1>
                                <p>The solutions offered include Staff Augmentation, Team Augmentation and Project services using onsite, offshore and nearshore delivery models.</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section> -->


    <div class="paperforms-popup" *ngIf="lng == 'en'">
        <!-- (click)="openPopUp()" -->
        <!-- <div class="npf_wgts" data-height="500px" data-w="fbd810a994afdc3854747899fedda22e"></div> -->
        <a routerLink="/enquire" class="theme-button rotated">
            <p style="color: white !important; padding: 0;margin: 0;">Enquire Now</p>
            <!-- <i class="fab fa-wpforms"></i> -->
        </a>
    </div>
    <div class="paperforms-popup" *ngIf="lng == 'ja'">
        <!-- (click)="openPopUp()" -->
        <!-- <div class="npf_wgts" data-height="500px" data-w="fbd810a994afdc3854747899fedda22e"></div> -->
        <a routerLink="/enquire" class="theme-button rotate">
            <p style="color: white !important; padding: 0;margin: 0;">お問い合わせ</p>
            <!-- <i class="fab fa-wpforms"></i> -->
        </a>
    </div>

    <section class="banner-section style-one">
        <div class="banner-carousel owl-carousel owl-dots-none">
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-6 col-md-12 offset-lg-6 content-column">
                            <div class="content-box">
                                <span>{{ slider1_title }}</span>
                                <h1>{{ slider1_desc1 }}</h1>
                                <p>{{ slider1_desc2 }}</p>
                                <div class="btn-box">
                                    <a href="{{slider1_btn_link}}" target="_blank" class="theme-btn-two">{{ 'Home.Learn
                                        More' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider2_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-6 col-md-12 offset-lg-6 content-column">
                            <div class="content-box">
                                <span>{{slider2_title}}</span>
                                <h1>{{slider2_desc1}}</h1>
                                <p>{{slider2_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider2_btn_link}}" target="_blank" class="theme-btn-two">{{ 'Home.Learn
                                        More' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider3_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-6 col-md-12 offset-lg-6 content-column">
                            <div class="content-box">
                                <span>{{slider3_title}}</span>
                                <h1>{{slider3_desc1}}</h1>
                                <p>{{slider3_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider3_btn_link}}" target="_blank" class="theme-btn-two">{{ 'Home.Learn
                                        More' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider4_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-6 col-md-12 offset-lg-6 content-column">
                            <div class="content-box">
                                <span>{{slider4_title}}</span>
                                <h1>{{slider4_desc1}}</h1>
                                <p>{{slider4_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider4_btn_link}}" target="_blank" class="theme-btn-two">{{ 'Home.Learn
                                        More' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Three Card -->
    <!-- <section class="feature-section">
        <div class="outer-container">
            <div id="content_block_4">
                <div class="content-box">
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-7.png" alt=""></figure>
                                        <h3><span>01</span><a routerLink="/learning">{{ 'Home.Learning' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Japanese Training Technical Training' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                       <figure class="icon-box"><img src="assets/images/icons/icon-9.png" alt=""></figure>
                                        <h3><span>02</span><a routerLink="/register">{{ 'Home.Integrated Platform' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>www.nihoncareers.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                         <figure class="icon-box"><img src="assets/images/icons/icon-8.png" alt=""></figure>
                                        <h3><span>03</span><a routerLink="/jobs_in_japan_semi_skilled">{{ 'Home.Jobs in Japan' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Semi Skilled Professionals' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- <section class="feature-section">
        <div class="outer-container">
            <div id="content_block_4">
                <div class="content-box">
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="00ms"
                                    data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-7.png" alt="">
                                        </figure>
                                        <h3><a routerLink="/japanese-training">{{ 'Home.Learning' | translate }}<i
                                                    class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Japanese Training Technical Training' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="300ms"
                                    data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-9.png" alt="">
                                        </figure>
                                        <h3><a href="https://ncareers.org/" target="_blank">{{ 'Home.IntegratedPlatform'
                                                | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.www.nihoncareers.com' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="600ms"
                                    data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-8.png" alt="">
                                        </figure>
                                        <h3><a routerLink="/jobs_in_japan_professional">{{ 'Home.Jobs in Japan' |
                                                translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Semi Skilled Professionals' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- image -->

    <section *ngIf="lng == 'ja'">

        <div class="container-fluid" style="justify-content: center;">
            <div class="sec-title centred">
                <h2 style=" font-weight: 900;margin-top: 31px;">⼈材不⾜でお困りですか？</h2>
                <h2 style=" font-weight: 900;margin-top: 31px;">     オンラインで優秀な候補者を⾒つけましょう！！</h2>
            </div>

       

            <!-- <div class="d-flex justify-content-center flex-wrap">
                <figure class="snip1163" *ngFor="let item of jobimages let i=index">
                    <img src="{{item.image}}" style="height: 100%;" alt="sample48" *ngIf="i <3 ">
                    <figcaption>
                        <h2>{{item.title}}</h2>
                        <div> -->
            <!-- <p>{{item.description}}</p> -->
            <!-- </div>

                    </figcaption>
                </figure> -->
            <!-- <figure class="snip1163">
              <img src="../../../assets/images/Rectangle 45 (1).png" alt="sample68" />
              <figcaption>
                <h2>Find placement in industry<br> of your choice</h2>
                <div>
                  <p>I'm a simple man with complex tastes.</p>
                </div>
                <a href="#"></a>
              </figcaption>
            </figure> -->
            <!-- </div> -->

            <div class="row justify-content-center mt-4">
                <div class="col-md-6 col-lg-4">
                    <div class="card-body text-center" style="background-color: #0353a7; border-radius: 12px;">
                        <h2 class="card-title" style="color: white;">Ncareers をお試しください</h2>
                        <a href="https://ncareers.org/" class="btn btn-danger btn-lg mt-2 " target="_blank"
                            style="background-color: #eb011c; border-radius: 12px;">Ncareers.org</a>
                    </div>
                </div>
            </div>
            <h2 class="card-title centred mt-4 " style="color: #0353a7;">日本エデュテックは日本への送り出しに特化した送り出し企業です</h2>
     

        </div>
    </section>

    <section *ngIf="lng == 'ja'">

        <div class="container-fluid" style="justify-content: center;">
            <div class="sec-title centred"  >
                <h2 style=" font-weight: 900;margin-top: 31px;">私たちのこれまでの送り出し数<br>{{ calculateTotal() }} + ⼈</h2>
            </div>
<!-- <div class="auto-container row">
    <div class="row">
        <div class="auto-container col-md-4" *ngFor="let item of mech">
            <div class="box">
              <div class=""><img style="height: 150px; height: 188px;
                width: 300px;" src="{{item.image}}"></div>
              <div class="">
                <h2  style="padding: 12px; text-align: center;">{{item.Location}} : {{item.placement}}+ 人</h2>
           
              </div>
            </div>
          </div>
      
         
    
    
    </div>
 

</div> -->
<div class="auto-container row" (mouseenter)="onCardHover($event)">
    <div class="row">
      <div class="auto-container col-md-4" *ngFor="let item of mech; let i = index">
        <div class="box" (mouseover)="onCardMouseOver(i)" (mouseleave)="onCardMouseLeave()">
          <div class=""><img style="height: 150px; height: 188px; width: 300px;" src="{{item.image}}"></div>
          <div class="">
            <h2 style="padding: 12px; text-align: center;">{{item.Location}} : {{item.placement}}+ 人</h2>
          </div>
        </div>
      </div>
    </div>
   
    <!-- Static content for the last card -->
    <div *ngIf="showStaticContent" class="">
        <div class="card10">
          
            <div class="card-content">
          
            
                <p class="card-title">    その他のカテゴリー </p>
              <p class="">機械検査・機械保全</p>
              <p class="">      食品製造</p>
                 <p class="">      仕立て</p>
                 <p class="">      農業</p>
          
            </div>
          </div>
    </div>
  </div>
  


            <!-- <div class="d-flex justify-content-center flex-wrap">
                <figure class="snip1163" *ngFor="let item of jobimages let i=index">
                    <img src="{{item.image}}" style="height: 100%;" alt="sample48" *ngIf="i <3 ">
                    <figcaption>
                        <h2>{{item.title}}</h2>
                        <div> -->
            <!-- <p>{{item.description}}</p> -->
            <!-- </div>

                    </figcaption>
                </figure> -->
            <!-- <figure class="snip1163">
              <img src="../../../assets/images/Rectangle 45 (1).png" alt="sample68" />
              <figcaption>
                <h2>Find placement in industry<br> of your choice</h2>
                <div>
                  <p>I'm a simple man with complex tastes.</p>
                </div>
                <a href="#"></a>
              </figcaption>
            </figure> -->
            <!-- </div> -->

      

        </div>
        <P style="text-align: center;">当社の送り出し実績に興味がありますか？<a href="/enquire">こちら</a>をクリックしてください</P>     
    </section><br>

    <section class="container" *ngIf="lng == 'ja'">
        <h5 class="sec-title centred" style="font-weight: 700;font-size: 20px;"> {{ 'resourcing_semi_skilled.nihon18' | translate }}

        </h5>
        <div class="">
            <div class='main-table-containter' >
                <div class='title-table-container'>
                    <!-- <div class='subtitle'>Transacciones</div> -->

                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th style="color: #0353a7;    font-size: 20px;" colspan="6">送り出し可能な人材の数</th>
                                
                                 <th style="color: #0353a7;    font-size: 20px;">
                                    送り出し人数</th>
                            </tr>
                            <tr>
                                <th>職種</th>
                                <th>合計</th>
                                <th>N5</th>
                                <th>N4</th>
                                <th>N3</th>
                                <th>特定技能

                                </th>
                                <th>	
                                    勉強中</th>
                              
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="font-size: 15px;" *ngFor="let item of waiting">
                                <td><span class="completado">{{item.content}}</span></td>
                                <td>{{ item.content5 + item.content6 + item.content7 + + item.content2 }}</td>
                                <td>{{item.content7}}</td>
                                <td>{{item.content6}}</td>
                                <td>{{item.content5}}</td>
                                <td>{{item.content3}}</td>
                                <td>{{item.content2}}</td>
                            
                                <td>{{item.content4}}</td>
                            </tr>
                            <!-- Add more rows as needed -->
                        </tbody>
                    </table>
                    <!-- <table class="" >
                     
                        <tbody>
                            <tr style="font-size: 22px; color: black;font-weight: bolder;">
                                <td>Occupation/Education </td>

            
                                
                                    <td>N5</td>
                                    <td>N4</td>
                                    <td>N3</td>
                            
                                 
                                    <td>SSW ready</td>
                                    <td>candidates </td>
                                
                                    <td>Placed Candidates</td>
                 
                            </tr>

                            <tr style="font-size: 15px;" *ngFor="let item of waiting">
                                <td><span class="completado">{{item.content}}</span><br>
                                  
                                </td>
                                <td>{{item.content7}}
                                </td>
                                <td>{{item.content6}}</td>
                                <td>{{item.content5}}
                                </td>
                                <td>{{item.content3}}
                                </td>
                                <td>{{ item.content5 + item.content6 + item.content7 }}</td>
                                <td>{{item.content4}}
                                </td>
                             
                               
                          
                              
                 
                            </tr>


                        
                        </tbody>
                    </table> -->
                </div>
              

            </div>
        

        </div><br>
        <div  >
            <p style="text-align: end;" *ngIf="lng == 'ja'">送り出し総数:<strong > {{ getTotalPlacement1()}}人</strong></p>
        </div>
        <div  >
            <p style="text-align: end;">最終更新日<strong *ngFor="let item of waiting"> {{item.content9}}</strong></p>
        </div>
        <p class="text-center">私たちのデータベースには、N1やN2を所有するITやエンジニアの候補者もいます。</p>
   

      
       
 

    <p class="text-center" >ぴったりの候補者が見つかりましたか？ <a href="/enquire">こちら</a>をクリックして、ぜひお問い合わせください。</p>
    <!-- <p class="text-center"　*ngIf="lng == 'en'">ぴったりの候補者が見つかりましたか？ <a href="/enquire">こちら</a>をクリックして、ぜひお問い合わせください</p> -->

</section>

<section class="clients-section alternet-2 py-5"  *ngIf="lng == 'ja'">
    <div class="col-xl-12 col-lg-12 col-sm-12">
        <h2 class="clients_ttl">{{ 'Home.Our Clients' | translate }}</h2>
    </div>
    <div class="outer-container">
        <owl-carousel-o [options]="customOptionsClient">
            <ng-container *ngFor="let item of client">
                <ng-template carouselSlide>
                    <figure class="clients-logo-box">
                        <a href="{{item.website_url}}" target="_blank"><img src="{{item.logo}}" alt=""></a>
                        <span class="logo-title"><a href="{{item.website_url}}" target="_blank">{{ 'Home.Visit
                                Website' | translate
                                }}</a></span>
                    </figure>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
            <figure class="clients-logo-box">
                <a href="index.html"><img src="assets/images/clients/clients-logo-4.png" alt=""></a>
                <span class="logo-title"><a href="index.html">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.cii.in/" target="_blank"><img src="assets/images/clients/clients-logo-5.png" alt=""></a>
                <span class="logo-title"><a href="https://www.cii.in/" target="_blank">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.maxell.com/" target="_blank"><img src="assets/images/clients/clients-logo-7.png" alt=""></a>
                <span class="logo-title"><a href="https://www.maxell.com/" target="_blank">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/clients-logo-8.png" alt=""></a>
                <span class="logo-title"><a href="https://minusculetechnologies.com/" target="_blank">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="http://www.simplexsol.com/" target="_blank"><img src="assets/images/clients/clients-logo-9.png" alt=""></a>
                <span class="logo-title"><a href="http://www.simplexsol.com/" target="_blank">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.udtrucks.com/" target="_blank"><img src="assets/images/clients/clients-logo-10.png" alt=""></a>
                <span class="logo-title"><a href="https://www.udtrucks.com/" target="_blank">Visit Website</a></span>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.fujifilm.com/" target="_blank"><img src="assets/images/clients/clients-logo-6.png" alt=""></a>
                <span class="logo-title"><a href="https://www.fujifilm.com/" target="_blank">Visit Website</a></span>
            </figure>
            
        </div> -->
    </div>
</section>

<section class="clients-section alternet-2"  *ngIf="lng == 'ja'">
    <div class="outer-container">
        <div class="col-xl-12 col-lg-12 col-sm-12">
            <h2 class="clients_ttl">{{ 'Home.Our Partners' | translate }}</h2>
        </div>
        <owl-carousel-o [options]="customOptionsClient">
            <ng-container *ngFor="let item of partner">
                <ng-template carouselSlide>
                    <figure class="clients-logo-box">
                        <a href="{{item.website_url}}" target="_blank"><img src="{{item.logo}}" alt=""
                                class="partners_img"></a>
                        <span class="logo-title"><a href="{{item.website_url}}" target="_blank">{{ 'Home.Visit
                                Website' | translate
                                }}</a></span>
                    </figure>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
            <figure class="clients-logo-box">
                <a href="https://www.securekloud.com/" target="_blank"><img src="assets/images/clients/partnr_img1.png" alt=""></a>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.steepgraph.com/" target="_blank"><img src="assets/images/clients/partnr_img2.png" alt=""></a>
            </figure>
            <figure class="clients-logo-box">
                <a href="http://www.rajasri.com/" target="_blank"><img src="assets/images/clients/partnr_img3.png" alt=""></a>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/partnr_img4.png" alt=""></a>
            </figure>
            <figure class="clients-logo-box">
                <a href="https://www.avvanz.com/"><img src="assets/images/clients/partnr_img5.png" alt=""></a>
            </figure>
        </div> -->
    </div>
</section>

    <section class="auto-container row" *ngIf="lng == 'ja'">

        <div class="container-fluid" style="justify-content: center;">
            <div class="sec-title centred">
                <h2 style=" font-weight: 900;margin-top: 31px;">日本エデュテックの特徴</h2>
            </div><br>

        <div class=" row ">
            <div class=" row col-md-12 col-sm-12" style="display: flex;" >
              <div class="col-md-6 col-sm-12"><img class="imagewrap"   src="assets/images/qt.png"></div>
              <div class="box-text col-md-6 col-sm-12 " >
                <h2>日本のマナーとエチケットのトレーニング</h2>
                <li style="list-style: disc;">日本特有の習慣や、礼儀作法の訓練を徹底的に行っております。</li>
                    <li style="list-style: disc;">日本の慣習に倣った校則を導入しております。</li> <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> -->
              </div>
            </div>
          </div>
          <div class=" row  mt-4 ">
            <div class=" row col-md-12 col-sm-12" style="display: flex;">
                <div class="box-text col-md-6 col-sm-12">
                    <h2>日本文化を体験することのできるクラスの提供</h2>
                    <li style="list-style: disc;">カリキュラムの一部として、日本文化のレッスンを提供しております。</li>
                        <li style="list-style: disc;">講義形式と体験型の授業を組み合わせることで、日本についてより理解を深めることができます。</li>  <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> -->
                  </div>
                <div class="col-md-6 col-sm-12"><img  class="imagewrap"  src="../../../assets/images/ct.png"></div>
             
            </div>
          </div>
          <div class=" row  mt-4">
            <div class=" row col-md-12 col-sm-12" style="display: flex;">
                <div class="col-md-6 col-sm-12"><img  class="imagewrap"  src="../../../assets/images/compliance-icon-illustration-compliance-regulations-standard-requirements-governance-law-infographic-template-presentation-concept-banner-pictogram-icon-set-icons-vector.jpg"></div>
              <div class="box-text col-sm-12 col-md-6">
                <h2>コンプライアンスの徹底</h2>
                
                <li style="list-style: disc;">日本基準に沿ったコンプライアンスを遵守しています。</li>
                    <li style="list-style: disc;">プロセス全体において細心の注意を払い、法令遵守を徹底しています。</li><!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> -->
              </div>
            </div>
          </div>
          <div class=" row  mt-4 ">
            <div class=" row col-md-12 col-sm-12" style="display: flex;">
                <div class="box-text col-md-6 col-sm-12 ">
                    <h2>日本語教師による熱心な指導</h2>
                    <li style="list-style: disc;">ネイティブの日本人教師とインド人教師からなる多彩な教員陣を擁し、そのうち50%以上が日本人教師です。</li>
                        <li style="list-style: disc;">日本語教育から仕事での活躍に必要なスキル習得まで、日本人教師が各ステップで重要な役割を果たします。</li>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> -->
                  </div>
                <div class="col-md-6 col-sm-12"><img  class="imagewrap"  src="../../../assets/images/yt.png"></div>
            
            </div>
          </div>
         
    
 
 

            <!-- <div class="d-flex justify-content-center flex-wrap">
                <figure class="snip1163" *ngFor="let item of jobimages let i=index">
                    <img src="{{item.image}}" style="height: 100%;" alt="sample48" *ngIf="i <3 ">
                    <figcaption>
                        <h2>{{item.title}}</h2>
                        <div> -->
            <!-- <p>{{item.description}}</p> -->
            <!-- </div>

                    </figcaption>
                </figure> -->
            <!-- <figure class="snip1163">
              <img src="../../../assets/images/Rectangle 45 (1).png" alt="sample68" />
              <figcaption>
                <h2>Find placement in industry<br> of your choice</h2>
                <div>
                  <p>I'm a simple man with complex tastes.</p>
                </div>
                <a href="#"></a>
              </figcaption>
            </figure> -->
            <!-- </div> -->

      

        </div>

    </section><br>
    <P  *ngIf="lng == 'ja'" style="text-align: center;">コースの詳細に興味がありますか？<a href="/japanese-training">こちら</a>をクリックしてください。</P>  


    <section *ngIf="lng == 'ja'">

        <div class="container-fluid" style="justify-content: center;">
            <div class="ec-title centred">
                <h2 style=" font-weight: 900;margin-top: 31px;">センター紹介</h2>
            </div>
<div class="row">
    <div class=" col-lg-12 col-md-12 mt-2">
        <div class="card-image-container">
            <img src="../../../assets/images/Heading (12) 1.png" >
        </div>
     
        <!-- <div class="card-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
          </svg>
          <span class="card-btn-text">Watch Video</span>
        </div> -->
      </div>


</div>
 

            <!-- <div class="d-flex justify-content-center flex-wrap">
                <figure class="snip1163" *ngFor="let item of jobimages let i=index">
                    <img src="{{item.image}}" style="height: 100%;" alt="sample48" *ngIf="i <3 ">
                    <figcaption>
                        <h2>{{item.title}}</h2>
                        <div> -->
            <!-- <p>{{item.description}}</p> -->
            <!-- </div>

                    </figcaption>
                </figure> -->
            <!-- <figure class="snip1163">
              <img src="../../../assets/images/Rectangle 45 (1).png" alt="sample68" />
              <figcaption>
                <h2>Find placement in industry<br> of your choice</h2>
                <div>
                  <p>I'm a simple man with complex tastes.</p>
                </div>
                <a href="#"></a>
              </figcaption>
            </figure> -->
            <!-- </div> -->

      

        </div>
        <!-- <P style="text-align: center;">コースの詳細に興味がありますか？<a href="">こちら</a>をクリックしてください</P>      -->
    </section>


    

 
    <section  *ngIf="lng == 'en'">

        <div class="container-fluid" style="justify-content: center;">
            <div class="sec-title centred">
                <h2 style="font-size: 30px; font-weight: 900;margin-top: 31px;">Want to Work in Japan?</h2>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-6 mb-3" *ngFor="let item of jobimages let i=index">
                        <div class="card want_card" *ngIf="i <3 ">
                            <!-- <img *ngIf="i <3 " src="https://www.nautec.com/wp-content/uploads/2018/04/placeholder-person.png" alt=""> -->
                            <h2>{{item.title}}</h2>
                            <!-- <div class="cont">
                                <p class="text-light">{{item.description}}</p>
                                <a class="want_btn" target="_blank" *ngIf="item.jobslink && item.jobslink !== ''"
                                    href="{{item.jobslink}}">Click Here</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="d-flex justify-content-center flex-wrap">
                <figure class="snip1163" *ngFor="let item of jobimages let i=index">
                    <img src="{{item.image}}" style="height: 100%;" alt="sample48" *ngIf="i <3 ">
                    <figcaption>
                        <h2>{{item.title}}</h2>
                        <div> -->
            <!-- <p>{{item.description}}</p> -->
            <!-- </div>

                    </figcaption>
                </figure> -->
            <!-- <figure class="snip1163">
              <img src="../../../assets/images/Rectangle 45 (1).png" alt="sample68" />
              <figcaption>
                <h2>Find placement in industry<br> of your choice</h2>
                <div>
                  <p>I'm a simple man with complex tastes.</p>
                </div>
                <a href="#"></a>
              </figcaption>
            </figure> -->
            <!-- </div> -->

            <div class="row justify-content-center mt-4">
                <div class="col-md-6 col-lg-4">
                    <div class="card-body text-center" style="background-color: #0353a7; border-radius: 12px;">
                        <h5 class="card-title" style="color: white;">Sign Up and Embark on your Journey</h5>
                        <a href="https://ncareers.org/" class="btn btn-danger btn-lg " target="_blank"
                            style="background-color: #eb011c; border-radius: 12px;">Ncareers.org</a>
                    </div>
                </div>
            </div>

        </div>
    </section>






    <!-- imge card -->
    <section class="project-section"  *ngIf="lng == 'en'">
        <div class="container-fluid R ">
            <div class="row">
                <div class="col-12">
                    <div class="sec-title text-center">
                        <h2 style="
                font-size: 30px;
                margin-top: 35px;
                font-weight: 900;"> Current Offerings</h2>
                    </div>

                </div>
            </div>
            <div id="accordion">
                <div class="container">
                    <div class="row">
                        <!-- <div class="col-md-2"></div> -->
                        <div class="col-md-12">
                            <div class="neko2" style="display: flex;justify-content: space-between;">
                                <div class="d-flex btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn jobposttoggle w-100 active" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <input type="radio" name="options" id="option1" autocomplete="off" checked> <i
                                            class="fa fa-building" aria-hidden="true"></i>
                                         Skilled Workers
                                    </label>
                                    <label class="btn jobposttoggle w-100 collapsed " data-toggle="collapse"
                                        data-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">
                                        <input type="radio" name="options" id="option2" autocomplete="off"> <i
                                            class="fa fa-desktop" aria-hidden="true"></i>
                                        IT & Engineering Jobs
                                    </label>
                                    <label class="btn jobposttoggle w-100 collapsed " data-toggle="collapse"
                                    data-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                                    <input type="radio" name="options" id="option3" autocomplete="off"> <i class="fa fa-graduation-cap" aria-hidden="true"></i>
   Japanese Courses
                                </label>

                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-2"></div> -->
                    </div>

                    <owl-carousel-o [options]="customOptionsjobtitp">

                        <ng-container *ngFor="let item of Sswtitp">
                            <ng-template carouselSlide>

                                <div class="row collapse show" id="collapseOne" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="card-deck deck ">
                                        <div class="col-md-12 mb-5">
                                            <div class="card card1 h-100">
                                                <img class="card-img-top jobimg" src="{{item.image}}"
                                                    alt="Card image cap">
                                                <div class="card-body  " style="height: 330px;
                                        width: 301px;">
                                                    <h5 class="card-title ti">{{item.title}}</h5>
                                                    <ul class="card-text " style="
                          color: #005aabbf;    font-weight: 900;">
                                                        <li><span style="color:#0353a7;">Company:</span>
                                                            {{item.Company}} </li>
                                                        <li><span style="color:#0353a7">Location:</span>
                                                            {{item.Location}}</li>
                                                        <li><span style="color:#0353a7">Experience:</span>
                                                            {{item.Experience}}</li>
                                                        <li><span style="color:#0353a7">Indicative salary per month:</span> {{item.Salary}}
                                                        </li>
                                                    </ul>
                                                    <div class="gopi">
                                                        <a href="{{item.urlLink}}" target="_blank"
                                                            class="btn btn-primary venom  ">Apply
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 51.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Casting worker</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 52.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Welder</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                    </div>
                                </div>


                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>

                <!--  IT Jobs </div> -->
                <div class="container">
                    <owl-carousel-o [options]="customOptionsjobit">

                        <ng-container *ngFor="let item of ITint">
                            <ng-template carouselSlide>
                                <div class="row collapse" id="collapsetwo" aria-labelledby="headingtwo"
                                    data-parent="#accordion">
                                    <div class="card-deck deck ">
                                        <div class="col-md-12 mb-5">
                                            <div class="card card1 h-100">
                                                <img class="card-img-top jobimg" src="{{item.image}}"
                                                    alt="Card image cap">
                                                <div class="card-body " style="height: 330px;
                                            width: 301px;">
                                                    <h5 class="card-title">{{item.title}}</h5>
                                                    <ul class="card-text ti" style="
                          color: #005aabbf;    font-weight: 900;">
                                                        <li><span style="color:#0353a7;">Company:</span>
                                                            {{item.Company}} </li>
                                                        <li><span style="color:#0353a7; ">Location:</span>
                                                            {{item.Location}}</li>
                                                        <li><span style="color:#0353a7;">Experience:</span>
                                                            {{item.Experience}}</li>
                                                        <li><span style="color:#0353a7;">Indicative salary per month:</span> {{item.Salary}}
                                                        </li>
                                                    </ul>
                                                    <div class="gopi"> <a href="{{item.urlLink}}" target="_blank"
                                                            class="btn btn-primary venom ">Apply Now</a></div>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 51.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Casting worker</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 52.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Welder</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                    </div>
                                </div>

                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                </div>

                <div class="container">
                    <owl-carousel-o [options]="customOptionsjapanese">

                        <ng-container *ngFor="let item of japanesecourse">
                            <ng-template carouselSlide>
                                <div class="row collapse" id="collapsethree" aria-labelledby="headingthree"
                                    data-parent="#accordion">
                                    <div class="card-deck deck ">
                                        <div class="col-md-12 mb-5">
                                            <div class="card card1 h-100">
                                                <img class="card-img-top jobimg" src="{{item.image}}"
                                                    alt="Card image cap">
                                                <div class="card-body " style="height: 250px;
                                            width: 301px;">
                                                    <h5 class="card-title">{{item.title}}</h5>
                                                    <ul class="card-text ti" style="
                          color: #005aabbf;    font-weight: 900;">
                                                        <li><span style="color:#0353a7;"></span>
                                                            {{item.Company}} </li>
                                                        <li><span style="color:#0353a7; ">Duration:</span>
                                                            {{item.Location}}</li>
                                                        <!-- <li><span style="color:#0353a7;">Fees:</span>
                                                            {{item.Experience}}</li> -->
                                                            <li *ngIf="item.Experience">
                                                                <span style="color:#0353a7;">Fees:</span>
                                                                {{item.Experience}}
                                                            </li>
                                                            
                                                        <!-- <li><span style="color:#0353a7;">Salary:</span> {{item.Salary}}
                                                        </li> -->
                                                    </ul>
                                                    <div class="gopi"> <a href="{{item.urlLink}}" target="_blank"
                                                            class="btn btn-primary venom ">Learn more</a></div>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 51.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Casting worker</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                        <!-- <div class="col-md-4 mb-5">
                            <div class="card card1 h-100">
                                <img class="card-img-top" src="../../../assets/images/Rectangle 52.png"   alt="Card image cap">
                                <div class="card-body">
                                    <h5 class="card-title">Welder</h5>
                                    <ul class="card-text" style="
                          color: #005aabbf;    font-weight: 700;">
                                        <li>Company: ASV</li>
                                        <li>Location: Osaka,Japan</li>
                                        <li>Experience: 1+ Year</li>
                                        <li>Salary: 25000 INR</li>
                                    </ul>
                                    <a href="#" class="btn btn-primary prime ">Apply Now</a>
                                </div>
                            </div>
                        </div> -->
                                    </div>
                                </div>

                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>

                </div>

            </div>
        </div>
    </section>




    <!-- mapping -->
    <section  *ngIf="lng == 'en'">
        <div class="container" style="justify-content: center;     margin-top: 70px;">
            <div class="row">
                <div class="col-12">
                    <div class="sec-title centred">
                        <h2 style="
                   
                    font-size: 30px;
                    font-weight: 900;
                   
                ">Join our Training & Placement Programs at your preferred Location!</h2>

                    </div>
                </div>

            </div>
            <div class="fire">
                <p class="" style="font-size: 22px;color: #fff;">
                    <span class="common_progarms">Common Programs Across Locations</span>
                </p>

                <div style="font-size: 19px;    margin-top: 6px;
                  margin-bottom: 6px;font-weight: 400;">
                    <li></li>

                    <li style="    font-size: 19px;">Japanese Language & Culture Training</li>
                </div>

                <li style=" font-weight: 400;   ">Technical Intern Training Program(TITP)/Specified Skilled
                    Worker(SSW)/IT & Engineering</li>
            </div>

          

            <div class="row" style="justify-content: center;    margin-top: 14px;">
                <div class="card-deck deck " style="text-align: center;margin-top: 75px;justify-content: center;">
                    <!-- <div class=" col-md-4 mb-5 pr-0"*ngFor="let item of studentTestimonial" >

                        <div class="card card1 h-100 "
                            style="border: 3px solid  #0353a7; background: #dfebfb;height: 480px;">
                            <div class="profile-card__img">
                                <img src="{{item.image}}" style="    height: 100%;" alt="profile card">
                            </div>

                            <div class="card-body" style=" text-align: left; ">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px;
                    margin-top: -50px; text-align: center;">{{item.name}}</h5>
                                <ul class="card-text" style="
                   color: #005aabbf!important;   font-weight: 900;">
                                   <p [innerHTML]="transformHtml(item.description1)"></p>
                                   
                                   




                                </ul>

                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-6 col-lg-4 mb-5 pr-0"    *ngFor="let item of training_center" >
                        
                        <div class="card card2" style="border: 3px solid  #0353a7; background: #dfebfb;width: 350px;">
                            <div class="profile-card__img">
                                <img src="{{item.image}}" style="    height: 100%;"
                                    alt="profile card">
                            </div>

                            <div class="card-body" style=" text-align: center; ;">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px; margin-top: -65px;;">
                                    {{item.district}},<br>{{item.state}}</h5>
                                <ul class="card-text" style="
                 color: #005aabbf;   font-weight: 900;">

                                    <div class="fire2" style="font-size: 15px;"> <u>Specialization</u>
                                        <li>{{item.sector}}</li>
                                    </div>
                                    <div style="    font-size: 13px;
                                    font-weight: 800; margin-top: 12px;
                                "> New Batch Begins From </div>

                                    <!-- <div style="color: black;">  <p>New Batch Begins:{{item.date}}</p></div> -->
                                    <button class="ribbon" style="    width: 200px;">
                                        <b>{{ item.date | date:'MMMM yyyy' }}
                                        </b>
                                    </button>

                                    <div class="mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <img src="{{item.image1}}"
                                                    class="locationcard" style=" 
                                            border-radius: 50%; " alt="profile card">
                                                <div class="caption">{{item.name1}}</div>
                                            </div>
                                            <div class="col-md-6">
                                                <img src="{{item.image2}}"
                                                    class="locationcard" style="   
                                            border-radius: 50%;" alt="profile card">
                                                <div class="caption">{{item.name2}}</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="{{item.image3}}"
                                                    class="locationcard" style="   
                                                    border-radius: 50%;" alt="profile card">
                                                <div class="caption">{{item.name3}}</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="{{item.image4}}"
                                                    class="locationcard" style="   
                                                        border-radius: 50%;" alt="profile card">
                                                <div class="caption">{{item.name4}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <p style="text-align: center;">*The Training and Placement Program for Japan has also been launched in <strong style="color: #0353a7;"> Hyderabad</strong>  since January 2024   </p>
                    <!-- <div class="col-md-6 col-lg-4 mb-5 pr-0" >
                        <div class="card card2" style="border: 3px solid  #0353a7; background: #dfebfb;width: 350px;">
                            <div class="profile-card__img">
                                <img src="../../../assets/images/Ellipse 6.png" style="    height: 100%;"
                                    alt="profile card">
                            </div>

                            <div class="card-body" style=" text-align: center; ;">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px; margin-top: -65px;;">
                                    Gurugram,<br>Haryana</h5>
                                <ul class="card-text" style="
                 color: #005aabbf;   font-weight: 900;">

                                    <div class="fire2" style="font-size: 15px;"> <u>Specialization</u>
                                        <li>Service Sector</li>

                                    </div>
                                    <div style="color: black;">  <p>New Batch Begins: dd/mm/yyyy</p></div>

                                    <div class="mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/MicrosoftTeams-image (15).png"
                                                    class="locationcard" style=" 
                                            border-radius: 50%; " alt="profile card">
                                                <div class="caption">Care Worker</div>
                                            </div>
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/MicrosoftTeams-image (18).png"
                                                    class="locationcard" style="   
                                            border-radius: 50%;" alt="profile card">
                                                <div class="caption">Accommodation</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/MicrosoftTeams-image (16).png"
                                                    class="locationcard" style="   
                                                    border-radius: 50%;" alt="profile card">
                                                <div class="caption">Food Service</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/MicrosoftTeams-image (19).png"
                                                    class="locationcard" style="   
                                                        border-radius: 50%;" alt="profile card">
                                                <div class="caption">Food Manufacturing</div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4  mb-5 pr-0">
                        <div class="card card2" style="border: 3px solid  #0353a7; background: #dfebfb;width: 350px;">
                            <div class="profile-card__img">
                                <img src="../../../assets/images/Ellipse 5.png" style="    height: 100%;"
                                    alt="profile card">
                            </div>

                            <div class="card-body" style=" text-align: center;;">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px; margin-top: -65px;;">
                                    Chennai,<br>Tamil Nadu</h5>
                                <ul class="card-text" style="
                 color: #005aabbf;   font-weight: 900;">

                                    <div class="fire2" style="font-size: 15px;"> <u>Specialization</u>
                                        <li>IT/Engineering Sector</li>

                                    </div>
                                    <div style="color: black;">  <p>New Batch Begins: dd/mm/yyyy</p></div>

                                    <div class="mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/test/test3.jpeg" class="locationcard"
                                                    style=" 
                                            border-radius: 50%; " alt="profile card">
                                                <div class="caption">Cloud</div>
                                            </div>
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/test/test2.jpeg" class="locationcard"
                                                    style="   
                                            border-radius: 50%;" alt="profile card">
                                                <div class="caption">Fullstack</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/test/test4.jpeg" class="locationcard"
                                                    style=" 
                                                        border-radius: 50%; " alt="profile card">
                                                <div class="caption">Testing</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/test/test1.jpeg" class="locationcard"
                                                    style="   
                                                        border-radius: 50%;" alt="profile card">
                                                <div class="caption">Infra/App Support</div>
                                            </div>
                                        </div>
                                    </div>

                                </ul>

                            </div>
                        </div>
                    </div>

                    <div class=" col-lg-4 col-md-6 mb-5 pr-0">
                        <div class="card card2" style="border: 3px solid  #0353a7; background: #dfebfb;width: 350px;">
                            <div class="profile-card__img">
                                <img src="../../../assets/images/Ellipse 7 (1).png" style="    height: 100%;"
                                    alt="profile card">
                            </div>

                            <div class="card-body" style=" text-align: center;;">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px; margin-top: -65px;;">
                                    Tirunelveli,<br>Tamil Nadu</h5>
                                <ul class="card-text" style="
                 color: #005aabbf;   font-weight: 900;">

                                    <div class="fire2" style="font-size: 15px;"> <u>Specialization</u>
                                        <li>Engineering/Agriculture Sector</li>

                                    </div>

                                    <div style="color: black;">  <p>New Batch Begins: dd/mm/yyyy</p></div>

                                    <div class="mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/MicrosoftTeams-image (14) (1).png"
                                                    class="locationcard" style=" 
                                                border-radius: 50%; " alt="profile card">
                                                <div class="caption">Manufacturing</div>
                                            </div>
                                            <div class="col-md-6">
                                                <img src="../../../assets/images/MicrosoftTeams-image (17).png"
                                                    class="locationcard" style="   
                                                border-radius: 50%;" alt="profile card">
                                                <div class="caption">Construction</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/MicrosoftTeams-image (20).png"
                                                    class="locationcard" style=" 
                                                            border-radius: 50%; " alt="profile card">
                                                <div class="caption">Automobile</div>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <img src="../../../assets/images/MicrosoftTeams-image (13).png"
                                                    class="locationcard" style="   
                                                            border-radius: 50%;" alt="profile card">
                                                <div class="caption">Agriculture</div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>
                    </div> -->
                 
                    <!-- <div class="col-md-4 mb-5">
                        <div class="card card1" style="border: 3px solid  #0353a7; background: #dfebfb;">
                            <div class="profile-card__img">
                                <img src="/assets/images/Ellipse 7.png" style="    height: 100%;"
                                    alt="profile card">
                            </div>

                            <div class="card-body" style="height: 250px;text-align: left; ">
                                <h5 class="card-title" style="color: #eb011c;font-size: 27px;
                  margin-top: -50px;">Tirunelveli,TN</h5>
                                <ul class="card-text" style="
                    color: #005aabbf;   font-weight: 900;">
                                    <li> TITP/SSW Training</li>
                                    <li> TITP/SSW Upskilling</li><br>
                                    <li>Specialization:</li>
                                    <li>Engineering sector</li>
                                    <li style="
                   font-size: 12px;">(Manufacturing,construction,automobile service&repair agriculture etc)</li>






                                </ul>

                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
    </section>










    <!-- What We Do -->
    <!-- <section class="about-style-two about-page">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                         <div id="image_block_1">
                            <div class="image-box">
                                <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-14.png);"></div>
                                <div class="video-inner bdr_r1" style="background-image: url({{whatWeDo.video_image}});">
                                    <a href="{{whatWeDo.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                                <figure class="image-1"><img src="{{whatWeDo.image}}" alt=""></figure>
                                <figure class="image-2 wow slideInLeft animated animated" data-wow-delay="00ms"><img src="{{whatWeDo.images}}" alt="" class="bdr_r"></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box">
                                <div class="sec-title centred">
                                <h2>{{ 'Home.What We do' | translate }}</h2>
                            </div>		   
                                <div class="text">
                                    <p>{{whatWeDo.description}}</p>
                                </div>
                                <div class="author-box">
                                    <div class="author-text">
                                        <h3>{{ 'Home.Get your dream job in Japan' | translate }}</h3>
                                    </div>
                                    <div class="author-info">
                                        <span class="designation"><a routerLink="/register" target=_blank>{{ 'Home.www.nihoncareers.com' | translate }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- <section class="about-style-two about-page">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="pattern-layer"
                                    style="background-image: url(assets/images/shape/pattern-14.png);"></div>
                                <div class="video-inner bdr_r1"
                                    style="background-image: url({{whatWeDo.video_image}});">
                                    <a href="{{whatWeDo.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                                <figure class="image-1"><img src="{{whatWeDo.image}}" alt=""></figure>
                                <figure class="image-2 wow slideInLeft animated animated" data-wow-delay="00ms"><img
                                        src="{{whatWeDo.images}}" alt="" class="bdr_r"></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box">
                                <div class="sec-title centred">
                                    <h2 class="h2_ttl">{{ 'Home.What We do?' | translate }}</h2>
                                </div>
                                <div class="text">
                                    <p [innerHTML]="transformHtml(whatWeDo.description)"></p>
                                    <p>For corporates, we  offer technical services specialising in latest technologies and remote engineers from India.</p>
                                    <p>We serve customers across the industry verticals of Agriculture, Automobile, Construction, Financial Service, Healthcare and Markerng.</p>
                                </div>
                                <div class="author-box">
                                    <div class="author-text">
                                        <h3>{{ 'Home.Get your dream job in Japan' | translate }}</h3>
                                    </div>
                                    <div class="author-info">
                                        <span class="designation"><a routerLink="/register" target=_blank>{{
                                                'Home.www.nihoncareers.com' | translate }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Our Team -->
    <!-- <section class="team-style-four bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Our Executive Team' | translate }}</span>
                <h2>{{ 'Home.Team Behind Nihon Edutech' | translate }}</h2>
                <p>{{ 'Home.We help companies thrive b creating good jobs' | translate }}</p>
            </div>
            <div *ngFor = "let item of teamData" class="row clearfix">
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="{{item.image}}" alt="">
                                <div class="share-box">
                                    <p><a href="{{item.linkedin_url}}"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a routerLink="/">{{item.name}}</a></h3>
                                <span class="designation">{{item.occupation}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Pages Link -->
    <!-- <section class="service-details">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2>{{ 'Home.Our Key Offerings' | translate }}</h2>
                <p>{{ 'Home.Choose a job you love, and you will never have to work a day in your life' | translate }}</p>
            </div>
            <div class="row clearfix">
                <div class="col-md-12 col-sm-12 image-column">
                    <div class="service-details-content">                   
                        <div class="content-four">
                            <div class="inner-box">
                                <div class="row clearfix">
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-book-reader"></i></div>
                                                <a routerLink="/japanese-training"><h5>{{ 'Home.Japanese Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Japanese Language/culture training' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                             <li>
                                                <div class="icon-box"><i class="fas fa-microchip"></i></div>
                                                <a routerLink="/technical_training"><h5>{{ 'Home.Technology Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learn emergent technologies' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>  
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-users"></i></div>
                                                <a routerLink="/learnchain"><h5>{{ 'Home.Learnchain' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learning and Career Aggregation Platform' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fab fa-windows"></i></div>
                                                <a routerLink="/register"><h5>{{ 'Home.Nihon Careers' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Platform to Up-skill and find jobs in Japan' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                         <li>
                                                <div class="icon-box"><i class="fas fa-user-cog"></i></div>
                                                 <a routerLink="/ssw_ssp"><h5>{{ 'Home.Semi Skilled Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Specific Skilled Workers/Program' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-tie"></i></div>
                                                <a routerLink="/it_engineers"><h5>{{ 'Home.IT/ Engineering Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Training and placement of IT Bilingual Talent' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="service-details">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Our Key Offerings' | translate }}</h2>
                <p>{{ 'Home.Choose a job you love, and you will never have to work a day in your life' | translate }}
                </p>
            </div>
            <div class="row clearfix">
                <div class="col-md-12 col-sm-12 image-column">
                    <div class="service-details-content">
                        <div class="content-four">
                            <div class="inner-box">
                                <div class="row clearfix">
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                            <li>
                                                <div class="icon-box"><i class="fas fa-book-reader"></i></div>
                                                <a routerLink="/japanese-training">
                                                    <h5>{{ 'Home.Japanese Training' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Japanese Language/culture training' | translate
                                                                }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fas fa-microchip"></i></div>
                                                <a routerLink="/technical_training">
                                                    <h5>{{ 'Home.Technology Training' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Learn emergent technologies' | translate }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                            <li>
                                                <div class="icon-box"><i class="fas fa-users"></i></div>
                                                <a routerLink="/learnchain">
                                                    <h5>{{ 'Home.Learnchain' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Learning and Career Aggregation Platform' |
                                                                translate }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fab fa-windows"></i></div>
                                                <a href="https://ncareers.org/" target="_blank">
                                                    <h5>{{ 'Home.Nihon Careers' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Platform to Up-skill and find jobs in Japan' |
                                                                translate }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-cog"></i></div>
                                                <a routerLink="/jobs_in_japan_semi_skilled">
                                                    <h5>{{ 'Home.Semi Skilled Jobs' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Specific Skilled Workers/Program' | translate }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-tie"></i></div>
                                                <a routerLink="/jobs_in_japan_professional">
                                                    <h5>{{ 'Home.IT/ Engineering Jobs' | translate }}</h5>
                                                    <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i
                                                                class="flaticon-menu"></i></div>
                                                        <div class="text">
                                                            <p>{{ 'Home.Training and placement of IT Bilingual Talent' |
                                                                translate }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Technical Training Program -->
    <!-- <section class="welcome-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{technical_train.title}}</h2>
                <p>{{technical_train.subtitle}}</p>
            </div>
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                    <figure class="image-box js-tilt"><img src="{{technical_train.image}}" class="brds" alt=""></figure>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="tabs-box">
                                <div class="tab-btn-box">
                                    <ul class="tab-btns tab-buttons clearfix">
                                        <li class="tab-btn active-btn" data-tab="#tab-1">
                                            <i class="employ-icon flaticon-businessman"></i>
                                            <h5>{{ 'Home.Learn Japanese' | translate }}</h5>
                                            <i class="arrow-icon flaticon-up-arrow-2"></i>
                                        </li>
                                        <li class="tab-btn" data-tab="#tab-2">
                                            <i class="employ-icon flaticon-employer"></i>
                                            <h5>{{ 'Home.Upgrade Skills' | translate }}</h5>
                                            <i class="arrow-icon flaticon-up-arrow-2"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabs-content">
                                    <div class="tab active-tab" id="tab-1"
                                        [innerHTML]="transformHtml(technical_train.description1)">
                                        <div class="inner-box">
                                            <h5>Learn Japanese</h5>
                                            <h2>Add Japanese language skill to your resume for more job opportunities
                                            </h2>
                                            <p>Get trained by our experienced teachers and develop business
                                                communication skills in Japanese language.</p>
                                            <p>Join growing number of candidates who have been successfully placed in
                                                Japan by Nihonedutech.</p>
                                            <div class="link"><a href="contactus.html"><i
                                                        class="flaticon-right-arrow"></i>Contact us for free Demo
                                                    class</a></div>
                                        </div>
                                    </div>
                                    <div class="tab" id="tab-2"
                                        [innerHTML]="transformHtml(technical_train.description2)">
                                        <div class="inner-box">
                                            <h5>Upgrade Skills</h5>
                                            <h2>Nihonedutech will help you learn the right skill for a great career in
                                                Japan.</h2>
                                            <p>Up-skilling in niche technical/IT skills in addition to the Japanese
                                                language is key to finding successful job placements in Japan.</p>
                                            <p>Join our Technical training program conducted as per the requirements of
                                                our Japanese clients and get placed in Japan</p>
                                            <div class="link"><a href="contactus.html"><i
                                                        class="flaticon-right-arrow"></i>Know more about technical
                                                    training</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Skills in Demand in Japan -->
    <!-- <section class="about-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{about_sec.title}}</h2>
                <p>{{about_sec.subtitle}}</p>
            </div>
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box" [innerHTML]="transformHtml(about_sec.description1)">
                                <p>Japan is technologically advanced nation and market leader in many sectors of the
                                    global economy. Japan offers greater opportunities for blue-collar and white-collar
                                    jobs. From IT professional to skilled/semi-skilled workers from various sectors like
                                    automobile, mechanical, textile, healthcare, or construction.</p>
                                <p>Irrespective of your educational back ground Nihon edutech trains you in Japanese
                                    language skills and technical skills and makes you ready for TITP and SSW visa.</p>
                                <div class="text">
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-6">
                                            <h3 class="h3_ttl">IT Skills</h3>
                                            <ul class="list_1">
                                                <li>Cloud</li>
                                                <li>Devops</li>
                                                <li>Infrastructure support</li>
                                                <li>Data science </li>
                                                <li>Full stack Development</li>
                                                <li>SaaS Platforms</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <h3 class="h3_ttl">Non IT/ Technical Skills</h3>
                                            <ul class="list_1">
                                                <li>Agriculture</li>
                                                <li>Mechanical</li>
                                                <li>Manufacturing</li>
                                                <li>Construction</li>
                                                <li>Careworker</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i>Upload CV
                                        / Find suitable job</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_2">
                            <div class="content-box centred">
                                <figure class="image-box"><img src="{{about_sec.image}}" class="brds" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Looking for IT professional -->
    <!-- <section class="recruitment-technology bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{recruitment_sec.title}} <br> {{recruitment_sec.subtitle}}</h2>
            </div>
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                    <figure class="image-box js-tilt"><img src="{{recruitment_sec.image}}" class="brds" alt=""></figure>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column"
                    [innerHTML]="transformHtml(recruitment_sec.description1)">
                    <div id="content_block_4">
                        <div class="content-box">
                            <div id="content_block_3">
                                <div class="content-box">
                                    <p><strong>Nihonedutech specialises in recruiting bilingual professionals for
                                            Japanese companies across a broad range of industries and sectors.</strong>
                                    </p>
                                    <p>We focus on efficient matching of Japanese companies that want to hire bilingual
                                        professionals with the right talent across all levels of skill sets.
                                    <p>We can help you quickly and easily find remote engineers for.</p>
                                    <p>Our robust technology platform <a href="https://ncareers.com/"
                                            target="_blank">Ncareers.com</a> helps you find the right candidate for your
                                        requirement.
                                    <p>Explore the advantages of recruiting through us</p>
                                    <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i> You
                                            can post your requirement here</a></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Blogs and news -->
    <section class="service-section Blognews-section"  *ngIf="lng == 'en'">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Blogs & News' | translate }}</h2>
                <p>{{ 'Home.Smarter Business Solutions. Better Customer Relations' | translate }}</p>
            </div>
            <owl-carousel-o [options]="customOptionsBlogNews">
                <ng-container *ngFor="let item of blogNews">
                    <ng-template carouselSlide>
                        <div class="service-block-one">
                            <div class="inner-box">
                                <figure class="image-box"><img src="{{item.image}}" alt="" style="height: 330px;">
                                </figure>
                                <div class="lower-content">
                                    <div class="content-box">
                                        <div class="inner">

                                            <h4>{{item.title}}</h4>
                                        </div>
                                        <div class="link"><a routerLink="['/blogs_news',item.blogs_news_id]">{{
                                                'Home.More Details' | translate }}</a></div>
                                    </div>
                                    <div class="overlay-content">
                                        <p>{{item.description}}</p>
                                        <a routerLink="/blogs_news/{{item.blogs_news_id}}"><i
                                                class="flaticon-right-arrow"></i>{{ 'Home.More Details' | translate
                                            }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>

            <!-- <div class="three-item-carousel owl-carousel owl-theme owl-nav-none owl-dot-style-one">
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Transforming India</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/news/blog1.png" alt=""></figure>
                                    <h4>The world of Kanji</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog3.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>3 Surprising Culture</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Temporary</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""></figure>
                                    <h4>Direct Hire</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog3.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>Contract</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Temporary</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""></figure>
                                    <h4>Direct Hire</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>Contract</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- </div> -->
            <!-- </section> -->

        </div>
    </section>

    <!-- Student Testimonial  nihon v7-->
    <!-- <section class="testimonial-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title">
                <span class="top-title">{{ 'Home.Student Testimonials' | translate }}</span>
                <h2>{{ 'Home.Highest Student Satisfaction' | translate }}</h2>
            </div>
            <div class="testimonial-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <div class="testimonial-block-one"  >
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="{{item.image}}" alt="">
                            </figure>
                            <h3>{{item.name}}<span>{{item.training}}</span></h3>
                        </div>
                        <div class="text">
                            <p>{{item.description1}}</p>
                            <p>{{item.description2}}</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Sheik Mohaideen Badhusha<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>Nihon Edutech is giving several jobs opportunities in Japan such as construction, nursing, agriculture and other sectors, and also providing language and culture training within a short duration.</p>
                            <p>日本語や文化などのトレーニングの短い期間内に日本でいくつかの仕事の機会を与えてくれてる</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Jeyaraman<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I am in Japan, the land of rising sun, for almost 2 1/2 years. I am really glad to be here with very kind Japanese people.</p>
                            <p>約2年半位ここのぼる太陽の国に住んでますここでこんな優しい日本人といるのがとてもうれしいと思い</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Suthakar<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I went to so many places and met so many people here in Japan. I have studied before that Japanese are serious on their work. Now I’m glad to actually experience it.</p>
                            <p>いろんな行ってきましたいろんな人と会いましたNE で勉強中で日本人が仕事で真面目と聞いたことありますけどそれを実際に経験することができていいなぁと思ってます</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- Gallary  nihon v7-->
    <!-- <section class="portfolio-masonry protfolio-page">
        <div class="auto-container">
         <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Our Network & Industry Experience are Unmatched' | translate }}</span>
                <h2>{{ 'Home.Gallery' | translate }}</h2>
            </div>
            <div class="sortable-masonry">
                <div class="items-container row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img5.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon</span>
                                        <h3><a href="#">CareWorker Interns<br> Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img2.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img3.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img4.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">Manufacturing Interns <br />Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img8.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation Recruitment technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img7.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                       <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img6.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">Technical Interns <br />in Japan</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more-btn centred"><a routerLink="/gallery">{{ 'Home.More Gallery' | translate }}</a></div>
            </div>
        </div>
    </section> -->

    <section class="portfolio-masonry protfolio-page Gallery-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Gallery' | translate }}</h2>
                <span>{{ 'Home.Our Network & Industry Experience are Unmatched' | translate }}</span>
            </div>
            <div class="outer-container">
                <owl-carousel-o [options]="customOptionsimage">
                    <ng-container *ngFor="let item of images">
                        <ng-template carouselSlide>
                            <div class="project-block-one">
                                <div id="image_block_1">
                                    <div class="image-box">
                                        <div class="video-inner" style="background-image: url({{item.image}}); height: 200px;">
                                        </div>
                                        <div class="lower-cont">
                                            <h3>{{item.title}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img1.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>Manufacturing Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img2.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img3.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img4.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img5.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div> -->
            </div>
            <div class="more-btn centred"><a routerLink="/gallery">{{ 'Home.More Gallery' | translate }}</a></div>
        </div>
    </section>

    <!-- Videos -->
    <!-- <section class="project-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Training & Opportunities' | translate }}</span>
                <h2>{{ 'Home.Videos' | translate }}</h2>
            </div>
        </div>
        <div class="outer-container">
            <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                                <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>Our Training Program</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>2021-Japan Job Opportunities</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Balaji</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                                <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                                <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Pankaj</h3>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    </section>
     -->
    <section class="videocal-section bg-color-2 py-5"  *ngIf="lng == 'en'">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl pt-3">{{ 'Home.Videos' | translate }}</h2>
                <span>{{ 'Home.Training & Opportunities' | translate }}</span>
            </div>
        </div>
        <div class="outer-container">
            <owl-carousel-o [options]="customOptionsVideos">
                <ng-container *ngFor="let item of videos">
                    <ng-template carouselSlide>
                        <div class="project-block-one">
                            <div id="image_block_1">
                                <div class="image-box">
                                    <div class="video-inner" style="background-image: url({{item.image}});">
                                        <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                            <i class="flaticon-play"></i>
                                        </a>
                                        <div class="border"></div>
                                    </div>
                                </div>
                                <div class="lower-cont">
                                    <h3>{{item.title}}</h3>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                                <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>Our Training Program</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>2021-Japan Job Opportunities</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Balaji</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                                <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                                <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Pankaj</h3>
                        </div>
                    </div>
                </div>                    
            </div> -->
        </div>
    </section>

    <!-- Jobs in Japan nihon v7-->
    <!-- <section class="portfolio-masonry protfolio-page">
        <div class="auto-container">
         <div class="sec-title centred">
                <span class="top-title">Nihon Careers :</span>
                <h2>For Jobs in Japan</h2>
            </div>
            <div class="upper-box">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div class="title-inner">
                            <h2>For Jobs in Japan</h2>
                            <p>Japan has huge demand for low to semi-skilled and highly-skilled professionals.</p>
                            <p><a href="http://www.nihonCareers.com" target="_blank">NihonCareers</a> is the platform services offering on Japanese language and culture training, technical/technology trainings.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                        <div class="inner-box">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-md-6 col-sm-12 column">
                                    <div class="single-item">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-54.png" alt=""></figure>
                                        <h3>345,000</h3>
                                        <p>Demand for technical resources across 14 sectors by 2024</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 column">
                                    <div class="single-item">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-55.png" alt=""></figure>
                                        <h3>780,000</h3>
                                        <p>Demand for Software Engineers by 2030</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->




    <!-- Our Clients-->
    <!-- <section class="clients-section alternet-2">
        <div class="outer-container">
            <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure *ngFor="let item of company" class="clients-logo-box">
                    <a href="{{item.website_url}}"><img src="{{item.logo}}" alt=""></a>
                    <span class="logo-title"><a href="{{item.website_url}}">Visit Website</a></span>
                </figure>
            </div>
        </div>
    </section> -->
    <section class="clients-section alternet-2 py-5"  *ngIf="lng == 'en'">
        <div class="col-xl-12 col-lg-12 col-sm-12">
            <h2 class="clients_ttl">{{ 'Home.Our Clients' | translate }}</h2>
        </div>
        <div class="outer-container">
            <owl-carousel-o [options]="customOptionsClient">
                <ng-container *ngFor="let item of client">
                    <ng-template carouselSlide>
                        <figure class="clients-logo-box">
                            <a href="{{item.website_url}}" target="_blank"><img src="{{item.logo}}" alt=""></a>
                            <span class="logo-title"><a href="{{item.website_url}}" target="_blank">{{ 'Home.Visit
                                    Website' | translate
                                    }}</a></span>
                        </figure>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure class="clients-logo-box">
                    <a href="index.html"><img src="assets/images/clients/clients-logo-4.png" alt=""></a>
                    <span class="logo-title"><a href="index.html">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.cii.in/" target="_blank"><img src="assets/images/clients/clients-logo-5.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.cii.in/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.maxell.com/" target="_blank"><img src="assets/images/clients/clients-logo-7.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.maxell.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/clients-logo-8.png" alt=""></a>
                    <span class="logo-title"><a href="https://minusculetechnologies.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="http://www.simplexsol.com/" target="_blank"><img src="assets/images/clients/clients-logo-9.png" alt=""></a>
                    <span class="logo-title"><a href="http://www.simplexsol.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.udtrucks.com/" target="_blank"><img src="assets/images/clients/clients-logo-10.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.udtrucks.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.fujifilm.com/" target="_blank"><img src="assets/images/clients/clients-logo-6.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.fujifilm.com/" target="_blank">Visit Website</a></span>
                </figure>
                
            </div> -->
        </div>
    </section>

    <section class="clients-section alternet-2"  *ngIf="lng == 'en'">
        <div class="outer-container">
            <div class="col-xl-12 col-lg-12 col-sm-12">
                <h2 class="clients_ttl">{{ 'Home.Our Partners' | translate }}</h2>
            </div>
            <owl-carousel-o [options]="customOptionsClient">
                <ng-container *ngFor="let item of partner">
                    <ng-template carouselSlide>
                        <figure class="clients-logo-box">
                            <a href="{{item.website_url}}" target="_blank"><img src="{{item.logo}}" alt=""
                                    class="partners_img"></a>
                            <span class="logo-title"><a href="{{item.website_url}}" target="_blank">{{ 'Home.Visit
                                    Website' | translate
                                    }}</a></span>
                        </figure>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure class="clients-logo-box">
                    <a href="https://www.securekloud.com/" target="_blank"><img src="assets/images/clients/partnr_img1.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.steepgraph.com/" target="_blank"><img src="assets/images/clients/partnr_img2.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="http://www.rajasri.com/" target="_blank"><img src="assets/images/clients/partnr_img3.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/partnr_img4.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.avvanz.com/"><img src="assets/images/clients/partnr_img5.png" alt=""></a>
                </figure>
            </div> -->
        </div>
    </section>

    <!-- Employment v7-->
    <!-- <section class="advice-section" style="background-image: url(assets/images/background/advice-1.jpg);">
        <div class="auto-container">
            <div class="content-inner clearfix">
                <div class="text pull-left"><h2>Employment Advice for Your Business, <a href="index-3.html">Start Here.</a></h2></div>
                <ul class="social-links pull-right">
                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-google-plus-g"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-youtube"></i></a></li>
                </ul>
            </div>
        </div>
    </section> -->

    <!-- Address Map v7-->
    <!-- <section class="contactinfo-section bg-color-2">
        <div class="image-column" style="background-image: url(assets/images/background/contact-bg-1.jpg);"></div>
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-24.png);"></div>
        <div class="auto-container">
            <div class="row align-items-center clearfix">
                <div class="col-xl-4 col-lg-6 col-md-12 content-column">
                    <div id="content_block_6">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'Home.Endless Opportunities' | translate }}</span>
                                <h2>{{ 'Home.Touch With Us' | translate }}</h2>
                            </div>
                            <ul class="info-list clearfix">
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-39.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{address.country1}}</h4>
                                        <p>{{address.address1}}</p>
                                        <h4>{{address.country2}}</h4>
                                        <p>{{address.address2}}</p>
                                    </div>
                                </li>
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-40.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{ 'Home.Call or Email' | translate }}</h4>
                                        <p>
                                            <a href="tel:{{address.phone1}}">{{address.phone1}}</a><br/>
                                            <a href="mailto:{{address.email1}}">{{address.email2}}</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 map-column">
                    <div class="map-inner">
                       <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7775.781621367692!2d80.225296!3d12.978835000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63894ea6dc3a0704!2sNihon%20Edutech%20Pvt.%20Limited!5e0!3m2!1sen!2sin!4v1616749039445!5m2!1sen!2sin" width="600" height="380" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <div class="modal" id="myModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <!-- <div class="npf_wgts" data-height="500px" data-w="fbd810a994afdc3854747899fedda22e"></div> -->

                <!-- <div class="modal-header">
        <h4 class="modal-title">GeeksForGeeks</h4>
      </div>
      <div class="modal-body">
        <p>One Stop Solution for all CS problems</p>
  
      </div> -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closePopup()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>


    <!-- Modal -->
    <div class="modal fade" id="carouselModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ 'Home.Blogs & News' | translate }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item" *ngFor="let item of blogNews">
                                <div class="service-block-one">
                                    <div class="inner-box">
                                        <figure class="image-box"><img src="{{item.image}}" alt=""></figure>
                                        <div class="lower-content">
                                            <div class="content-box">
                                                <div class="inner">

                                                    <h4>{{item.title}}</h4>
                                                </div>
                                                <div class="link"><a routerLink="['/blogs_news',item.blogs_news_id]">{{
                                                        'Home.More Details' | translate }}</a></div>
                                            </div>
                                            <div class="overlay-content">
                                                <p>{{item.description}}</p>
                                                <a routerLink="/blogs_news/{{item.blogs_news_id}}"><i
                                                        class="flaticon-right-arrow"></i>{{ 'Home.More Details'
                                                    | translate
                                                    }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item active">
                                <div class="service-block-one">
                                    <div class="inner-box">
                                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt="">
                                        </figure>
                                        <div class="lower-content">
                                            <div class="content-box">
                                                <div class="inner">
                                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png"
                                                            alt=""></figure>
                                                    <h4>Transforming India</h4>
                                                </div>
                                                <div class="link"><a href="temprory-staffing.html">More Details</a>
                                                </div>
                                            </div>
                                            <div class="overlay-content">
                                                <p>Explain to you how this idea denouncing pleasure & praising pain was
                                                    born.</p>
                                                <a href="temprory-staffing.html"><i
                                                        class="flaticon-right-arrow"></i>More
                                                    details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <button class="carousel-control-prev" type="button" data-target="#carouselExampleFade" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-target="#carouselExampleFade" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </button> -->
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="modal" [ngClass]="{'modal-open': showModal}">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Carousel Modal</h4>
              <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <owl-carousel-o [options]="customOptionsBlogNews">
                <ng-container *ngFor="let item of blogNews">
                    <ng-template carouselSlide carousel-fit>
                    <div class="service-block-one">
                        <div class="inner-box">
                            <figure class="image-box"><img src="{{item.image}}" alt=""></figure>
                            <div class="lower-content">
                                <div class="content-box">
                                    <div class="inner">

                                        <h4>{{item.title}}</h4>
                                    </div>
                                    <div class="link"><a
                                            routerLink="['/blogs_news',item.blogs_news_id]">{{
                                            'Home.More Details' | translate }}</a></div>
                                </div>
                                <div class="overlay-content">
                                    <p>{{item.description}}</p>
                                    <a routerLink="/blogs_news/{{item.blogs_news_id}}"><i
                                            class="flaticon-right-arrow"></i>{{ 'Home.More Details'
                                        | translate
                                        }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div> -->


    <!-- Modal -->
    <div class="modal fade" id="testModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" style="font-weight: 900;" id="exampleModalLabel">Blogs & News</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <owl-carousel-o [options]="customOptionsBlogNews">
                        <ng-container *ngFor="let item of blogNews">
                            <ng-template carouselSlide [width]="550">
                                <div class="service-block-one">
                                    <div class="inner-box">
                                        <figure class="image-box"><img src="{{item.image}}" style="height: 370px;"
                                                alt="">
                                        </figure>
                                        <div class="lower-content">
                                            <div class="content-box">
                                                <div class="inner">
                                                    <h4>{{item.title}}</h4>
                                                </div>
                                                <div class="link"><a routerLink="['/blogs_news',item.blogs_news_id]">{{
                                                        'Home.More Details' | translate }}</a></div>
                                            </div>
                                            <div class="overlay-content">
                                                <p>{{item.description}}</p>
                                                <a routerLink="/blogs_news/{{item.blogs_news_id}}"><i
                                                        class="flaticon-right-arrow"></i>{{ 'Home.More Details' |
                                                    translate
                                                    }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>


</div>