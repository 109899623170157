<header class="main-header style-two">
    <div class="header-top">
        <div class="top-inner">
             <figure class="logo-box"><a routerLink="/"><img src="assets/images/logo_careers.png" alt=""></a></figure>
            <div class="location-box">
            </div>
            <div class="right-info">
                <ul class="list">
                    <li><a routerLink="/about_us">About Us</a></li>
                    <li><a routerLink="/">Locations</a></li>
                    <li><a routerLink="/resourcing">Resources</a></li>
                </ul>
                <ul class="social-links">
                    <li><a routerLink="/"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a routerLink="/"><i class="fab fa-twitter"></i></a></li>
                    <li><a routerLink="/"><i class="fab fa-google-plus-g"></i></a></li>
                    <li><a routerLink="/"><i class="fab fa-youtube"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div class="header-lower">
        <div class="outer-box clearfix">
           &nbsp;
        </div>
    </div> -->
</header>
