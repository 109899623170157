<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo">
                <a routerLink="/">
                    <img src="assets/images/small-logo.png" alt="" title="">
                </a>
            </div>
            <div class="menu-outer"></div>
        </nav>
    </div>

    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'about-us.Home' | translate }}</a></li>
                    <li>{{ 'about-us.About Us' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>


    <section class="about-style-three" id="learnchain">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title centred">
                        <span class="top-title">{{aboutData.heading}}</span>
                        <h2>{{aboutData.title}}</h2>
                        <p>{{aboutData.subtitle}}</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <figure class="img_pdg"><img src="{{aboutData.image}}" alt="" class="brds"></figure>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="job-details-content">
                                <div class="text" [innerHTML]="aboutData.description">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="h3_ttl">{{ 'about-us.Company Profile' | translate }} :</h3>
            <div [innerHTML]="aboutData.company_profile">
            </div>
            <h3 class="h3_ttl">{{ 'about-us.Company History' | translate }} :</h3>
            <div [innerHTML]="aboutData.company_history"></div>
        </div>
    </section>

    <section class="team-style-four bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'about-us.Our Executive Team' | translate }}</span>
                <h2>{{ 'about-us.Team Behind Nihon Edutech' | translate }}</h2>
                <p>{{ 'about-us.We train resources in Japanese language and technical skills for Japan market' |
                    translate }}</p>
            </div>
            <div class="row clearfix">
                <div *ngFor="let item of teamData; let i = index" class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms"
                        data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="{{item.image}}" alt="">
                                <div class="share-box">
                                    <p><a href="{{item.linkedin_url}}" target="_blank"><i
                                                class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a>{{item.name}}</a></h3>
                                <span class="designation">{{item.occupation}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-2.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/ganapathy-subramanian-1076684/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Ganapathy Subramanian</a></h3>
                                <span class="designation">COO</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-3.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/gunasekaran-natarajan-abb9b711/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Gunasekaran Natarajan</a></h3>
                                <span class="designation">CTO</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-4.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/ramya-babu-a477b39a/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Ramya Babu</a></h3>
                                <span class="designation">Advisor - Training</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-9.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/anuradha-krishnan-2185441/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Anuradha Krishnan</a></h3>
                                <span class="designation">Director - Administration</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-5.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/ranjani-babu-7267b025/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Ranjani Babu</a></h3>
                                <span class="designation">Head - Language Training</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-6.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/shinobu-chibu-3767b66a/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Shinobu Chibu</a></h3>
                                <span class="designation">Advisor</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-7.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/ted-hashimoto-91880ab/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Ted Hashimoto</a></h3>
                                <span class="designation">Advisor</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="assets/images/team/team-8.jpg" alt="">
                                <div class="share-box">
                                    <p><a href="https://www.linkedin.com/in/chandru-sekar-5648494/"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a href="index.html">Dr S. Chandrasekaran</a></h3>
                                <span class="designation">Advisor</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>