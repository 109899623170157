<!-- <p>partner works!</p> -->
<div class="boxed_wrapper">
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <div class="row">
        <div class="col-md-12">
            <embed src="assets/pdf/EN_Single_page_Brochure_TITP_agency_final_version.pdf" class="w-100" style="height: 575rem !important;" />
        </div>
    </div>
    <app-footer></app-footer>
</div>