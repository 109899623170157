<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'contact_us.Home' | translate }}</a></li>
                    <li>{{ 'contact_us.Contact Us' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>

    <section class="contactinfo-section contact-page-section">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-12 col-sm-12 content-column">
                    <div id="content_block_6">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'contact_us.Endless Opportunities' | translate }}</span>
                                <h2>{{ 'contact_us.It’s Your Turn' |
                                    translate }}</h2>
                                <p>{{ "contact_us.We d love to hear from you, please drop us a line if you've any
                                    query."|
                                    translate }}</p>
                            </div>
                            <ul class="info-list clearfix">
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-39.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{address.country1}}</h4>
                                        <!-- <p>#4, 7th Street, Tansi Nagar,<br>
                                            Taramani Link Road,<br>
                                            Velachery, Chennai - 600 042</p> -->
                                        <p>{{address.address1}}</p>
                                        <h4>{{address.country2}}</h4>
                                        <!-- <p>3-20-23, Edakita, Aoba-Ku,<br>
                                            Yokohama-City, Kanagawa,<br>
                                            Japan - 2250015</p> -->
                                        <p>{{address.address2}}</p>
                                    </div>
                                </li>
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-40.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{ 'contact_us.Call or Email' | translate }}</h4>
                                        <p>
                                            <a href="tel:{{address.phone1}}">{{address.phone1}}</a><br />
                                            <a href="mailto:{{address.email1}}">{{address.email1}}</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 form-column">
                    <div class="form-inner">
                        <h2>{{ 'contact_us.Contact Form' | translate }}</h2>
                        <b style="color: red;">{{ errorMsg }}</b>
                        <b style="color: green;">{{ successMsg }}</b>
                        <form method="post" [formGroup]="enqForm" (ngSubmit)="onSubmit()" id="contact-form"
                            class="default-form">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <input type="text" name="username"
                                        placeholder="{{ 'contact_us.Your Name' | translate }} *" formControlName="name"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">{{ 'contact_us.Name is required' | translate
                                            }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <input type="email" name="email_id"
                                        placeholder="{{ 'contact_us.Email Address' | translate }} *"
                                        formControlName="email_id"
                                        [ngClass]="{ 'is-invalid': submitted && f.email_id.errors }">
                                    <div *ngIf="submitted && f.email_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.email_id.errors.required">{{ 'contact_us.Email id is required' |
                                            translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                                    <input type="text" name="phone" required=""
                                        placeholder="{{ 'contact_us.Phone' | translate }} *" formControlName="phone"
                                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">{{ 'contact_us.Phone is required' |
                                            translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                                    <div class="select-box">
                                        <select class="wide" id="subject" name="subject" formControlName="subject"
                                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                                            <option data-display="Subject">{{ 'contact_us.Subject' | translate }}
                                            </option>
                                            <option value="Upskilling (Learnchain)">Upskilling (Learnchain)</option>
                                            <option value="Jobs in Japan (NihonCareers)">Jobs in Japan (NihonCareers)
                                            </option>
                                            <option value="Technology Services">Technology Services</option>
                                            <!-- <option value="Background Screening">Background Screening</option> -->
                                            <option value="Others">Others</option>
                                        </select>
                                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                            <div *ngIf="f.subject.errors.required">{{ 'contact_us.Subject is required' |
                                                translate }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                    <textarea name="msg" placeholder="{{ 'contact_us.Message' | translate }} ..."
                                        formControlName="msg"
                                        [ngClass]="{ 'is-invalid': submitted && f.msg.errors }"></textarea>
                                    <div *ngIf="submitted && f.msg.errors" class="invalid-feedback">
                                        <div *ngIf="f.msg.errors.required">{{ 'contact_us.Message is required' |
                                            translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                                    <button class="theme-btn-one" type="submit" [disabled]="loading"
                                        name="submit-form">{{ 'contact_us.Send Message' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="locations-section bg-color-2">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-50.png);"></div>
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                    <div class="single-item wow fadeInUp animated animated" data-wow-delay="00ms"
                        data-wow-duration="1500ms">
                        <div class="inner-box">
                            <div class="upper-box">
                                <h3>{{address.country1}}</h3>
                                <p>{{address.address1}}</p>
                                <!-- <p>#4, 7th Street, Tansi Nagar,<br>
                                            Taramani Link Road,<br>
                                            Velachery, Chennai - 600 042</p> -->
                            </div>
                            <ul class="info-list clearfix">
                                <li>
                                    <i class="flaticon-email"></i>
                                    <p>{{ 'contact_us.Email' | translate }}</p>
                                    <a href="mailto:{{address.email1}}">{{address.email1}}</a>
                                </li>
                                <li>
                                    <i class="flaticon-phone-call"></i>
                                    <p>{{ 'contact_us.Phone' | translate }}</p>
                                    <a href="tel: {{address.phone1}}"> {{address.phone1}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                    <div class="single-item wow fadeInUp animated animated" data-wow-delay="300ms"
                        data-wow-duration="1500ms">
                        <div class="inner-box">
                            <div class="upper-box">
                                <h3>{{address.country2}}</h3>
                                <p>{{address.address2}}</p>
                                <!-- <p>3-20-23, Edakita, Aoba-Ku,<br>
                                            Yokohama-City, Kanagawa,<br>
                                            Japan - 2250015</p> -->
                            </div>
                            <ul class="info-list clearfix">
                                <li>
                                    <i class="flaticon-email"></i>
                                    <p>{{ 'contact_us.Email' | translate }}</p>
                                    <a href="mailto:{{address.email2}}">{{address.email2}}</a>
                                </li>
                                <li>
                                    <i class="flaticon-phone-call"></i>
                                    <p>{{ 'contact_us.Phone' | translate }}</p>
                                    <a href="tel:{{address.phone2}}"> {{address.phone2}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>