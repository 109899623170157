import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-technical-training',
  templateUrl: './technical-training.component.html',
  styleUrls: ['./technical-training.component.css']
})
export class TechnicalTrainingComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Technical Training';
  bannerDetails:any={
    title:"",
    description:"",
    image:"",
  }
  techService:any = {
    image:"",
    description1: "",
    description2: "",
    description3: ""
  }
  videos:any;
  images:any;
  paragraphs:any;
  studentTestimonial:any;
  customOptionsVideos: OwlOptions = {
    loop:true,
	margin:30,
	nav:false,
	smartSpeed: 1000,
  dots: false,
	navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
	responsive:{
		0:{
			items:1
		},
		480:{
			items:2
		},
		600:{
			items:3
		},
		800:{
			items:4
		},			
		1200:{
			items:5
		}
	}
  }
  customOptionsStudent: OwlOptions = {
    loop:true,
	margin:30,
	nav:true,
	smartSpeed: 3000,
	dots: false,
	navText: [ '<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>' ],
	responsive:{
		0:{
			items:1
		},
		480:{
			items:1
		},
		600:{
			items:1
		},
		800:{
			items:2
		},
		1024:{
			items:2
		}
	}
  }
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService,
		private sanitizer: DomSanitizer,
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getTechnologyTrainingSeo()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.updateTitle(data.data.title);
            this.updateMetaDesc(data.data.description);
            this.updateMetaKey(data.data.keywords);
          }else{
          
          }
        },
        error => {}
      );
  }
  transformHtml(value:any) {
	return this.sanitizer.bypassSecurityTrustHtml(value);
}
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getTechnologyTrainingBanner()
    .pipe(first())
    .subscribe(
      data => {
        if(data.status == 1){
          this.bannerDetails = data.data;
        }else{
        
        }
      },
      error => {
      }
    );
    this.requestService.getTechnologyTrainingTechService()
    .pipe(first())
    .subscribe(
      data => {
        if(data.status == 1){
          this.techService = data.data;
        }else{
        
        }
      },
      error => {
      }
    );
    this.requestService.getGallery(1,3)
		.pipe(first())
		.subscribe(
			data => {
				console.log('data videos',data);
				if(data.status == 1){
					this.videos = data.data;
				}else{
					this.videos =[
						// {"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
						// {"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getGallery(0,3)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.images = data.data;
				}else{
					this.images =[
						// {"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
						// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getTechnologyTrainingParagraph()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.paragraphs = data.data;
					setTimeout(()=>{
						$("#pera_expand0").click();
				   }, 2000);
				}else{
				}
			},
			error => {
			}
		);
    this.requestService.getStudentTestimonial(3)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.studentTestimonial = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  	}
}
