

<div class="boxed_wrapper">
    <app-header></app-header>        
        <div class="mobile-menu">
            <div class="menu-backdrop"></div>
            <div class="close-btn"><i class="fas fa-times"></i></div>
                <nav class="menu-box">
                    <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                    <div class="menu-outer"></div>
                </nav>
            </div>
            <section class="page-title" style="background-image: url({{bannerDetails.image}});margin-top: 69px;">
                <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <div class="title-box centred">
                            <h1>{{bannerDetails.title}}</h1>
                            <h1 style="font-size: 16px;" >{{bannerDetails.description}}</h1  >
                        </div>
                        <ul class="bread-crumb clearfix">
                            <li><a routerLink="/">{{ 'learnchain.Home' | translate }}</a></li>
                            <li>{{ 'learnchain.Learnchain' | translate }}</li>
                        </ul>
                    </div>
                </div>
            </section><br>
            <section class="" id="japanese_training">
                <div class="auto-container">
                    <div class="row align-items-center clearfix">
                        <div class="col-xl-12 col-lg-12 col-sm-12"  *ngIf="lng == 'en'">
                            <div class="sec-title centred">
                                <span class="top-title">NIHON EDUTECHON PARTNERSHIP</span>
                                <h2>Master Japanese with Us</h2>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-sm-12" *ngIf="lng == 'ja'">
                            <div class="sec-title centred">
                                <span class="top-title">日本エデュテックとのパートナーシップ
                                </span>
                                <h2>私たちと一緒に日本語をマスターしましょう
                                </h2>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div class="text">
                                <img src="assets/images/learnchain_img1.jpg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 inner-column " *ngIf="lng == 'en'">
                            <div id="content_block_1">
                                <div class="content-box">
                                    <div class="inner-box">
                                       <p> We understand your institution may lack Japanese teachers, curriculum design, or
                                        experience in offering Japanese classes. However, this shouldn't hinder your goal of
                                        teaching Japanese. Our partnership program provides well-designed Japanese language
                                        courses and expertly trained Japanese teachers to support your institution's language
                                        education initiatives.
                                        </p>
                                       <strong>Meet Nihon Edutech</strong>
                                       <p> NIHON EDUTECH, in partnership with the Confederation of Indian Industry (CII), holds
                                        NSDC approval for the Technical Intern Training Program (TITP). This collaboration
                                        emphasizes our commitment to fostering a skilled workforce with a focus on Japanese
                                        language proficiency. By joining forces with us, your organization can provide students
                                        exclusive access to a comprehensive language program, empowering them for lucrative
                                        career paths in Japan</p>
                                        <strong>Discover the opportunities of our partnership program! <a href="/enquire">Click here to connect to us</a>
                                        </strong>
                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mrgn_top" *ngIf="lng == 'en'">
                            <h5 style="font-weight: 700;">Partnership Description
                            </h5>
                               <p class="mt-2"> Upon establishing our partnership program, designated teachers will be deployed to your specified locations. Our courses emphasize conversational skills, Japanese culture, and
                               etiquette, preparing students for versatile academic and career paths in Japan after completing their degrees. Upon course completion, we facilitate seamless job placements in Japan,
                               ensuring a smooth transition for your students into the workforce.
                               </p>

                               <h5 class="mt-4" style="font-weight: 700;">      Why Nihon Edutech?
                            </h5>
                         

                            <ul class="list_6 mt-3">
                                <li>Native-Japanese trainers and Indian teachers.</li>
                                <li>Focus on spoken Japanese language and culture.</li>
                                <li>Top-tier sending organization in India
                                </li>
                                <li>Active partnership with Japanese enterprises </li>
                                <li>More than 300 successful job placement
                                </li>
                            </ul>
                            <p style="text-align: center;font-size: 13px; ">Unlock the potential of partnership! <a href="/enquire">Contact us</a>  and explore the possibilities!
                            </p>
                            <!-- <p>Nihon Edutech provides Japanese language training through different programs and extend support to students for job placements in Japan. All the programs stress on conversational skills and teaches Japanese culture and etiquettes. The programs are conducted through a combination of on-line and physical classroom sessions (presently only on-line).</p> -->
                        </div>




                        <div class="col-lg-6 col-md-12 col-sm-12 inner-column " *ngIf="lng == 'ja'">
                            <div id="content_block_1">
                                <div class="content-box">
                                    <div class="inner-box">
                                       <p> 日本語教師、カリキュラム作成、日本語の指導方法に不安や心配がありますか？私たちにお任せください。当社のパートナーシッププログラムでは、日本語学習コースの提供と経験豊富な日本人教師をお客様の指定された場所に配置することで、お客様の日本語教育のサポートをいたします。
                                        
                                        </p>
                                       <strong>日本エデュテックとは
                                    </strong>
                                       <p> 日本エデュテックは、インド工業連盟（CII）と提携し、技能実習生トレーニングプログラム（TITP）に関する全国技能開発公社（NSDC）の承認を取得しています。当社は、日本語能力と就労に必要となるスキルの育成に焦点を当てた送り出し機関です。当社とのパートナーシップにより、お客様は生徒に日本語のコースを提供することが可能となるだけでなく、日本での就労に向けてサポートをすることもできます。
                                        </p>
                                        <strong>パートナシップに興味がありますか？ お問い合わせは<a href="/enquire">こちら</a>から     
                                        </strong>
                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mrgn_top" *ngIf="lng == 'ja'">
                            <h5 style="font-weight: 700;">パートナーシップの概要
                            </h5>
                               <p class="mt-2"> パートナーシップ締結後、指定された場所に専任の講師を配置いたします。当社のコースは、会話スキル、日本文化、礼儀・マナーを重視し、生徒はコース修了後の多彩な進路に向けた準備を行うことができます。コース修了後は、生徒が日本で就職することができるよう円滑にサポートいたします。
                                
                               </p>

                               <h5 class="mt-4" style="font-weight: 700;">      日本エデュテックとパートナーシップを結ぶ6つの理由

                            </h5>
                         

                            <ul class="list_6 mt-3">
                                <li>日本人教師とインド人教師が熱心に教えております</li>
                                <li>日本語のコミュニケーションと文化の理解を積極的に指導しています</li>
                                <li>オンラインプラットフォームのNcareersを採用しております
                                </li>
                                <li>インドでトップクラスの送り出し機関です
                                </li>
                                <li>多くの日本企業とのパートナーシップを結んでおります 
                                </li>
                                <li>300人以上の送り出し経験があります
                                </li>
                            </ul>
                            <p style="text-align: center;font-size: 13px; ">私たちとのパートナーシップに興味はありますか？ お問い合わせは<a href="/enquire">こちら</a>から
                            </p>
                            <!-- <p>Nihon Edutech provides Japanese language training through different programs and extend support to students for job placements in Japan. All the programs stress on conversational skills and teaches Japanese culture and etiquettes. The programs are conducted through a combination of on-line and physical classroom sessions (presently only on-line).</p> -->
                        </div>

                      
                
                    </div>
                </div>
            </section>

            <section class="auto-container row" *ngIf="lng == 'en'">
                <div class="col-lg-6 col-md-12 col-sm-12 ">
                    <h5 style="font-weight: 700;">Course Details for Indian organization

                    </h5>
                    <strong class="mt-3">For College/University

                    </strong><br>
                    <P><strong>Target Japanese proficiency:</strong> JLPT N3 in 3 years- Reaching N3
                        proficiency may require enrolling in our full-time class offered at
                        our centers.
                        </P>
                        <P><strong>Classes: </strong>  5 hours / Week
                            </P>
                            <P><strong>Duration: </strong> 3 years
                                </P>

                                <strong>For organizations

                                </strong><br>
                                <p><strong>You can craft customized learning courses</strong> that seamlessly align with your unique
                                    needs and objectives. By taking the time to understand your specific challenges
                                    and desired outcomes, we can deliver targeted courses that empower your team
                                    to achieve its full potential. <a href="/enquire">Contact us</a> today to discuss how we can tailor a
                                    learning solution that perfectly fits your needs.</p><br>


                    
                  
               
                  
              

                    <h5 style="font-weight: 700;">Current Partners
                    </h5><br>
                
              
                        
                    <div class="image-row">
                        <ng-container *ngFor="let item of paragraphs">
                          <figure class="image-container">
                            <img src="{{item.image}}" alt="" class="image">
                          </figure>
                        </ng-container>
                      </div>
                  
     
                    
                  
                </div>
          
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="text">
                            <img src="assets/images/classroom-multi-ethnic-students-listening-600nw-1077839504.webp" />
                        </div>
                    </div>
            </section>

            <section class="auto-container row mt-4" *ngIf="lng == 'en'">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <h5 style="font-weight: 700;">Partnership Detail with Japanese Companies
                    </h5>
                    <strong class="mt-3">What we are offering to Japanese Partners
                    </strong>
                    
                  
               
                  
                    
                    <ul class="">
                        <li>  Customization of candidates</li>
                        <li>     We can design and deliver programs that cater to your
                            specific requirements, including desired skill sets and target
                            Japanese language proficiency levels.</li>
                        <li>  Full support post-selection, from visa application to housing
                            arrangement
                        </li>
                       
                    </ul><br>

                 
                  
     
                    
                  
                </div>
<div class="col-lg-6 col-md-12 col-sm-12 ">
    <h5 style="font-weight: 700;">Current Partners
    </h5><br>


        
    <div class="image-row">
        <ng-container *ngFor="let item of paragraphs1">
          <figure class="image-container">
            <img src="{{item.image}}" alt="" class="image">
          </figure>
        </ng-container>
      </div>
</div>

               
          
                
            </section>


            <section class="auto-container row mt-4" *ngIf="lng == 'ja'">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <h5 style="font-weight: 700;">日本企業様とのパートナーシップ
                    </h5>
                    <strong class="mt-3">日本企業へのオファー
                    </strong>
                    
                  
               
                  
                    
                    <ul class="">
                        <li>  候補者のカスタマイゼーション</li>
                        <li>  お客様のニーズに合わせたプログラムの設計と提供が可能です。求めるスキルセットや目標の日本語能力レベルに対応します。</li>
                        <li>  選考後、ビザの申請から住居手配まで、フルサポートいたします
                        </li>
                       
                    </ul><br>

                 
                  
     
                    
                  
                </div>
<div class="col-lg-6 col-md-12 col-sm-12 ">
    <h5 style="font-weight: 700;">現在のパートナー
    </h5><br>


    <div class="image-row">
        <ng-container *ngFor="let item of paragraphs1">
          <figure class="image-container">
            <img src="{{item.image}}" alt="" class="image">
          </figure>
        </ng-container>
      </div>
</div>

               
          
                
            </section>


            <section class="auto-container row" *ngIf="lng == 'ja'">
                <div class="col-lg-6 col-md-12 col-sm-12 ">
                    <h5 style="font-weight: 700;">コースの詳細

                    </h5>
                    <strong class="mt-3">専門学校・大学向けクラスモデル

                    </strong><br>
                    <P><strong>ゴール:</strong> 3年間でJLPT N3レベル - 3年でJLPT N3レベルを目指す場合、当センターで提供されているフルタイムクラスへの参加が必要となる可能性があります。
                        </P>
                        <P><strong>授業時間: </strong>  1時間/日・5時間/週
                            </P>
                            <P><strong>期間: </strong> 3年
                                </P>

                                <strong>一般企業様向け

                                </strong><br>
                                <p>私たちがお客様の現状とゴールをしっかりと理解し、目標達成を後押しすることのできる、最適な学習プランをお作りします。 ぜひ、<a href="/enquire">こちら</a>をクリックしてお問い合わせください。</p><br>


                    
                  
               
                  
              

                    <h5 style="font-weight: 700;">現在の私たちのパートナー

                    </h5><br>
                
              
                        
                    <div class="image-row">
                        <ng-container *ngFor="let item of paragraphs">
                          <figure class="image-container">
                            <img src="{{item.image}}" alt="" class="image">
                          </figure>
                        </ng-container>
                      </div>
                  
     
                    
                  
                </div>
          
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="text">
                            <img src="assets/images/classroom-multi-ethnic-students-listening-600nw-1077839504.webp" />
                        </div>
                    </div>
            </section>
                
            <!-- <section class="faq-section">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                            <div class="inner-box">
                                <ul class="accordion-box">
                                    <li *ngFor="let item of paragraphs; let i = index" class="accordion block">
                                        <div class="acc-btn">
                                            <div id="pera_expand{{i}}" class="icon-outer"></div>
                                            <h5>{{item.title}}</h5>
                                        </div>
                                        <div class="acc-content">
                                            <div class="text">
                                                <div class="row">
                                                    <div class="col-md-4 col-sm-4 bdr_right">
                                                        <img src="{{item.image}}" />
                                                    </div>
                                                    <div class="col-md-8 col-sm-8 panel_height" [innerHTML]="transformHtml(item.description)">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
            <div class="auto-container">
                <div class="sec-title">
                    <span class="top-title">{{ 'it_engineering.Gallery' | translate }}</span>
                </div>
                <owl-carousel-o [options]="customOptionsVideos">
                    <ng-container *ngFor = "let item of images">
                        <ng-template carouselSlide>
                            <div  class="project-block-one">
                                <div id="image_block_1">
                                    <div class="image-box">
                                        <div class="video-inner" style="background-image: url({{item.image}});">
                                        </div>
                                        <div class="lower-cont">
                                            <h3>{{item.title}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="auto-container">
                <div class="sec-title">
                    <span class="top-title">{{ 'it_engineering.Videos' | translate }}</span>
                </div>
                <owl-carousel-o [options]="customOptionsVideos">
                    <ng-container *ngFor = "let item of videos">
                        <ng-template carouselSlide>
                            <div class="project-block-one">
                                <div id="image_block_1">
                                    <div class="image-box">
                                        <div class="video-inner" style="background-image: url({{item.image}});">
                                            <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                                <i class="flaticon-play"></i>
                                            </a>
                                            <div class="border"></div>
                                        </div>
                                    </div>
                                    <div class="lower-cont">
                                        <h3>{{item.title}}</h3>
                                    </div>
                                </div>
                            </div>
                        </ng-template>  
                    </ng-container>
                </owl-carousel-o>
            </div>
                    
            <section class="testimonial-section bg-color-2">
                <div class="auto-container">
                    <div class="sec-title">
                        <span class="top-title">{{ 'it_engineering.Student Testimonials' | translate }}</span>
                    </div>
                    <owl-carousel-o [options]="customOptionsStudent">
                        <ng-container *ngFor="let item of studentTestimonial">
                            <ng-template carouselSlide>
                                <div class="testimonial-block-one">
                                    <div class="inner-box">
                                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                        <div class="author-box">
                                            <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                            <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                        </div>
                                        <div class="text">
                                            <p>{{item.description1}}</p>
                                            <p>{{item.description2}}</p>
                                        </div>
                                    </div>
                                </div> 
                            </ng-template>  
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </section>
        <app-footer></app-footer>
    </div>
                    