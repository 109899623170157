<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header></app-header>
   <!-- <header class="main-header">
        <div class="header-lower">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left clearfix">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                    <div class="mobile-nav-toggler">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu navbar-expand-md navbar-light">
                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix">
                                <li class="dropdown"><a href="learning.html">Learning</a>
                                    <ul>
                                        <li><a href="learning.html#japanese_training">Japanese Training</a></li>
                                        <li><a href="learning.html#technical_training">Technical Training</a></li>
                                        <li><a href="register.html">nihoncareers.com</a></li>
                                    </ul>
                                </li>    
                                <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                    <ul>
                                        <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                        <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                    <ul>
                                        <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                        <li><a href="resourcing_professionals.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="technology.html">Technology</a>
                                    <ul>
                                        <li><a href="technology.html#tech_services">Tech Services</a></li>
                                        <li><a href="technology.html#background_screening">Background Screening</a></li>
                                        <li><a href="learning.html#learnchain">Learnchain</a></li>
                                    </ul>
                                </li> 
                                <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                  <ul>
                                      <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="sticky-header">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                    <nav class="main-menu clearfix">
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="search-btn">
                            <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                        </div>
                    </li>
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
    </header> -->
    <section class="banner-section style-two">
        <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
            <div class="slide-item">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-10.jpg)"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <span>Get the job</span>
                        <h1>Confidence has a lot to do with interviewing <br>- that, and timing</h1>
                        <div class="btn-box">
                            <a href="hire_now.html" class="btn-one">Hire Now</a>
                            <a routerLink="/get_job" class="btn-two">Get job Now</a>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </section>
  
      <section class="findjob-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">Employer</span>
                <h2>Find Your Job You Deserve It</h2>
                <p>Long established fact that a reader will be distracted by the <br>readable content of a page.</p>
            </div>
            <div class="search-inner">
                <form action="index.html" method="post" class="search-form">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-12 col-sm-12 column">
                            <div class="form-group">
                                <i class="flaticon-search"></i>
                                <input type="search" name="search-field" placeholder="Keyword (Title or Company)" required="">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 column">
                            <div class="form-group">
                                <i class="flaticon-place"></i>
                                <input type="search" name="search-field" placeholder="Location (City or State)" required="">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 column">
                            <div class="form-group">
                                <i class="flaticon-folder"></i>
                                <div class="select-box">
                                    <select class="wide">
                                       <option data-display="Desired Work Status">Desired Work Status</option>
                                       <option value="1">Nihon Edutech</option>
                                       <option value="2">Digitera</option>
                                       <option value="3">Toyota</option>
                                       <option value="4">Wipro</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 column">
                            <div class="form-group message-btn">
                                <button type="submit" class="theme-btn-one">Search Jobs</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="post-jobs">
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 3 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 2757203</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo1.png" alt=""></figure>
                            <h3>IT Head and Director</h3>
                            <p><i class="flaticon-place"></i>Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$44,000 - $55,000 Per Year</p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>1-3 Yrs</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 5 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 5462485</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo2.png" alt=""></figure>
                            <h3>Marketing Head and Director</h3>
                            <p><i class="flaticon-place"></i>Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$85,000 - $90,000 Per Year</p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>3-5 Yrs</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 8 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 8548214</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo3.png" alt=""></figure>
                            <h3>Administrative Coordinator</h3>
                            <p><i class="flaticon-place"></i> Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$62,000 - $75,000 Per Year</p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>Freshers</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 12 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 9452152</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo4.png" alt=""></figure>
                            <h3>Purchase Head and Director</h3>
                            <p><i class="flaticon-place"></i> Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$85,000 - $90,000 Per Year</p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>2-3 Yrs</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 14 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 8546144</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo5.png" alt=""></figure>
                            <h3>IT Head and Director</h3>
                            <p><i class="flaticon-place"></i> Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$44,000 - $55,000 Per Year </p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>1-2 Yrs</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-header clearfix">
                        <ul class="info pull-left">
                            <li><i class="flaticon-clock"></i>Posted: 25 days ago</li>
                        </ul>
                        <div class="number pull-right"><p>Job ID: 8548566</p></div>
                    </div>
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo6.png" alt=""></figure>
                            <h3>CSR Head and Director</h3>
                            <p><i class="flaticon-place"></i> Kanagawa, Japan</p>
                        </div>
                        <div class="salary-box">
                            <span>Salary</span>
                            <p>$85,000 - $90,000 Per Year</p>
                        </div>
                        <div class="experience-box">
                            <span>Experience Need</span>
                            <p>3-5 Yrs</p>
                        </div>
                        <div class="apply-btn">
                            <a href="job-openings.html">Apply</a>
                        </div>
                    </div>
                </div>
                <ul class="pagination">
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                  </ul>
            </div>
        </div>
    </section>
    <app-footer-two></app-footer-two>
<!-- <footer class="main-footer style-two bg-color-21">
        <div class="auto-container">
            <div class="footer-top">
                <div class="widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget logo-widget">
                                <div class="widget-title">
                                    <h3>About Company</h3>
                                </div>
                                <div class="text">
                                     <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners...<i class="fas fa-arrow-up"></i></p>
                                </div>
                                <div class="upload-btn"><a href="index-3.html"><i class="flaticon-cloud-computing"></i>Upload Resume</a></div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul class="links-list clearfix">
                                    <li><a href="aboutus.html">About Us</a></li>
                                    <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>
                                    <li><a href="learning.html">Learning</a></li>
                                    <li><a href="resourcing.html">Resourcing</a></li>
                                    <li><a href="technology.html">Technology</a></li>
                                    <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                    <li><a href="contactus.html">Contact Us</a></li>
                                    <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                    <li><a href="schedule_session.html">Schedule a Session</a></li>
                                </ul>
                                <div class="about-widget">
                                <ul class="social-links clearfix">
                                    <li><h5>Connected:</h5></li>
                                    <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 footer-column">
                            <div class="footer-widget post-widget">
                                <div class="widget-title">
                                    <h3>Recent Post</h3>
                                </div>
                                <div class="post-inner">
                                    <div class="post">
                                        <figure class="image-box"><a href="blog-details.html"><img src="assets/images/news/post-1.jpg" alt=""></a></figure>
                                        <h5><a href="blog-details.html">Transforming India: How to maximize benefits from the state of change</a></h5>
                                         <span class="post-date">03rd March 2021</span>
                                    </div>
                                    <div class="post">
                                        <figure class="image-box"><a href="blog-details.html"><img src="assets/images/news/post-3.jpg" alt=""></a></figure>
                                        <h5><a href="blog-details.html">3 Surprising Culture Shocks for Indians in Japan</a></h5>
                                        <span class="post-date">06th Feb 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom clearfix">
                <div class="copyright pull-left">
                    <p class="clr_2">Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right clearfix">
                    <li class="clr_2">Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                </ul>
            </div>
        </div>
    </footer> -->
</div>