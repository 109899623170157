import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Contact Us';
  loading = false;
  submitted = false;
  enqForm:any;
  errorMsg:any = "";
  successMsg:any = "";
  bannerDetails:any={
    title:"Learning Experience Platform",
    description:"Use existing training setup and revenue as base and leverage track record to acquire employers",
    image:"assets/images/background/page-title.jpg",
  }
  address:any = {
    country1:"India",
    address1:"#4, 7th Street, Tansi Nagar, Taramani Link Road, Velachery, Chennai - 600 042",
    email1:"info@nihonedutech.com",
    phone1:"(+91) 8939581615",
    country2:"Japan",
    address2:"3-20-23, Edakita, Aoba-Ku, Yokohama-City, Kanagawa, Japan - 2250015",
    phone2:"info@nihonedutech.com",
    email2:"(+81) 45-913-8982"
  }
	constructor(
		private formBuilder: FormBuilder,
		private titleService: Title,
		private metaService: Meta,
    	private translate: TranslateService,
    	private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getContactUsSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}
    updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
	  ]);
	this.enqForm = this.formBuilder.group({
		name: ['', Validators.required],
		email_id: ['', Validators.required],
		phone: ['', Validators.required],
		// subject: ['', Validators.required],
		msg: ['', Validators.required],
		subject: ['']
	  });
    this.requestService.getContactUsBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getContactUsAddress()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.address = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  }

  get f() { return this.enqForm.controls; }

  onSubmit(){
	let selected = $("#subject").val();
    this.enqForm.value.subject = selected;
    this.submitted = true;
    if (this.enqForm.invalid) {
        return;
    }
	this.loading = true;
	console.log('this.enqForm.value',this.enqForm.value);
    this.requestService.addContactUsForm(this.enqForm.value)
    .pipe(first())
    .subscribe(
      data => {
        console.log('data',data);
        if(data.status == 1){
          this.successMsg = data.message;
          setTimeout(() => {
            this.successMsg = '';
          }, 1500);
        }else{
          this.errorMsg = data.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 1500);
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

}
