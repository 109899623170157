import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage-service';


@Injectable()
export class LangInterceptor implements HttpInterceptor {
  lng:any = 'en';
  constructor(
    private localStorageService: LocalStorageService,
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem('lng'))this.lng = localStorage.getItem('lng');
    const modifiedReq = req.clone({ 
      headers: req.headers.set('lang', this.lng),
    });
    return next.handle(modifiedReq);
  }
}