<div class="boxed_wrapper">
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>

    <section class="page-title" style="background-image: url({{enquirepagebannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <div class="npf_wgts" data-height="750px" data-w="fbd810a994afdc3854747899fedda22e"></div>
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="growth-style-two" id="japanese_training">
        <div class="container-fluid btn_top text-center">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12"
                    *ngFor="let item of enquirepageParagraph.slice(0, itemsToShow)">
                    <figure class="snip1163">
                        <!-- *ngFor="let item of jobimages let i=index" -->
                        <img src="{{item.image}}" class="enquireimage" alt="sample48">
                        <!-- *ngIf="i <3  -->
                        <figcaption>
                            <h2 class="enquirehaeder">{{item.title}}</h2>
                            <div>
                                <span class=" btn_top">
                                    <div class="enquireP" [innerHTML]="transformHtml(item.description)"
                                        [ngStyle]="{ color: item.textColor }"></div>
                                    <p class="enquireP">
                                        <!-- Japanese language proficiency levels vary from entry level (N5) through intermediate
                                    (N4, N3) to advanced levels (N2, N1). While N4 level proficiency is sufficient for
                                    semi-skilled workers, for high-skilled category such as IT, Engineering and other
                                    disciplines, the minimum expectation from Japan is N3 level and above. -->
                                    </p>
                                    <a target="_blank" *ngIf="item.urllink && item.urllink !== ''"
                                        class="theme-btn-one1" href="{{item.urllink}}">Read more</a>
                                </span>
                            </div>

                        </figcaption>
                    </figure>
                </div>

            </div>
            <a *ngIf="itemsToShow < enquirepageParagraph.length" (click)="loadMoreItems()" class="theme-btn-2">
                See More
            </a>
        </div>

    </section>

    <app-footer></app-footer>
</div>