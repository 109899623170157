import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';
import { PreloaderService } from 'src/app/services/preloader.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	
	lng = localStorage.getItem('lng');
	[x: string]: any;
	title = 'Nihon Edutech Private Limited | Home';
	meta_desc = 'Nihon';
	meta_keyword = 'Nihon';
	slider1_title = "";
	slider1_desc1 = "";
	slider1_desc2 = "";
	slider1_img = "";
	slider1_btn_link = "";
	slider2_title = "";
	slider2_desc1 = "";
	slider2_desc2 = "";
	slider2_img = "";
	slider2_btn_link = "";
	slider3_title = "";
	slider3_desc1 = "";
	slider3_desc2 = "";
	slider3_img = "";
	slider3_btn_link = "";
	slider4_title = "";
	slider4_desc1 = "";
	slider4_desc2 = "";
	slider4_img = "";
	slider4_btn_link = "";

	teamData: any = [];
	studentTestimonial: any = [];
	displayStyle = "none";
	whatWeDo: any = {
		description: "",
		image: "",
		images: "",
		video_image: "",
		video_link: ""
	};
	address: any = {
		country1: "India",
		address1: "#4, 7th Street, Tansi Nagar, Taramani Link Road, Velachery, Chennai - 600 042",
		email1: "info@nihonedutech.com",
		phone1: "(+91) 8939581615",
		country2: "Japan",
		address2: "3-20-23, Edakita, Aoba-Ku, Yokohama-City, Kanagawa, Japan - 2250015",
		phone2: "info@nihonedutech.com",
		email2: "(+81) 45-913-8982"
	}

	videos: any;
	images: any;
	Sswtitp: any;
	ITint: any;
	mech: any = [];
	japanesecourse: any;
	training_center:any;
	partner: any = [];
	client: any = [];
	blogNews: any;
	scripts = { loaded: false, url: 'https://widgets.in5.nopaperforms.com/emwgts.js' }
	technical_train: any = {
		title: "",
		subtitle: "",
		description1: "",
		description2: "",
		image: "",
	}
	about_sec: any = {
		title: "",
		subtitle: "",
		description1: "",
		description2: "",
		image: "",
	}
	recruitment_sec: any = {
		title: "",
		subtitle: "",
		description1: "",
		description2: "",
		image: "",
	}

	calculateTotal(): number {
		return this.mech?.reduce((total: number, item: any) => total + Number(item.placement), 0);
	  }
	  showStaticContent = false;

	  onCardMouseOver(index: number): void {
		this.showStaticContent = index === this.mech.length - 1;
	  }
	
	  onCardMouseLeave(): void {
		this.showStaticContent = false;
	  }
	  
	  customOptionsimage: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			600: {
				items: 3
			},
			800: {
				items: 3
			}
			// 1200:{
			// 	items:5
			// }
		}
	}
	customOptionsVideos: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			600: {
				items: 3
			},
			800: {
				items: 4
			}
			// 1200:{
			// 	items:5
			// }
		}
	}

	getTotalPlacement1(): number {
		return this.waiting?.reduce((sum: number, item: { content4: number }) => sum + (item.content4 || 0), 0);
	  }
	getTotalPlacement(): number {
		return this.mech?.reduce((sum: number, item: { placement: number }) => sum + (item.placement || 0), 0);
	  }
	  
	customOptionsClient: OwlOptions = {
		loop: true,
		margin: 0,
		nav: false,
		dots: false,
		smartSpeed: 3000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			600: {
				items: 3
			},
			800: {
				items: 4
			},
			1200: {
				items: 6
			}

		}
	}
	customOptionsBlogNews: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoWidth: true,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 2
			},
			1024: {
				items: 3
			}
		}
	}

	customOptionsjobtitp: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 2
			},
			1024: {
				items: 3
			}
		}
	}

	customOptionsjobit: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 2
			},
			1024: {
				items: 3
			}
		}
	}

	customOptionsjapanese: OwlOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 2
			},
			1024: {
				items: 3
			}
		}
	}

	customOptionsFirstcol: OwlOptions = {
		loop: true,
		margin: 0,
		nav: true,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 1
			},
			1024: {
				items: 1
			}
		}
	}

	hiddenform = false;
	jobimages: any;
	Jobspost: any;
	showModal: boolean = false;
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService,
		private sanitizer: DomSanitizer,
		private preloaderService: PreloaderService,
	) {

		this.preloaderService.show(); // Show the preloader when this component is loaded
		setTimeout(() => {
			this.preloaderService.hide(); // Hide the preloader after a delay (simulating some loading process)
		}, 20000);

		// let lng = localStorage.getItem('lng');
		console.log(this.lng);
		if (this.lng == null || this.lng == '' || this.lng == undefined) this.lng = 'en';
		translate.setDefaultLang(this.lng);
		this.requestService.getHomeSlider()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						data.data.forEach((item: any) => {
							// console.log(data);
							if (item.slug == 1) {
								this.slider1_title = item.title;
								this.slider1_desc1 = item.description1;
								this.slider1_desc2 = item.description2;
								this.slider1_img = item.image;
								this.slider1_btn_link = item.link_button;
							}
							if (item.slug == 2) {
								this.slider2_title = item.title;
								this.slider2_desc1 = item.description1;
								this.slider2_desc2 = item.description2;
								this.slider2_img = item.image;
								this.slider2_btn_link = item.link_button;
							}
							if (item.slug == 3) {
								this.slider3_title = item.title;
								this.slider3_desc1 = item.description1;
								this.slider3_desc2 = item.description2;
								this.slider3_img = item.image;
								this.slider3_btn_link = item.link_button;
							}
							if (item.slug == 4) {
								this.slider4_title = item.title;
								this.slider4_desc1 = item.description1;
								this.slider4_desc2 = item.description2;
								this.slider4_img = item.image;
								this.slider4_btn_link = item.link_button;
							}
						})
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getDataBySlug(6)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.technical_train = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getDataBySlug(7)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.about_sec = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getDataBySlug(8)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.recruitment_sec = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getAllTeam()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.teamData = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getStudentTestimonial(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.studentTestimonial = data.data.reverse();
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getCompanyData(0)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.partner = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getCompanyData(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.client = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getContactUsAddress()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.address = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getHomeSeo()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.updateTitle(data.data.title);
						this.updateMetaDesc(data.data.description);
						this.updateMetaKey(data.data.keywords);
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getHomeWhatWeDo()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.whatWeDo = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getGallery(1, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.videos = data.data;
					} else {
						this.videos = [
							// {"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
							// {"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
						]
					}
				},
				error => {
				}
			);
		this.requestService.getGallery(0, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.images = data.data;
					} else {
						this.images = [
							// {"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}

			);
		this.requestService.getworkjapan(0, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.jobimages = data.data.reverse();
					} else {
						this.jobimages = [
							// {"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);
		this.requestService.getBlogsNewsData()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.blogNews = data.data;
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);
		this.requestService.postworkjapan(0, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.Sswtitp = data.data.reverse();
					} else {
						this.Sswtitp = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);

			this.requestService.list(0, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.mech = data.data.reverse();
					} else {
						this.mech = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);

		
		this.requestService.postworkjapan(1, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.ITint = data.data.reverse();
					} else {
						this.ITint = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);

			this.requestService.postwaiting(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.waiting = data.data.reverse()
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);
		  

			this.requestService.postjapanesecourse(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.japanesecourse = data.data.reverse();
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);

			this.requestService.posttrainigcenters(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.training_center = data.data.reverse();
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);
			

	}
	transformHtml(value: any) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({ name: 'description', content: contant })
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({ name: 'keywords', content: contant })
	}
	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.metaService.addTags([
			{ name: 'description', content: this.meta_desc },
			{ name: 'keywords', content: this.meta_keyword },
		]);
		if (this.scripts.loaded) {
			// resolve({script: name, loaded: true, status: 'Already Loaded'});
		}
		else {
			//load script
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = this.scripts.url;
			// if (script.readyState) {  //IE
			//     script.onreadystatechange = () => {
			//         if (script.readyState === "loaded" || script.readyState === "complete") {
			//             script.onreadystatechange = null;
			//             this.scripts[name].loaded = true;
			//             resolve({script: name, loaded: true, status: 'Loaded'});
			//         }
			//     };
			// } else {  //Others
			script.onload = () => {
				this.scripts.loaded = true;
				// console.log('loaded script .....');
				// resolve({script: name, loaded: true, status: 'Loaded'});
			};
			// script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
			document.getElementsByTagName('head')[0].appendChild(script);
		}
		// $(".preloader-close").click();
	}

	paperformclick() {
		this.hiddenform = true;
	}

	openPopUp() {
		this.displayStyle = "block";
		$("#myModal").modal({ backdrop: true });
	}
	closePopup() {
		this.displayStyle = "none";
		$("#myModal").modal('hide');
		// $("#myModal").modal({backdrop: false});
	}





	// closeModal() {
	//   this.showModal = false;
	// }


}
