import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent implements OnInit {
  constructor(
    private translate: TranslateService
  ) {
    let lng = localStorage.getItem('lng');
    if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
  }

  ngOnInit(): void {
  }

}
