import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';


@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.css']
})
export class TechnologyComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Technology';
  bannerDetails:any={
    title:"Resourcing",
    description:"Use existing training setup and revenue as base and leverage track record to acquire employers, then expand",
    image:"assets/images/background/page-title.jpg",
  }
  techService:any = {
    image:"assets/images/tech_img1.jpg",
    description1: "Nihon Edutech offers solutions in niche technologies that can automate business processes and provide a superior ROI to customer’s technology investments. The solutions offered include Staff Augmentation, Team Augmentation and Project services using onsite, offshore and nearshore delivery models.",
    description2: "HR in Corporates often undertake rapid hiring approach at the cost of Safety, Security and Performance standards, posing high risk to their business and reputation. It is extremely critical for organizations to perform a thorough background check and due diligence on individuals before hiring them.",
    description3: "Nihon Edutech in partnership with Avvanz, a reputed global leader in background screening, offers you an AI-powered, automated and cloud-based solution (ScreenGlobal), to help HR perform fast and complete background verification of candidates and hire them without any concerns on their integrity."
  }
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
  ) {
    let lng = localStorage.getItem('lng');
      if(lng == null || lng == '' || lng == undefined)lng = 'en';
      translate.setDefaultLang(lng);
      this.requestService.getTechnologySeo()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.updateTitle(data.data.title);
            this.updateMetaDesc(data.data.description);
            this.updateMetaKey(data.data.keywords);
          }else{
          
          }
        },
        error => {
        }
      );
  }
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getTechnologyBanner()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.bannerDetails = data.data;
          }else{
          
          }
        },
        error => {
        }
      );
      this.requestService.getTechnologyTechService()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.techService = data.data;
          }else{
          
          }
        },
        error => {
        }
      );
  }

}
