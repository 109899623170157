<footer class="main-footer style-two bg-color-1">
	<div class="auto-container">
		<div class="footer-top">
			<div class="widget-section">
				<div class="row clearfix mb-md-5">
					<div class="col-md-6 col-sm-12 footer-column">
						<div class="footer-widget about-widget">
							<div class="widget-title">
								<h3>{{ 'footer-one.About_Company' | translate }}</h3>
							</div>
							<div class="text">
								<p>{{ 'footer-one.About Desc' | translate }}</p>
								<!-- <img src="assets/images/startupindia2.png" alt="" class="">
								<p class="bm">Recognized: (DIPP63113)</p> -->
							</div>
						</div>
					</div>
					<div class="col-md-6 col-sm-12 footer-column">
						<div class="footer-widget links-widget">
							<div class="widget-title">
								<h3>{{ 'footer-one.Useful_Links' | translate }}</h3>
							</div>
							<ul class="links-list clearfix dropdown_ul">
								<li><a routerLink="/about_us">{{ 'footer-one.About_Us' | translate }}</a></li>
								<!-- <li><a data-toggle="modal" data-target="#exampleModal">Blogs</a></li> -->
								<!-- <li><a  data-toggle="modal" data-target="#testModal" style="cursor: pointer;">Blogs & News</a></li> -->
								<!-- <li><a><button (click)="openModal()">Blogs</button></a></li> -->

								<li><a routerLink="/contact_us">{{ 'footer-one.Contact_Us' | translate }}</a></li>
								<li><a href="https://nihon.ncareers.org/" target="_blank">{{ 'footer-one.Learning' |
										translate }}</a>
								</li>
								<li><a routerLink="/tech_services">{{ 'footer-one.Technology' | translate }}</a></li>
								<li class="dropdown_li_main">
									<a>{{'footer-one.Jobs_in_Japan' | translate }}
										<i class="fas fa-angle-down"></i>
									</a>
									<ul class="dropdown_ul_sub">
										<li class="dropdown_li w-100">
											<span class="flaticon-right-arrow-2 dr_icon"></span>
											<a routerLink="/jobs_in_japan_semi_skilled" class="dr_link">
												{{'footer-one.Semi_skilled' | translate}}
											</a>
										</li>
										<li class="dropdown_li">
											<span class="flaticon-right-arrow-2 dr_icon"></span>
											<a class="dr_link" routerLink="/it_engineers">
												{{'footer-one.IT_Engineering' | translate}}
											</a>
										</li>
									</ul>
								</li>
								<li class="dropdown_li_main">
									<a>{{'footer-one.Resourcing' | translate}}
										<i class="fas fa-angle-down"></i>
									</a>
									<ul class="dropdown_ul_sub">
										<li class="dropdown_li w-100">
											<span class="flaticon-right-arrow-2 dr_icon"></span>
											<a routerLink="/resourcing_semi_skilled" class="dr_link">
												{{'footer-one.Semi_skilled' | translate}}
											</a>
										</li>
										<li class="dropdown_li">
											<span class="flaticon-right-arrow-2 dr_icon"></span>
											<a routerLink="/resourcing_professional" class="dr_link">
												{{'footer-one.IT_Engineering' | translate}}
											</a>
										</li>
									</ul>
								</li>
								<!-- <li><a routerLink="/blogs_news">{{ 'footer-one.Blogs_News' | translate }}</a></li> -->
								<li><a routerLink="/privacy_policy">{{ 'footer-one.Privacy_Policy' | translate }}</a>
								</li>
								<li><a class="d-none" routerLink="/schedule_session">{{ 'footer-one.Schedule_a_Session'
										| translate
										}}</a></li>
							</ul>
							<!-- <div class="about-widget">
								<ul class="social-links clearfix">
									<li>
										<h5>{{ 'footer-one.Connected' | translate }}:</h5>
									</li>
									<li><a href="https://www.facebook.com/nihoncareers"><i
												class="fab fa-facebook-f"></i></a></li>
									<li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="https://www.linkedin.com/company/nihonedutech"><i
												class="fab fa-linkedin"></i></a></li>
								</ul>
								<a href="https://www.veltechtbi.com/" target="_blank"><img
										src="assets/images/veltech.jpg"> TBI Incubatee</a>
							</div> -->
						</div>
					</div>

					<!-- form start -->
					<!-- <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
						<div class="footer-widget callback-widget">
							<div class="pattern-layer">
								<div class="pattern-1"
									style="background-image: url(assets/images/shape/pattern-25.png);"></div>
								<div class="pattern-2"></div>
								<div class="pattern-3"></div>
								<div class="pattern-4"
									style="background-image: url(assets/images/shape/pattern-23.png);"></div>
							</div>
							<div class="widget-content">
								<b style="color: red;">{{ errorMsg }}</b>
								<b style="color: green;">{{ successMsg }}</b>
								<form [formGroup]="enqForm" (ngSubmit)="onSubmit()" method="post" class="callback-form">
									<div class="row clearfix">
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="text" name="name"
												placeholder="{{ 'footer-one.Name' | translate }}*"
												formControlName="name"
												[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
											<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
												<div *ngIf="f.name.errors.required">Name is required</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="email" name="email"
												placeholder="{{ 'footer-one.Email' | translate }}*"
												formControlName="email"
												[ngClass]="{ 'is-invalid': submitted && f.email.errors }">
											<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
												<div *ngIf="f.email.errors.required">Email is required</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<input type="text" name="phone"
												placeholder="{{ 'footer-one.Phone' | translate }}*"
												formControlName="phone"
												[ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
											<div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
												<div *ngIf="f.phone.errors.required">Phone is required</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 col-sm-12 form-group">
											<div class="select-box">
												<select class="wide" style="display: none;" formControlName="type">
													<option data-display="Please Select">{{ 'footer-one.Please_Select' |
														translate }}</option>
													<option value="1">TITP</option>
													<option value="2">SSW/SSP</option>
													<option value="3">IT/Engineering</option>
												</select>
												<div *ngIf="submitted && f.type.errors" class="invalid-feedback">
													<div *ngIf="f.type.errors.required">Type is required</div>
												</div>
												<div class="nice-select wide" tabindex="0">
													<span class="current">{{ 'footer-one.Please_Select' | translate
														}}</span>
													<ul class="list">
														<li data-value="Please Select" data-display="Please Select"
															class="option selected focus">{{ 'footer-one.Please_Select'
															| translate }}</li>
														<li (click)="changeSelectedType('1')" data-value="1"
															class="option">TITP</li>
														<li (click)="changeSelectedType('2')" data-value="2"
															class="option">SSW/SSP</li>
														<li (click)="changeSelectedType('3')" data-value="3"
															class="option">IT/Engineering</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="col-lg-12 col-md-12 col-sm-12 form-group">
											<button type="submit" [disabled]="loading" class="theme-btn-one">{{
												'footer-one.Get_Call_Back' | translate }}</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div> -->
					<!-- form end -->

				</div>
				<br>

				<div class="row">
					<div class="col-md-4 text-center">
						<div class="text">
							<img src="assets/images/startupindia2.png" alt="" class="">
							<p class="" style="color: #b7c9e4!important;">Recognized: (DIPP63113)</p>
						</div>
					</div>
					<div class="col-md-4 text-center mb-4">
						<!-- <div class="about-widget"> -->
						<!-- <ul class="social-links clearfix">
								<li>
									<h5>{{ 'footer-one.Connected' | translate }}:</h5>
								</li>
								<li><a href="https://www.facebook.com/nihoncareers"><i
											class="fab fa-facebook-f"></i></a></li>
								<li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a>
								</li>
								<li><a href="https://www.linkedin.com/company/nihonedutech"><i
											class="fab fa-linkedin"></i></a></li>
							</ul> -->
						<a href="https://www.veltechtbi.com/" class="d-flex justify-content-center h-100"
							target="_blank">
							<img src="assets/images/veltech.jpg" class="d-block mr-2">
							<span class="d-flex align-items-center tbistyle">TBI Incubatee</span>
						</a>
						<!-- </div> -->
					</div>
					<div class="col-md-4 text-center d-flex justify-content-center align-items-center mb-md-4">
						<div class="about-widget">
							<ul class="social-links clearfix">
								<li>
									<h4>{{ 'footer-one.Connected' | translate }}:</h4>
								</li>
								<li>
									<a href="https://www.facebook.com/nihoncareers" target="_blank"> 
										<i class="fab fa-facebook-f"></i>
									</a>
								</li>
								<li>
									<a href="https://twitter.com/nihoncareers1" target="_blank">
										<i class="fab fa-twitter"></i>
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/nihonedutech" target="_blank">
										<i class="fab fa-linkedin"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/nihonedutech/" target="_blank">
										<i class="fab fa-instagram"></i>
									</a>
								</li>
							</ul>
							<!-- <a href="https://www.veltechtbi.com/" target="_blank"><img
									src="assets/images/veltech.jpg"> TBI Incubatee</a> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-bottom clearfix">
			<div class="copyright pull-left">
				<p>Copyrights &copy; 2024 Nihon Edutech Private Limited. All Rights Reserved.</p>
			</div>
			<ul class="footer-nav pull-right clearfix">
				<li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera
						Technologies</a></li>
			</ul>
		</div>

	</div>
</footer>

<!-- <button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>{{ 'footer-one.Top' | translate }}</button> -->