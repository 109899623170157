import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class PreloaderResolver implements Resolve<Observable<boolean>> {

  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Perform your asynchronous operation here
    // and return an Observable<boolean>

    return new Observable<boolean>((observer) => {
      // Simulate an asynchronous operation that resolves the route
      setTimeout(() => {
        // Resolved successfully, navigate to the route
        observer.next(true);
        observer.complete();
      }, 20000); // Simulated delay of 2 seconds
    });
  }
}
