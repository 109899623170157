<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
    <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
    <div class="layer layer-one"><span class="overlay"></span></div>
    <div class="layer layer-two"><span class="overlay"></span></div>        
    <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
<app-header></app-header>
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    <nav class="menu-box">
        <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
        <div class="menu-outer"></div>
    </nav>
</div>

<section class="banner-section style-three">
    <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider1_title}}</h1>
                            <p>{{slider1_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider1_btn_link}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider2_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider2_title}}</h1>
                            <p>{{slider2_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider2_btn_link}}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider3_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider3_title}}</h1>
                            <p>{{slider3_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider3_btn_link}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section><br>

<div class="auto-container" *ngIf="lng == 'en'">
    <div class="content-box">
        <div class="title-box centred">
            <h4 style="color:#e23936; font-weight: 800;">HIGHER EDUCATION
            </h4>
            <p style="color:#0353a7 ;font-weight: 800
            ;">LEARNING THE JAPANESE LANGUAGE</p>
        </div>

    </div>
</div>

<div class="auto-container" *ngIf="lng == 'ja'">
    <div class="content-box">
        <div class="title-box centred">
            <h4 style="color:#e23936; font-weight: 800;">高等教育プログラム

            </h4>
            <p style="color:#0353a7 ;font-weight: 800
            ;">日本語学習
</p>
        </div>

    </div>
</div>

<section class="" id="ssw">
    <div class="auto-container">
        <div class="row clearfix">
          
            <div class="col-md-6">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/First-semester-classes.jpg" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="lng == 'en'">
                <div id="content_block_1">
                    <div class="content-box">
                      
                        <div class="inner-box" style="text-align: justify;">
                            <p>Embarking on a journey to bolster your Japanese language skills? Our
                                collaboration with prestigious Japanese language schools across Japan
                                empowers us to tailor your language learning experience. Discover the ideal
                                school and program that perfectly aligns with your aspirations and learning
                                style. Let's craft your path to Japanese proficiency together.
                                </p>
                            <p>Nihon Edutech facilitates Japanese language training up to the JLPT N3 level
                                with advanced placement opportunities in Japan. With no extra fees besides
                                tuition, they help find suitable schools for learners and provide comprehensive
                                support for visas, travel, and stay. Partnering with Kudan Institute and ARC
                                Tokyo, they offer JLPT N2/Business Japanese training, job opportunities, and
                                support services during their studies.
                                </p>
                            <!-- <div class="link"><a href="#" class="theme-btn-two">{{ 'technology.Request Service' | translate }}</a></div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6" *ngIf="lng == 'ja'">
                <div id="content_block_1">
                    <div class="content-box">
                      
                        <div class="inner-box" style="text-align: justify;">
                            <p>日本で日本語を勉強しませんか？日本エデュテックは、日本にある名門日本語学校とのパートナーシップを通じて、お客様一人ひとりの学習希望と学習スタイルに合った最適なカリキュラムを提供いたします。理想の学校とプログラムを発見し、私たちと一緒に日本語を習得しましょう。
                                
                                </p>
                            <p>日本エデュテックは、JLPT N3レベルまでの日本語学習コースの提供、さらに日本でのインターンシップや就職などのキャリア支援も行っています。この高等教育プログラムでは、授業料以外での追加料金は一切なく、生徒にあった最適な日本語学校の紹介や、ビザ・渡航・滞在サポートも行っています。留学先の学校では、JLPT N2/ビジネス日本語コースや、求人紹介、留学中のサポートサービスも幅広く提供されております。
                                
                                </p>
                            <!-- <div class="link"><a href="#" class="theme-btn-two">{{ 'technology.Request Service' | translate }}</a></div> -->
                        </div>
                    </div>
                </div>
            </div>
            </div>
        
    
            
            
    </div>
</section>

<section class="" id="japanese_training" *ngIf="lng == 'en'">
    <div class="auto-container">
        
           
            <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-2">
               <div id="content_block_1">
                   <div class="content-box">
                       <div class="inner-box">
                           <h5 style="font-weight: 700;">Why Nihon Edutech?

                           </h5>
                       
                               
                                   <ul>
                                       <strong> No Additional Fees</strong>
                                  
                                    <P>Explore your course options without added costs! Only the tuition fee for a language school in Japan is required!</P>
                                     <strong>Everyone Is Welcome!!
                                    </strong>
                                    <p>We get you customized education. We’ll Locate the Ideal Japanese School for Your Learning Stage, from Beginner to Advanced!
                                    </p>
                                    <strong>Experience Seamless Support</strong>
                                    <p>From Visa Processing to Accommodation, We’ve Got Your Entire Japan Journey Covered!
                                    </p>
                                     </ul>
                               
                           
                                 
                           <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                           <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                       </div><br>
                       <div class="inner-box">
                           <h5 style="font-weight: 700;">Overview of the higher education

                           </h5>
                           <strong>How to step up with us!!
                        </strong>
                        <p>Nihon Edutech offers a comprehensive program designed to elevate Japanese language proficiency to
                            JLPT N3 levels within India. This initiative serves as a gateway to advanced language training and career
                            prospects in Japan, facilitated by strategic alliances with prestigious Japanese language academies.
                            Please note that specific language proficiency prerequisites may vary among institutions.
                            </p><br>
                            <div class="d-md-block d-none ">
                                <div class="page " style="display: flex; justify-content: center">
  
    
                                    <div><strong style="margin-left: 45px;" >Study
                                    </strong>   <div class="arrow arrow--1">
                                        N3<br>
                                        N4<br>
                                        N5<br>
                                        </div><strong style="margin-left: 29px;" >At NEPL, India
                                        </strong></div>
                                                         
                                                        
                                                            <div style=" margin-top: 76px;;
                                                            color: red;
                                                            font-size: 50px;
                                                        ">
                                                            <i class="fa-solid fa-arrow-right"></i>
                                                    
                                                            </div>
                                                           <div><strong style="margin-left: 93px;" >Study
                                                        </strong> 
                                                            <div class="arrow arrow--1">
                                                                Basic,<br>
                                                                Intermediate,<br>
                                                                Advanced,<br>
                                                                Business Japanese<br>
                                                              
                                                                <!-- <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> -->
                                                              </div><strong style="margin-left: 29px;" >At a language school, Japan</strong></div><br>
                                                          
                                                              <div style="margin-top: 25px;
                                                              color: red;
                                                              font-size: 50px;
                                                          ">
                                                          
                                                      
                                                              </div>
                                                             
                                                    
                                                            
                                                         
                                                          
                                                           
                                                            
                                                            
                                                          </div>
                            </div>

                            <div class="d-block d-md-none ">
                                <div class="page " >
  
    
                                    <div>   <div class="arrow arrow--3">
                                        N3<br>
                                        N4<br>
                                        N5<br>
                                        </div><strong style="margin-left: 29px;" >At NEPL, India
                                        </strong></div>
                                                         
                                                        
                                                            <div style="margin-top: 25px;
                                                            color: red;
                                                            font-size: 50px;
                                                        ">
                                                            <!-- <i class="fa-solid fa-arrow-right"></i> -->
                                                    
                                                            </div>
                                                           <div>
                                                            <div class="arrow arrow--1">
                                                                Basic<br>
                                                                Intermidiate<br>
                                                                Advanced<br>
                                                                Business Japanese<br>
                                                              
                                                                <!-- <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> -->
                                                              </div><strong style="margin-left: 29px;" >At a language school, Japan</strong></div><br>
                                                          
                                                              <div style="margin-top: 25px;
                                                              color: red;
                                                              font-size: 50px;
                                                          ">
                                                          
                                                      
                                                              </div>
                                                             
                                                    
                                                            
                                                         
                                                          
                                                           
                                                            
                                                            
                                                          </div>
                            </div>
                          
                              
                       
                               <strong>Prerequisite</strong>
                                   <ul>
                                       <li><strong>Degree</strong></li>
                                       <li><strong>Financial Statement</strong>  to show financial stability (Minimum is 15 Lakhs) ー The found money must not be a loan.

                                       </li>
                                       <li><strong>At least JLPT N5
                                    </strong></li>
                                     
                                       <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                                     </ul><br>
                               <p><strong>Tuition Fees:</strong> Approximately JPY 485,000 to 850,000 (2.75 Lakhs to 4.8 Lakhs) Per Year ー Depending on duration (1 to 2 Years)</p><br>
                           
                               <h5 style="font-weight: 700;">   First-Year Estimated Cost of Living (Rent, Food, The others)

                               </h5>

                            
                              <p><strong>Tokyo: </strong> JPY 90,000/month, JPY 1,08,000/year (INR 0.5 Lakhs/month, INR 6 Lakhs/year)</p> 
                              <p><strong> Kyoto:</strong>     JPY 75,000/month, JPY 900,000/year (INR 0.42 Lakhs/month, INR 5 Lakhs/year)</p> 

                              <p><strong> Osaka:</strong> JPY 80,000/month, JPY 960,000/year (INR 0.45 Lakhs/month, INR 5.4 Lakhs/year)</p> <br>


                              <h5 style="font-weight: 700;">   Part-time jobs

                              </h5>

                              <strong>Foreigners in Japan under a student visa, upon permission from Immigration, can work up to 28 hours/week to partly cover their living expenses.
                            </strong>
                            <strong>You can make approximately JPY 80,000 to 90,000. (INR 0.45 Lakhs to 0.51 Lakhs) (If you work 80 hours per month).</strong><br>
                            <strong> Part-time jobs could be at... (It depends on what kinds of certification you have)
                            </strong>
                            <ul>
                                <li>Convenience store</li>
                                <li>Restaurants
                                </li>
                                <li>Primary school for teaching

                             </li>
                              
                                <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                              </ul><br>
                                 
                           <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                           <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                       </div>



                  



                
                       
                   </div>
               </div>
           </div>
          
        </div>
</section>

<section class="" id="japanese_training" *ngIf="lng == 'ja'">
    <div class="auto-container">
        
           
            <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-2">
               <div id="content_block_1">
                   <div class="content-box">
                       <div class="inner-box">
                           <h5 style="font-weight: 700;">日本エデュテックを選ぶ3つの理由


                           </h5>
                       
                               
                                   <ul>
                                       <strong> 追加料金ゼロ
                                    </strong>
                                  
                                    <P>追加料金なしでコース選択を自由にすることができます！必要なのは授業料だけ！
                                    </P>
                                     <strong>日本語初心者から上級者まで大歓迎

                                    </strong>
                                    <p>お客様一人ひとりに合った教育を提供しております。初心者から上級者まで、あなたのレベルにあった最適な日本語学校をご紹介いたします！

                                    </p>
                                    <strong>万全なサポート体制
                                    </strong>
                                    <p>ビザの取得から宿泊手配まで、あなたの日本留学に必要なすべてをサポートします！
                                    </p>
                                     </ul>
                               
                           
                                 
                           <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                           <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                       </div><br>
                       <div class="inner-box">
                           <h5 style="font-weight: 700;">高等教育プログラムの概要


                           </h5>
                           <strong>日本語のレベルを私たちと一緒に上げましょう！！

                        </strong>
                        <p>日本エデュテックでは、生徒がインド国内でN3レベルまでレベルアップさせるコースを提供しております。そして、この高等教育プログラムでは、日本エデュテックと日本語学習機関との提携を通じて、語学力アップと日本での新しいキャリアの道を開くサポートします。なお、必要とされる日本語能力のレベルは機関によって異なりますのでご注意ください。
                            </p><br>
                            <div class="d-md-block d-none ">
                                <div class="page " style="display: flex; justify-content: center">
  
    
                                    <div>  <div class="arrow arrow--1">
                                        N3<br>
                                        N4<br>
                                        N5<br>
                                        </div><strong style="margin-left: 40px;" >インド
                                        </strong></div>
                                                         
                                                        
                                                            <div style=" margin-top: 54px;;
                                                            color: red;
                                                            font-size: 50px;
                                                        ">
                                                            <i class="fa-solid fa-arrow-right"></i>
                                                    
                                                            </div>
                                                           <div>
                                                            <div class="arrow arrow--1">
                                                                ビジネス⽇本語<br>
                                                                上級<br>
                                                                中級<br>
                                                                基礎<br>
                                                              
                                                                <!-- <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> -->
                                                              </div><strong style="margin-left: 55px;" >日本の語学学校</strong></div><br>
                                                          
                                                              <div style="margin-top: 25px;
                                                              color: red;
                                                              font-size: 50px;
                                                          ">
                                                          
                                                      
                                                              </div>
                                                             
                                                    
                                                            
                                                         
                                                          
                                                           
                                                            
                                                            
                                                          </div>
                            </div>

                            <div class="d-block d-md-none ">
                                <div class="page " >
  
    
                                    <div>   <div class="arrow arrow--3">
                                        N3<br>
                                        N4<br>
                                        N5<br>
                                        </div><strong style="margin-left: 29px;" >インド
                                        </strong></div>
                                                         
                                                        
                                                            <div style="margin-top: 25px;
                                                            color: red;
                                                            font-size: 50px;
                                                        ">
                                                            <!-- <i class="fa-solid fa-arrow-right"></i> -->
                                                    
                                                            </div>
                                                           <div>
                                                            <div>
                                                                <div class="arrow arrow--1">
                                                                    ビジネス⽇本語<br>
                                                                    上級<br>
                                                                    中級<br>
                                                                    基礎<br>
                                                                  
                                                                    <!-- <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> -->
                                                                  </div><strong style="margin-left: 9px;" >日本の語学学校</strong></div><br>
                                                          
                                                              <div style="margin-top: 25px;
                                                              color: red;
                                                              font-size: 50px;
                                                          ">
                                                          
                                                      
                                                              </div>
                                                             
                                                    
                                                            
                                                         
                                                          
                                                           
                                                            
                                                            
                                                          </div>
                            </div>
                          
                              
                       
                               
                                 
                           <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                           <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                       </div>


                       <strong>入学条件</strong>
                       <ul>
                           <li><strong>学位</strong></li>
                           <li><strong>残高証明：</strong>  最低150万円以上。
                            
                           </li>
                           <li><strong>必要言語条件：
                        </strong> JLPT N5</li>
                         
                           <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                         </ul><br>
                   <p><strong>授業料：</strong>1年あたり約48万~85万円。期間・留学先によって異なります。</p><br>
               
                   <h5 style="font-weight: 700;">  1年間生活費目安 (家賃、食費、その他)

                   </h5>

                
                  <p><strong>東京: </strong> ¥90,000/月, ¥1,080,000/年
                  </p> 
                  <p><strong> 京都:</strong>     ¥75,000/月、¥900,000/年</p> 

                  <p><strong> 大阪:</strong> ¥80,000/月、¥960,000/年
                  </p> <br>


                  <h5 style="font-weight: 700;">  アルバイトについて


                  </h5>

                  <strong>留学生は、出入国在留管理庁の許可を得た上で週最大28時間アルバイトをすることができ、生活費の一部を賄うことが可能です。月80時間（週約20時間）働く場合、およそ8万〜9万円稼ぐことができます。
                </strong><br><br>

                <strong >アルバイト先の例 (所持する資格の種類によって変わります)

                </strong>
                <ul>
                    <li>コンビニエンスストア</li>
                    <li>レストラン
                    </li>
                    <li>小学校の言語ティーチングアシスタント

                 </li>
                  
                    <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                  </ul><br>
                  



                
                       
                   </div>
               </div>
           </div>
          
        </div>
    </div>
</section>

<section class="">
    <div class="auto-container">
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                <h5 style="font-weight: 700;">
                    {{ 'tech_services.tech' | translate }}
                </h5><br>
                <div class="inner-box">

                    <div *ngFor="let item of partner " class="accordion block">
                        <div class="acc-btn">
                            <div class="icon-outer"></div>
                            <!-- <h5>{{item.title}}</h5> -->
                        </div>
                        <div class="acc-content">
                            <div class="text">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 bdr_right">
                                        <img src="{{item.image}}" />
                                    </div>
                                    <div class="col-md-8 col-sm-8 panel_height1"
                                        [innerHTML]="transformHtml(item.description1)">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <!-- <li class="accordion block active-block">
                                <div class="acc-btn active">
                                    <div class="icon-outer"></div>
                                    <h5>Semi-Skilled (TITP/SSW)</h5>
                                </div>
                                <div class="acc-content current">
                                    <div class="text_bdr">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/titp_img1.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>Semi-Skilled (TITP/SSW)</h3>
                                                <p><strong>Overview:</strong> This program is meant for full-time learners and is best suited for students of Technical Intern Trainee Program.</p>
                                                <p><strong>Scope:</strong>  Japanese language skills to prepare students for the JLPT N4 level.</p>
                                                <p><strong>Classes & Duration:</strong> 4 hours/day on all weekdays.  6 Months.</p>
                                                <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N4 level.</p>
                                                <p><strong>Cost :</strong></p>
                                                <div class="table-responsive-md">	
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr class="table_bg">
                                                                <th>(Fees exclude applicable taxes)</th>
                                                                <th>Japanese Training</th>
                                                                <th>Books</th>
                                                                <th>Placement Cost</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th><strong>TITP</strong></th>
                                                                <td>INR 75,000</td>
                                                                <td>INR 15,000</td>
                                                                <td>INR 2,10,000</td>
                                                            </tr>
                                                            <tr>
                                                                <th><strong>SSW</strong></th>
                                                                <td>INR 75,000</td>
                                                                <td>INR 15,000</td>
                                                                <td>TBD</td>
                                                            </tr>
                                                        </tbody>	
                                                    </table>
                                                </div>	
                                                <ul class="list_4n">
                                                    <li>Fees exclude Exam Fees and applicable taxes.</li>
                                                    <li>Upfront payment: INR 30,000 for Diploma Holders and INR 40,000 for B.E Degree holders.</li>
                                                </ul>
                                                <p>Please click this <a href="pdf/semi_skilled.pdf">link</a> to download brochure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="accordion block">
                                <div class="acc-btn">
                                    <div class="icon-outer"></div>
                                    <h5>High-Skilled (Professionals)</h5>
                                </div>
                                <div class="acc-content">
                                    <div class="text_bdr">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/titp_img1.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>High-Skilled (Professionals)</h3>
                                                <p><strong>Overview:</strong> Targeted for working professionals, the classes are conducted without disturbing the regular working time.</p>
                                                <p><strong>Scope:</strong> Up to JLPT N3 level. Flexibility to enroll only for selective levels.</p>
                                                <p><strong>Classes:</strong> Week-day Program - MON-FRI 2 hours/day (Morning or Evening) and 4 hours on Saturday. Week-end Program: 4 hours each on Saturday &amp; Sunday. </p>
                                                <p><strong>Duration:</strong> 9-12 months (for all levels) depending on weekday or week-end programs. On an average each level takes 3-4 months</p>
                                                <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N3 level.</p>
                                                <p><strong>Cost: (to be paid in 2-3 installments) :</strong></p>
                                                <div class="table-responsive-md">	
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr class="table_bg">
                                                                <th>(Fees include applicable Taxes)</th>
                                                                <th>N5</th>
                                                                <th>N4</th>
                                                                <th>N3</th>
                                                                <th>All Levels</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th><strong>Japanese Training</strong></th>
                                                                <td>INR 16,999</td>
                                                                <td>INR 24,999</td>
                                                                <td>INR 24,999</td>
                                                                <td>INR 64,999</td>
                                                            </tr>
                                                        </tbody>	
                                                    </table>
                                                </div>	
                                                <ul class="list_4n">
                                                    <li>Fees exclude Books and Exam Fees</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="accordion block">
                                <div class="acc-btn">
                                    <div class="icon-outer"></div>
                                    <h5>Colleges/Universities</h5>
                                </div>
                                <div class="acc-content">
                                    <div class="text_bdr">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/titp_img1.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>Colleges/Universities</h3>
                                                <p><strong>Overview:</strong> Learning Japanese language in colleges allow students to choose multiple academic and career options after they complete the degree program. This program is conducted to cover up to JLPT N3 level over 3 years duration, covering approximately one JLPT level per year.</p>
                                                <p><strong>Scope:</strong> Japanese language skills to prepare the students for the JLPT N3 level. This program stresses on conversational skills and teaches Japanese culture and etiquettes.</p>
                                                <p><strong>Classes:</strong> 5 hours per week.</p>
                                                <p><strong>Duration:</strong> 3 years (1 year for each JLPT level)</p>
                                                <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N3 level</p>
                                                <p><strong>Cost :</strong>Please <a href="contactus.html">contact us</a> for pricing</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="accordion block">
                                <div class="acc-btn">
                                    <div class="icon-outer"></div>
                                    <h5>Business Leaders (Spoken Skills)</h5>
                                </div>
                                <div class="acc-content">
                                    <div class="text_bdr">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/titp_img1.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>Business Leaders (Spoken Skills)</h3>
                                                <p><strong>Overview:</strong> This program focuses only on spoken skills, best suited for middle, senior management professionals and sales personnel in corporate who needs to interact with customers or those who need not possess Japanese read/write skills during the course of their work.</p>
                                                <p><strong>Scope:</strong> Along with spoken skills, necessary Japanese culture and etiquettes are. </p>
                                                <p><strong>Classes &amp; Duration :</strong> 2 hours/day on all weekdays, 4 months (all levels)</p>
                                                <p><strong>Exams/Certifications:</strong>  Tests and examinations are conducted by Nihon Edutech teachers.</p>
                                                <p><strong>Cost :</strong>
                                                <div class="table-responsive-md">	
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr class="table_bg">
                                                                <th>(Fees exclude applicable taxes)</th>
                                                                <th>Basic</th>
                                                                <th>Pre-intermediate</th>
                                                                <th>Intermediate</th>
                                                                <th>All Levels</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th><strong>Japanese Training</strong></th>
                                                                <td>INR 5,000</td>
                                                                <td>INR 10,000</td>
                                                                <td>INR 15,000</td>
                                                                <td>INR 25,000</td>
                                                            </tr>
                                                        </tbody>	
                                                    </table>
                                                </div>	
                                                <ul class="list_4n">
                                                    <li>Fees exclude Books and Exam Fees</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                            <p *ngIf="lng == 'en'" style="text-align: center;font-size: 13px; ">Want to Study at one of the school Please <a href="/enquire"> Contact Us</a></p>
                            <!-- <p *ngIf="lng == 'ja'" style="text-align: center;font-size: 13px; ">Want to Study at one of the school Please <a href="/enquire"> Contact Us</a></p> -->


                </div>
            </div><br>
            <p *ngIf="lng == 'en'"><strong> Note: </strong> At ARC Tokyo, assessment exams unique to their institution are used to determine your Japanese proficiency level and class placement. Your placement is not determined by your JLPT certificationPlease note that prices are subject to change over time
            </p><br>
            <p *ngIf="lng == 'ja'"><strong> 注意: </strong> ARC TOKYOでは、独自の日本語能力レベル試験を実施しており、クラス分けはJLPTの保有資格を基準に決定されません。<br>価格は予告なく変更となる場合がございますので、あらかじめご了承ください。


            </p><br>
        </div>

    </div>
</section>
 
<!-- <div *ngFor="let item of partner; let i=index;">
    <section *ngIf="i == 0" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 1" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 2" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 3" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 4" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 5" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 6" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 7" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 8" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 9" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="transformHtml(item.description1)">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="transformHtml(item.description2)">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->

<!-- <section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Cloud - Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/cloud-services1.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Cloud Transformation &amp; Security Services</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three bg-color-2a">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">PLM - Partner</span>
                    </div>
                </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img2.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Avvanz Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img3.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Avvanz</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three bg-color-2a">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Web development Partner</span>
                    </div>
                </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Web development</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                      </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img4.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>	

<section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Salesforce Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img5.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Salesforce</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section> -->

<app-footer></app-footer>
</div>