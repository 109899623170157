import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-enquire',
  templateUrl: './enquire.component.html',
  styleUrls: ['./enquire.component.css']
})
export class EnquireComponent implements OnInit {

  scripts = { loaded: false, url: 'https://widgets.in5.nopaperforms.com/emwgts.js' }
  title = 'Nihon Edutech Private Limite | IT/Engineering';
  itemsToShow = 6;
  enquirepagebannerDetails: any = {
    title: "",
    description: "",
    image: "",
  }
  enquirepageParagraph: any[] = [];
  // itemsToShow: any = [];



  loadMoreItems() {
    this.itemsToShow += 6;
  }

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService,
    private sanitizer: DomSanitizer,
  ) {
    let lng = localStorage.getItem('lng');
    if (lng == null || lng == '' || lng == undefined) lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getItEngineeringSeo()
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.updateTitle(data.data.title);
            this.updateMetaDesc(data.data.description);
            this.updateMetaKey(data.data.keywords);
          } else {

          }
        },
        error => {
        }
      );
  }

  validateColor(color: string): boolean {
    const element = document.createElement('div');
    element.style.color = color;
    return element.style.color !== '';
  }

  transformHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  updateTitle(title: any) {
    throw new Error('Method not implemented.');
  }
  updateMetaDesc(description: any) {
    throw new Error('Method not implemented.');
  }
  updateMetaKey(keywords: any) {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {

    if (this.scripts.loaded) {
      // resolve({script: name, loaded: true, status: 'Already Loaded'});
    }
    else {
      //load script
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.scripts.url;
      // if (script.readyState) {  //IE
      //     script.onreadystatechange = () => {
      //         if (script.readyState === "loaded" || script.readyState === "complete") {
      //             script.onreadystatechange = null;
      //             this.scripts[name].loaded = true;
      //             resolve({script: name, loaded: true, status: 'Loaded'});
      //         }
      //     };
      // } else {  //Others
      script.onload = () => {
        this.scripts.loaded = true;
        console.log('loaded script .....');
        // resolve({script: name, loaded: true, status: 'Loaded'});
      };
      // script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'description', content: 'Nihon' },
    ]);
    this.requestService.enquirepageBanner()
      .pipe(first())
      .subscribe(
        data => {
          if (data.status == 1) {
            this.enquirepagebannerDetails = data.data;
          } else {

          }
        },
        error => {
        }
      );

    this.requestService.getenquirepageParagraph().pipe(first()).subscribe(
      data => {
        if (data.status == 1) {
          this.enquirepageParagraph = data.data.sort((a: { priority_num: string }, b: { priority_num: any }) =>
            a.priority_num.localeCompare(b.priority_num)
          );

          this.enquirepageParagraph.forEach(item => {
            if (item.textColor === 'black') {
              item.textColor = 'white';
            }
          });

        } else {
          // Handle error
        }
      },
      error => {
        // Handle error
      }
    );

  }

}

