<div class="boxed_wrapper">
    <app-header></app-header>
    <!-- <header class="main-header">
            <div class="header-lower">
                <div class="outer-box clearfix">
                    <div class="menu-area pull-left clearfix">
                        <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                        <div class="mobile-nav-toggler">
                            <i class="icon-bar"></i>
                            <i class="icon-bar"></i>
                            <i class="icon-bar"></i>
                        </div>
                        <nav class="main-menu navbar-expand-md navbar-light">
                            <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                <ul class="navigation clearfix">
                                    <li class="dropdown"><a href="learning.html">Learning</a>
                                        <ul>
											<li><a href="japanese_training.html">Japanese Training</a></li>
											<li><a href="technical_training.html">Technical Training</a></li>
											<li><a href="register.html">nihoncareers.com</a></li>
                                        </ul>
                                    </li>    
                                    <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                        <ul>
											<li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                            <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                        </ul>
                                    </li> 
									<li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                        <ul>
                                            <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                            <li><a href="resourcing_professionals.html">Professional</a></li>
                                        </ul>
                                    </li> 
									<li class="dropdown"><a href="technology.html">Technology</a>
                                        <ul>
                                            <li><a href="tech_services.html">Tech Services</a></li>
                                            <li><a href="background_screening.html">Background Screening</a></li>
											<li><a href="learnchain.html">Learnchain</a></li>
                                        </ul>
                                    </li> 
									<li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <ul class="menu-right-content pull-right clearfix">
                        <li>
                            <div class="language">
                                <div class="lang-btn">
                                    <i class="icon flaticon-planet-earth"></i>
                                    <span class="txt">EN</span>
                                    <span class="arrow fa fa-angle-down"></span>
                                </div>
                                <div class="lang-dropdown">
									  <ul>
										  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
										  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
									</ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="register.html" class="theme-btn-one">Register</a>
                        </li>
						<li>
							<a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header> -->
        <div class="mobile-menu">
            <div class="menu-backdrop"></div>
            <div class="close-btn"><i class="fas fa-times"></i></div>
            <nav class="menu-box">
                <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                <div class="menu-outer"></div>
            </nav>
        </div>
        <section class="page-title" style="background-image: url({{bannerDetails.image}});">
            <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
            <div class="auto-container">
                <div class="content-box">
                    <div class="title-box centred">
                        <h1>{{bannerDetails.title}}</h1>
                        <p>{{bannerDetails.description}}</p>
                    </div>
                    <ul class="bread-crumb clearfix">
                        <li><a routerLink="/">{{ 'semi_skilled_job.Home' | translate }}</a></li>
                        <li>{{ 'semi_skilled_job.Specified Skilled Worker (SSW)' | translate }}</li>
                    </ul>
                </div>
            </div>
        </section>
		
		<section class="about-style-three" id="learnchain">
            <div class="auto-container">
                <div class="row clearfix">
					<div class="col-xl-6 col-lg-12 col-sm-12">
						<figure class="img_pdg img_pdg2"><img src="{{sswData.image}}" alt=""  class="brds"></figure>
					</div>
                    <div class="col-xl-6 col-lg-12 col-sm-12">
                        <div id="content_block_1">
                            <div class="content-box">
                                <div class="sec-title">
                                    <span class="top-title">{{ 'semi_skilled_job.Overview' | translate }}</span>
                                    <h2>{{ 'semi_skilled_job.Specified Skilled Worker (SSW)' | translate }}</h2>
                                </div>
                                <div class="inner-box">
                                    <p>{{sswData.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="col-xl-6 col-lg-12 col-sm-12">
						<div class="job-details-content">
                            <div class="text">
                                <ol class="list_3">
                                    <li><i class="fas fa-car"></i> {{ 'semi_skilled_job.Automobile repair and maintenance' | translate }} </li>
                                    <li><i class="fas fa-fighter-jet"></i> {{ 'semi_skilled_job.Aviation Industry' | translate }} </li>
                                    <li><i class="fas fa-bed"></i> {{ 'semi_skilled_job.Accommodation Industry' | translate }} </li>
                                    <li><i class="fas fa-tractor"></i> {{ 'semi_skilled_job.Agriculture' | translate }} </li>
                                    <li><i class="fas fa-fish"></i> {{ 'semi_skilled_job.Fishery Aquaculture' | translate }} </li>
                                    <li><i class="fas fa-utensils"></i> {{ 'semi_skilled_job.Manufacture of Food and Beverages' | translate }} </li>
                                    <li><i class="fas fa-hamburger"></i> {{ 'semi_skilled_job.Food Service Industry' | translate }} </li>
                                </ol>
                        </div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-12 col-sm-12">
					    <div class="job-details-content">
                            <div class="text">
                                <ol class="list_3" start="8">
                                    <li><i class="fas fa-car"></i> {{ 'semi_skilled_job.Automobile repair and maintenance' | translate }} </li>
                                    <li><i class="fas fa-fighter-jet"></i> {{ 'semi_skilled_job.Aviation Industry' | translate }} </li>
                                    <li><i class="fas fa-bed"></i> {{ 'semi_skilled_job.Accommodation Industry' | translate }} </li>
                                    <li><i class="fas fa-tractor"></i> {{ 'semi_skilled_job.Agriculture' | translate }} </li>
                                    <li><i class="fas fa-fish"></i> {{ 'semi_skilled_job.Fishery Aquaculture' | translate }} </li>
                                    <li><i class="fas fa-utensils"></i> {{ 'semi_skilled_job.Manufacture of Food and Beverages' | translate }} </li>
                                    <li><i class="fas fa-hamburger"></i> {{ 'semi_skilled_job.Food Service Industry' | translate }} </li>
                                </ol>
                            </div>
						</div>
					</div>
                </div>	
            </div>
        </section>
	
	    <section class="growth-style-two bg-color-2">
            <div class="auto-container">
                <div class="row align-items-center clearfix">
					<div class="col-xl-6 col-lg-12 col-sm-12">
						 <div id="content_block_1">
                            <div class="content-box">
                               <div class="job-details-content">
                            <div class="text">
                                <h3>{{ 'semi_skilled_job.Process' | translate }} :</h3>
                                <ul class="list clearfix">
                                    <li>{{sswProcessData.description1}}</li>
                                    <li>{{sswProcessData.description2}}</li>
                                    <li>{{sswProcessData.description3}}</li>
                                    <li>{{sswProcessData.description4}}</li>
                                    <li>{{sswProcessData.description5}}</li>
                                    <!-- <li>Foreign professionals pass the skills exam and Japanese language examinations.</li>
                                    <li>The professionals then needs to sign an employment contract with an employer (Accepting organization) in Japan.</li>
                                    <li>Accepting organization conducts a pre-departure orientation for the selected candidates.</li>
                                    <li>Accepting organization applies to regional immigration Bureau for the candidate’s certificate of eligibility (COE)</li>
                                    <li>Based on the COE, the Japanese embassies and Consulates-General in the originating countries issue visa for entry into Japan</li>
                                    <li>After entering Japan, the candidates attend orientation on daily life in Japan, conducted by the Accepting Organization, registers at municipality of residence and open bank accounts for receiving wages etc.</li>
                                    <li>Subsequently, the candidates start working in the Accepting organization.</li> -->
                                </ul>
                        </div>
						<p>(Referenece : <a href="https://www.mofa.go.jp/" target="_blank">"Specified Skilled Worker" - Ministry of Foreign Affairs of Japan</a> )</p>
                        </div>
                            </div>
                        </div>
					</div>
						<div class="col-xl-6 col-lg-12 col-sm-12">
							<figure><img src="{{sswProcessData.image}}" alt=""  class="brds"></figure>
						</div>
                </div>
            </div>
        </section>
    <app-footer></app-footer>
</div>