import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-blogs-news',
	templateUrl: './blogs-news.component.html',
	styleUrls: ['./blogs-news.component.css']
})
export class BlogsNewsComponent implements OnInit {
	title = 'Nihon Edutech Private Limite | Blogs & News';
	bannerDetails: any = {
		title: "Blogs & News",
		description: "Smarter Business Solutions. Better Customer Relations.",
		image: "assets/images/background/page-title.jpg",
	}
	blogNewsData: any = [];
	recentBlogNewsData: any = [];
	blog_id: any;


	loading = false;
	submitted = false;
	enqForm: any;
	selectedtype: any;
	errorMsg: any = "";
	successMsg: any = "";
	searchKeyword: any;
	filteredBlogNewsData: any;
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder
	) {
		let lng = localStorage.getItem('lng');
		if (lng == null || lng == '' || lng == undefined) lng = 'en';
		translate.setDefaultLang(lng);
		this.requestService.getBlogsNewsSeo()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.updateTitle(data.data.title);
						this.updateMetaDesc(data.data.description);
						this.updateMetaKey(data.data.keywords);
					} else {

					}
				},
				error => {
				}
			);
	}
	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({ name: 'description', content: contant })
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({ name: 'keywords', content: contant })
	}

		
	searchBlogs() {
	  if (this.searchKeyword) {
		this.filteredBlogNewsData = this.recentBlogNewsData.filter((item: any) =>
		  item.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
		);
	  } else {
		this.filteredBlogNewsData = this.recentBlogNewsData;
	  }
	}
	
	clearSearch() {
		this.searchKeyword = '';
		this.filteredBlogNewsData = this.recentBlogNewsData;
	  }

	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.metaService.addTags([
			{ name: 'description', content: 'Nihon' },
		]);
		this.requestService.getBlogsNewsBanner()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.bannerDetails = data.data[0];
					} else {

					}
				},
				error => {
				}
			);
		this.blog_id = this.route.snapshot.paramMap.get('id');
		this.requestService.getBlogsNewsDataBiId(this.blog_id)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.blogNewsData = data.data;
					} else {

					}
				},
				error => {
				}
			);
		this.requestService.getBlogsNewsData()
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.recentBlogNewsData = data.data;
						if (this.recentBlogNewsData && this.recentBlogNewsData.length > 5) {
							this.recentBlogNewsData = this.recentBlogNewsData.slice(0, 5);
						}
						// Set initial filtered data to all blog posts
						this.filteredBlogNewsData = this.recentBlogNewsData;
					} else {
						// Handle error case
					}
				},
				error => {
					// Handle error case
				}
			);
		this.enqForm = this.formBuilder.group({
			name: ['', Validators.required],
			email_id: ['', Validators.required],
			comment: ['', Validators.required],
			blog_id: ['']
		});
	}

	get f() { return this.enqForm.controls; }

	onSubmit() {
		this.enqForm.value.blog_id = this.blog_id;
		this.submitted = true;
		if (this.enqForm.invalid) {
			return;
		}
		this.loading = true;
		this.requestService.addBlogComment(this.enqForm.value)
			.pipe(first())
			.subscribe(
				data => {
					console.log('data', data);
					if (data.status == 1) {
						this.successMsg = data.message;
						setTimeout(() => {
							this.successMsg = '';
						}, 1500);
					} else {
						this.errorMsg = data.message;
						setTimeout(() => {
							this.errorMsg = '';
						}, 1500);
						this.loading = false;
					}
				},
				error => {
					this.loading = false;
				}
			);
	}

}
