<div class="boxed_wrapper">
    <app-header></app-header>
    <!-- <header class="main-header">
         <div class="header-lower">
             <div class="outer-box clearfix">
                 <div class="menu-area pull-left clearfix">
                     <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                     <div class="mobile-nav-toggler">
                         <i class="icon-bar"></i>
                         <i class="icon-bar"></i>
                         <i class="icon-bar"></i>
                     </div>
                     <nav class="main-menu navbar-expand-md navbar-light">
                         <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                             <ul class="navigation clearfix">
                                 <li class="dropdown"><a href="learning.html">Learning</a>
                                     <ul>
                                         <li><a href="japanese_training.html">Japanese Training</a></li>
                                         <li><a href="technical_training.html">Technical Training</a></li>
                                         <li><a href="register.html">nihoncareers.com</a></li>
                                     </ul>
                                 </li>    
                                 <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                     <ul>
                                         <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                         <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                     </ul>
                                 </li> 
                                 <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                     <ul>
                                         <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                         <li><a href="resourcing_professionals.html">Professional</a></li>
                                     </ul>
                                 </li> 
                                 <li class="dropdown"><a href="technology.html">Technology</a>
                                     <ul>
                                         <li><a href="tech_services.html">Tech Services</a></li>
                                         <li><a href="background_screening.html">Background Screening</a></li>
                                         <li><a href="learnchain.html">Learnchain</a></li>
                                     </ul>
                                 </li> 
                                 <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                             </ul>
                         </div>
                     </nav>
                 </div>
                 <ul class="menu-right-content pull-right clearfix">
                     <li>
                         <div class="language">
                             <div class="lang-btn">
                                 <i class="icon flaticon-planet-earth"></i>
                                 <span class="txt">EN</span>
                                 <span class="arrow fa fa-angle-down"></span>
                             </div>
                             <div class="lang-dropdown">
                                   <ul>
                                       <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                       <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                 </ul>
                             </div>
                         </div>
                     </li>
                     <li>
                         <a href="register.html" class="theme-btn-one">Register</a>
                     </li>
                     <li>
                         <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                     </li>
                 </ul>
             </div>
         </div>
     </header> -->
     <div class="mobile-menu">
         <div class="menu-backdrop"></div>
         <div class="close-btn"><i class="fas fa-times"></i></div>
         <nav class="menu-box">
             <div class="nav-logo"><a routerLink="/"><img src="assets/images/logo.png" alt="" title=""></a></div>
             <div class="menu-outer"></div>
         </nav>
     </div>
    <section class="portfolio-masonry protfolio-page">
         <div class="auto-container">
          <div class="sec-title centred">
                 <span class="top-title">Our Network & Industry Experience are Unmatched</span>
                 <h2>Gallery</h2>
             </div>
             <div class="sortable-masonry">
                 <div class="items-container row clearfix">
                     <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img5.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon</span>
                                         <h3><a href="#">CareWorker Interns<br> Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation technology">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img2.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                         <h3><a href="#">CareWorker Interns Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation market recruitment">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img3.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                         <h3><a href="#">CareWorker Interns Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img4.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                         <h3><a href="#">Manufacturing Interns <br />Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img8.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                         <h3><a href="#">CareWorker Interns Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation Recruitment technology">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img7.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                         <div class="project-block-one">
                             <div class="inner-box">
                                 <figure class="image-box">
                                     <img src="assets/images/gallery/gal_img6.jpg" alt="">
                                 </figure>
                                 <div class="content-box">
                                     <div class="text">
                                         <span>Nihon Edutech</span>
                                         <h3><a href="#">Technical Interns <br />in Japan</a></h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </section>
     <app-footer></app-footer>
     <!-- <footer class="main-footer style-two bg-color-1">
         <div class="auto-container">
             <div class="footer-top">
                 <div class="widget-section">
                     <div class="row clearfix">
                         <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                             <div class="footer-widget about-widget">
                                 <div class="widget-title">
                                     <h3>About Company</h3>
                                 </div>
                                 <div class="text">
                                     <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                                     <img src="assets/images/startupindia2.png" alt="" class="">
                                     <p class="bm">Recognized: (DIPP63113)</p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                             <div class="footer-widget links-widget">
                                 <div class="widget-title">
                                     <h3>Useful Links</h3>
                                 </div>
                                 <ul class="links-list clearfix">
                                     <li><a href="aboutus.html">About Us</a></li>
                                     <li><a href="contactus.html">Contact Us</a></li>
                                     <li><a href="learning.html">Learning</a></li>
                                     <li><a href="jobs_in_japan_semi_skilled.html">Jobs in Japan</a></li>	
                                     <li><a href="resourcing.html">Resourcing</a></li>
                                     <li><a href="technology.html">Technology</a></li>
                                     <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                     <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                     <li><a href="schedule_session.html">Schedule a Session</a></li>
                                 </ul>
                                 <div class="about-widget">
                                 <ul class="social-links clearfix">
                                     <li><h5>Connected:</h5></li>
                                     <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                     <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                     <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                 </ul>
                                 <img src="assets/images/veltech.jpg"> <a href="#">TBI Incubatee</a>
                             </div></div>
                         </div>
                         <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                             <div class="footer-widget callback-widget">
                                 <div class="pattern-layer">
                                     <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                     <div class="pattern-2"></div>
                                     <div class="pattern-3"></div>
                                     <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                                 </div>
                                 <div class="widget-content">
                                     <form action="index-2.html" method="post" class="callback-form">
                                         <div class="row clearfix">
                                             <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                 <input type="text" name="name" placeholder="Name*" required="">
                                             </div>
                                             <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                 <input type="email" name="email" placeholder="Email*" required="">
                                             </div>
                                             <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                 <input type="text" name="phone" placeholder="Phone*" required="">
                                             </div>
                                             <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                 <div class="select-box">
                                         <select class="wide" style="display: none;">
                                            <option data-display="Please Select">Please Select</option>
                                            <option value="1">TITP</option>
                                            <option value="2">SSW/SSP</option>
                                            <option value="3">IT/Engineering</option>
                                         </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                     </div>
                                             </div>
                                             <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                 <button type="submit" class="theme-btn-one">Get Call Back</button>
                                             </div>
                                         </div>
                                     </form>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="footer-bottom clearfix">
                 <div class="copyright pull-left">
                     <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                 </div>
                 <ul class="footer-nav pull-right clearfix">
                     <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                 </ul>
             </div>
         </div>
     </footer>
<button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
 </div>