
<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
      <div class="menu-backdrop"></div>
      <div class="close-btn"><i class="fas fa-times"></i></div>
          <nav class="menu-box">
              <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
              <div class="menu-outer"></div>
          </nav>
      </div>
   <!-- <div class="main-container">
      <span class="rect-polytechnic-college">RECT Polytechnic College</span>
      <div class="rectangle"></div>
      <span class="diploma-in-engineering"
        >Diploma in Engineering, Fluent in Japanese – Your Gateway to Global
        Opportunities!</span
      >
      <div class="image"></div>
      <div class="screenshot"></div>
      <span class="about-rect">About RECT</span>
      <div class="whatsapp-image"></div>
      <span class="rect-polytechnic-college-1"
        >RECT Polytechnic College started with the motive of providing
        high-quality, professional-oriented education to meet the changing needs
        of society by RECT an Education charitable Trust. RECT is located at
        Vijayanarayanam (Opposite to INS Kattabomman) a prime location with easy
        access to students. RECT Polytechnic College gives prime importance to
        develop the students’ community with the latest technical knowledge. The
        application oriented studies are the prominent requirement for any kind
        of engineering studies and the curriculum & syllabus is also phrased
        based on the same by the Directorate of Technical Education
        (DOTE).</span
      >
      <div class="material-symbols-verified-rounded"></div>
      <span class="placement-reputed-companies"
        >100% PLACEMENT IN REPUTED COMPANIES</span
      >
      <div class="material-symbols-verified-rounded-2"></div>
      <span class="excellent-infrastructure">EXCELLENT INFRASTRUCTURE</span>
    </div> -->

    <!-- <div class="container-fluid p-0" style="margin-top: 60px;    ">
        <div class="row">
            <div class="col-lg-12 p-0">
              <div style="text-align: center;  height: 529px; background-image: url('assets/images/Screenshot_8 1.png') ;">
                <h2 class="nihon1" style="font-weight: 700;  justify-content: center;">
                RECT Polytechnic College
                </h2>
              
              </div>
            </div>
        </div>
    </div> -->
    <section class="page-title d-md-block d-none" style=" background-image: url('assets/images/Screenshot_8 1.png');margin-top: 69px;">
      <!-- <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div> -->
      <div class="auto-container">
          <div class="content-box" *ngIf="lng == 'en'">
              <div class="title-box centred">
                  <h1 class="nihon1" style="font-size: 65px;">RECT Polytechnic College</h1>
                  <!-- <p>sxdcfhbjnkml,</p> -->
              </div>
             
          </div>
          <div class="content-box" *ngIf="lng == 'ja'">
            <div class="title-box centred">
                <h1 class="nihon1" style="font-size: 65px;">レクト・ポリテクニック・カレッジ</h1>
                <!-- <p>sxdcfhbjnkml,</p> -->
            </div>
           
        </div>
   
          
      </div>
  </section>
   <h1 *ngIf="lng == 'en'"   style="text-align: end; font-size: 15px; font-weight: 700; color: #f44336; margin-top: 11px; margin-right: 52PX;">Diploma in Engineering, Fluent in Japanese – Your Gateway to Global Opportunities!</h1>
   <h1 *ngIf="lng == 'ja'"  style="text-align: end; font-size: 15px; font-weight: 700; color: #f44336; margin-top: 11px; margin-right: 52PX;">     工学の卒業証書、流暢な日本語 - グローバルなチャンスへの登竜門!</h1>

  <section class="page-title d-block d-md-none" style=" background-image: url('assets/images/Screenshot_8 1.png');margin-top: -16px;">
    <!-- <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div> -->
    <div class="auto-container">
        <div class="content-box" *ngIf="lng == 'en'">
            <div class="title-box centred">
                <h1 class="nihon1" style="font-size: 30px;">RECT Polytechnic College</h1>
                <!-- <p>sxdcfhbjnkml,</p> -->
            </div>
           
        </div>
        
        <div class="content-box" *ngIf="lng == 'ja'">
          <div class="title-box centred">
              <h1 class="nihon1" style="font-size: 30px;">レクト・ポリテクニック・カレッジ</h1>
              <!-- <p>sxdcfhbjnkml,</p> -->
          </div>
         
      </div>

        
    </div>
</section>
<h1 *ngIf="lng == 'en'"  class="d-block d-md-none" style="text-align: center; font-size: 15px; font-weight: 700; color: #f44336; margin-top: 11px;">Diploma in Engineering, Fluent in Japanese – Your Gateway to Global Opportunities!</h1>
<h1 *ngIf="lng == 'ja'"  class="d-block d-md-none" style="text-align: center; font-size: 15px; font-weight: 700; color: #f44336; margin-top: 11px;">工学の卒業証書、流暢な日本語 - グローバルなチャンスへの登竜門!</h1>

<!-- <h1   style="text-align: end; font-size: 15px; font-weight: 700; color: #f44336; margin-top: 11px;">Diploma in Engineering, Fluent in Japanese – Your Gateway to Global Opportunities!</h1> -->


    <br>
<div *ngIf="lng == 'en'" >
  <section   class=" large-container row  " *ngFor="let item of rect">

    <div class="col-md-4">
        <img src="{{item.image}}" class=" h-100 d-block aboutimg">
      </div>
    <div class="col-md-8">
        <h2 style="font-weight: 800; color: black;">About RECT</h2>&nbsp;
      <p style="text-align: justify">{{item.content}}
      </p>

      <img src="assets/images/material-symbols_verified-rounded.png" style="height: 20px;">&nbsp;<span style="font-weight: 800; color: black;"class="placement-reputed-companies"
      >100% PLACEMENT IN REPUTED COMPANIES</span
    ><br>
    <img src="assets/images/material-symbols_verified-rounded.png" style="height: 20px;">&nbsp;<span style="font-weight: 800; color: black;"class="placement-reputed-companies"
    >EXCELLENT INFRASTRUCTURE</span
  >

    </div>
  
</section>
</div>
       <div *ngIf="lng == 'ja'">
        <section class=" large-container row  " *ngFor="let item of rect">

          <div class="col-md-4">
              <img src="{{item.image}}" class=" h-100 d-block aboutimg">
            </div>
          <div class="col-md-8">
              <h2 style="font-weight: 800; color: black;">レクト・ポリテクニック・カレッジについて
              </h2>&nbsp;
            <p style="text-align: justify">{{item.content}}
            </p>

            <img src="assets/images/material-symbols_verified-rounded.png" style="height: 20px;">&nbsp;<span style="font-weight: 800; color: black;"class="placement-reputed-companies"
            >有名企業への就職率100%
            </span
          ><br>
          <img src="assets/images/material-symbols_verified-rounded.png" style="height: 20px;">&nbsp;<span style="font-weight: 800; color: black;"class="placement-reputed-companies"
          >優れたインフラ</span
        >
      
          </div>
        
      </section>
       </div>
      

      
            <div class="large-container mt-3 d-md-block d-none" style="background-color: #DFEBFB;justify-content: center;;
            border-radius: 12px; padding: 35px;
            ;" >
    <div  *ngIf="lng == 'en'"><h2 style="font-weight: 800; color: black; display: flex; justify-content: center;">Diploma Courses Offered by RECT</h2></div>
    <div  *ngIf="lng == 'ja'"><h2 style="font-weight: 800; color: black; display: flex; justify-content: center;">レクトで提供されているディプロマのコース</h2></div>
   
      <div *ngIf="lng == 'en'" class="row mt-4" style="font-weight: 700;font-size: 16px;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="display: flex; justify-content: space-between;">
            <div class="col-md-4 col-sm-6"> 
                <img src="assets/images/Graduation hat.png" class="">&nbsp;
                Automobile Engineering</div>
            <div class="col-md-4 col-sm-6"> <img src="assets/images/Graduation hat.png" class="">&nbsp;Mechanical Engineering</div>
            <div class="col-md-4 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Electrical & Electronics Engineering</div>
        

        </div>
        <div class="col-md-1"></div>

      </div>
      <div *ngIf="lng == 'en'" class="row mt-2" style="font-weight: 700;font-size: 16px;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="display: flex; justify-content: space-between;">
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Civil Engineering</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Computer Engineering</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Electronics & Communication Engineering</div>

        </div>
        <div class="col-md-1"></div>

      </div>
                  
      <div *ngIf="lng == 'ja'" class="row mt-4" style="font-weight: 700;font-size: 16px;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="display: flex; justify-content: space-between;">
            <div class="col-md-4 col-sm-6"> 
                <img src="assets/images/Graduation hat.png" class="">&nbsp;
                自動車工学</div>
            <div class="col-md-4 col-sm-6"> <img src="assets/images/Graduation hat.png" class="">&nbsp;機械工学</div>
            <div class="col-md-4 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;電気電子工学</div>
        

        </div>
        <div class="col-md-1"></div>

      </div>
      <div *ngIf="lng == 'ja'" class="row mt-2" style="font-weight: 700;font-size: 16px;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="display: flex; justify-content: space-between;">
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;土木工学</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;コンピューターエンジニア</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;電子通信工学</div>

        </div>
        <div class="col-md-1"></div>

      </div>

                
             
            </div>

            
            <div *ngIf="lng == 'en'" class="large-container mt-3 d-block d-md-none" style="background-color: #DFEBFB;justify-content: center;;
            border-radius: 12px; padding: 35px;
            ;" >
    <div><h2 style="font-weight: 800; color: black; display: flex; justify-content: center;">Diploma Courses Offered by RECT</h2></div>
   
      <div class="row mt-4" style="font-weight: 700;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style=" justify-content: space-between;">
            <div class="col-md-4 col-sm-6 mt-3"> 
                <img src="assets/images/Graduation hat.png" class="">&nbsp;
                Automobile Engineering</div>
            <div class="col-md-4 col-sm-6 mt-3" > <img src="assets/images/Graduation hat.png" class="">&nbsp;Mechanical Engineering</div>
            <div class="col-md-4 col-sm-6 mt-3">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Electrical & Electronics Engineering</div>
        

        </div>
        <div class="col-md-1"></div>

      </div>
      <div class="row mt-2" style="font-weight: 700;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style=" justify-content: space-between;">
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Civil Engineering</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Computer Engineering</div>
            <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;Electronics & Communication Engineering</div>

        </div>
        <div class="col-md-1"></div>

      </div>
                  


                
             
            </div>

            <div *ngIf="lng == 'ja'" class="large-container mt-3 d-block d-md-none" style="background-color: #DFEBFB;justify-content: center;;
            border-radius: 12px; padding: 35px;
            ;" >
    <div><h2 style="font-weight: 800; color: black; display: flex; justify-content: center;">レクトで提供されているディプロマのコース</h2></div>
   
      <div class="row mt-4" style="font-weight: 700;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style=" justify-content: space-between;">
            <div class="col-md-4 col-sm-6 mt-3"> 
                <img src="assets/images/Graduation hat.png" class="">&nbsp;
                自動車工学</div>
                <div class="col-md-4 col-sm-6"> <img src="assets/images/Graduation hat.png" class="">&nbsp;機械工学</div>
                <div class="col-md-4 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;電気電子工学</div>

        </div>
        <div class="col-md-1"></div>

      </div>
      <div class="row mt-2" style="font-weight: 700;">
        <div class="col-md-1"></div>
        <div class="col-md-10" style=" justify-content: space-between;">
          <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;土木工学</div>
          <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;コンピューターエンジニア</div>
          <div class="col-md-4 mt-3 col-sm-6">      <img src="assets/images/Graduation hat.png" class="">&nbsp;電子通信工学</div>

        </div>
        <div class="col-md-1"></div>

      </div>
                  


                
             
            </div>
            

            <section class=" large-container mt-5 p-0 " *ngFor="let item of nihon">

            
          <div *ngIf="lng == 'en'" class="container" style="text-align: center;"><h2 class="nihon" style="font-weight: 800; color: black ;justify-content: center;">NIHON EDUTECH PVT. LTD &nbsp;<i style="color: #f44336; font-size: 30px;" class="fa-solid fa-handshake"></i> &nbsp;RECT POLYTECHNIC COLLEGE</h2></div>
          <div *ngIf="lng == 'ja'" class="container" style="text-align: center;"><h2 class="nihon" style="font-weight: 800; color: black ;justify-content: center;">日本エデュテック & レクトのパートナーシップ</h2></div>
            
            
            
              <div class="col-md-12 mt-4">
                <p style="text-align: justify;">{{item.content}}
                </p>
  
               
            
          
              </div>
            
          </section>
          

          <section *ngIf="lng == 'en'" id="counts" class="counts section-bg">
  <div  class="large-container"  *ngFor="let item of nihon">

    <div class="row counters">

      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-person" style="font-size: 40px;"></i>
        <h1 class="text-light">{{item.content1}}</h1>
        <h5 style="color: white; font-weight: 700;">GRADUATES</h5>
      </div>

      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-lightbulb" style="font-size: 40px;"></i>
        <div class="d-flex justify-content-center">
          <h1 class="text-light">{{item.content2}} </h1><span class="font"></span>
        </div>
        <h5 style="color: white; font-weight: 700;" >TRAINED IN JAPANESE </h5>
      </div>
      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-trophy" style="font-size: 40px;"></i>
        <h1 class="text-light" >{{item.content4}} </h1>
        <h5 style="color: white; font-weight: 700;">JAPAN PLACEMENTS</h5>
      </div>
      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-emoji-smile" style="font-size: 40px;"></i>
        <h1 class="text-light">{{item.content3}}</h1>
        <h5 style="color: white; font-weight: 700;">DOMESTIC PLACEMENTS</h5>
      </div>

    

    </div>

  </div>
</section>

<section id="counts" class="counts section-bg"  *ngIf="lng == 'ja'">
  <div  *ngFor="let item of nihon" class="large-container">

    <div class="row counters">

      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-person" style="font-size: 40px;"></i>
        <h1 class="text-light">{{item.content1}}</h1>
        <h5 style="color: white; font-weight: 700;">卒業生</h5>
      </div>

      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-lightbulb" style="font-size: 40px;"></i>
        <div class="d-flex justify-content-center">
          <h1 class="text-light">{{item.content2}} </h1><span class="font"></span>
        </div>
        <h5 style="color: white; font-weight: 700;" >日本語学習者数</h5>
      </div>
      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-trophy" style="font-size: 40px;"></i>
        <h1 class="text-light" >{{item.content4}} </h1>
        <h5 style="color: white; font-weight: 700;">日本への送り出し</h5>
      </div>
      <div class="col-lg-3 col-6 text-center text-light">
        <i class="bi bi-emoji-smile" style="font-size: 40px;"></i>
        <h1 class="text-light">{{item.content3}}</h1>
        <h5 style="color: white; font-weight: 700;">国内での就職</h5>
      </div>

    

    </div>

  </div>
</section>
<div class="large-container mt-4" style="    padding: 0px 0px 12px 0px;">
  <!-- <div class="row">
      <div class="col-lg-6 p-0">
          <div style="text-align: center;">
              <img src="../../../assets/images/Screenshot_8 1.png" class="d-block mx-auto" alt="Image">
             
           
          </div>
      </div> <br>

      <div class="col-lg-6 p-0">
        <div style="text-align: center;">
            <img src="../../../assets/images/Screenshot_8 1.png" class="d-block mx-auto" alt="Image">
           
         
        </div>
    </div>
  </div> -->


  
  <owl-carousel-o [options]="customOptionsrect">

    <ng-container *ngFor="let item of rectbanner">
        <ng-template carouselSlide>

            <div class="row collapse show" id="collapseOne" aria-labelledby="headingOne"
                data-parent="#accordion">
                <div class="col-md-12">
                  <figure class="image-box"><img src="{{item.image}}" alt="" style="height: 330px;">
                  </figure>
                
               
            </div>
          </div>


        </ng-template>
    </ng-container>
</owl-carousel-o>
</div>
<app-footer></app-footer>
     
    </div>