import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-resourcing-semi',
  templateUrl: './resourcing-semi.component.html',
  styleUrls: ['./resourcing-semi.component.css']
})
export class ResourcingSemiComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Resourcing';
  bannerDetails:any={
    title:"Nihon Edutech’s Talent Pool",
	
    description:"Taking up a career in Japan",
    image:"assets/images/background/page-title.jpg",
  }

   lng = localStorage.getItem('lng');
  sswData:any = {
	title:"",
	subtitle:"",
    description1:"",
    description2:"",
    description3:"",
	image:""
  }
  

  getTotalPlacement(): number {
	return this.waiting?.reduce((sum: number, item: { content4: number }) => sum + (item.content4 || 0), 0);
  }
  sswProcessData:any = {
    description1: "",
    description3: "",
    description4: "", 
    description5: "",
    image: ""
  }
  videos:any;
	images:any;
	paragraphs:any;
	studentTestimonial:any;
	customOptionsVideos: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		dots: false,
		smartSpeed: 1000,
		navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2
			},
			600:{
				items:3
			},
			800:{
				items:4
			},			
			1200:{
				items:5
			}
		}
	}
	customOptionsClient: OwlOptions = {
		loop: true,
		margin: 0,
		nav: false,
		dots: false,
		smartSpeed: 3000,
		autoplay: true,
		navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			600: {
				items: 3
			},
			800: {
				items: 4
			},
			1200: {
				items: 6
			}

		}
	}
	customOptionsStudent: OwlOptions = {
		loop:true,
		margin:30,
		nav:true,
		smartSpeed: 3000,
		dots: false,
		navText: [ '<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:1
			},
			600:{
				items:1
			},
			800:{
				items:2
			},
			1024:{
				items:2
			}
		}
	}
	waiting: any;
	eng: any;
	it: any;
	ssw: any;
	titp: any;
	partner: any;
	client: any;
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService,
	private sanitizer: DomSanitizer,
  ) {
    let lng = localStorage.getItem('lng');
      if(lng == null || lng == '' || lng == undefined)lng = 'en';
      translate.setDefaultLang(lng);
      this.requestService.getResourcingSemiSkilledSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
	  );
  }
  transformHtml(value:any) {
	return this.sanitizer.bypassSecurityTrustHtml(value);
}
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getResourcingSemiSkilledBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
    this.requestService.getResourcingSemiSkilledSSW()
	.pipe(first())
	.subscribe(
		data => {
			console.log("ashhsf",data)
			if(data.status == 1){
				this.sswData = data.data;
			}else{
			
			}
		},
		error => {
		}
	);
	this.requestService.getGallery(1,6)
	.pipe(first())
	.subscribe(
		data => {
			if(data.status == 1){
				this.videos = data.data;
				
			}else{
				this.videos =[
					// {"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
					// {"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
				]
			}
		},
		error => {
		}
	);
this.requestService.getGallery(0,6)
	.pipe(first())
	.subscribe(
		data => {
			if(data.status == 1){
				this.images = data.data;
			}else{
				this.images =[
					// {"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
					// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
				]
			}
		},
		error => {
		}
	);
this.requestService.getParagraph(3)
	.pipe(first())
	.subscribe(
		data => {
			if(data.status == 1){
				this.paragraphs = data.data;
				setTimeout(()=>{
					$("#pera_expand0").click();
			   }, 2000);
			}else{
				
			}
		},
		error => {
		}
	);

	this.requestService.postcorporate(3,1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.eng = data.data.reverse()
					} else {
						this.eng = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);


	this.requestService.postcorporate(2,1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.it = data.data.reverse()
					} else {
						this.it = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);

		
		this.requestService.postcorporate(1, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.ssw = data.data.reverse()
					} else {
						this.ssw = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);

			this.requestService.postcorporate(0, 1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.titp = data.data.reverse()
					} else {
						this.titp = [
							// {"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
							// {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						]
					}
				},
				error => {
				}
			);
	this.requestService.getStudentTestimonial(6)
		.pipe(first())
		.subscribe(
		data => {
			if(data.status == 1){
				this.studentTestimonial = data.data;
			}else{
			
			}
		},
		error => {
		}
	);

	this.requestService.getCompanyData(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.client = data.data;
					} else {

					}
				},
				error => {
				}
			);
	this.requestService.postwaiting(1)
	.pipe(first())
	.subscribe(
		data => {
			if (data.status == 1) {
				this.waiting = data.data.reverse()
			} else {
				// this.blogNews =[
				// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
				// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
				// ]
			}
		},
		error => {
		}
	);
  }

}
