// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl:"http://localhost:4000/api/",
  // base_url_assets:"http://localhost:4200/",
  apiUrl: "https://admin.nihonedutech.com/api/",
  base_url_assets: "https://nihonedutech.com/"
  // apiUrl: "http://test.ncareers.org:5000/api/",x
  // base_url_assets: "http://test.ncareers.org/nihon_web_frontend/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
