import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-jobs-in-japan',
  templateUrl: './jobs-in-japan.component.html',
  styleUrls: ['./jobs-in-japan.component.css']
})
export class JobsInJapanComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | TITP';
  bannerDetails:any={
    title:"TITP",
    description:"Nihon Edutech in consortium with Confederation of Indian Industry (CII) is the Leading Sending Organization of India",
    image:"assets/images/background/page-title.jpg",
  }
  titpDetails:any = {
    description:"TITP was established by Japan with an aim to contribute to developing countries by accepting interns from these countries for a period anywhere between 3 and 5 years for On-the-Job-Training (‘OJT’). In India, after entering into an MOU with Government of Japan, along with few Government departments in India, the Ministry of Skill Development & Entrepreneurship (MSDE), has appointed National Skills Development Corporation (NSDC) as the Implementing & Monitoring agency for TITP.",
    japan_placement:"2020 : 120+ Trainees 2021 : 150+ Trainees",
    segments:"Automobile, Manufacturing, Agriculture, Construction, Careworker",
    monthly_salary:"Rs.1 Lakh to 1.5 Lakh"
  }
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
  ) {
    translate.setDefaultLang('en');
    this.requestService.getJobInJapanSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
  }
    updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getJobInJapanBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
	);
    this.requestService.getJobInJapanTITP()
		.pipe(first())
		.subscribe(
			data => {
        		console.log('titp',data)
				if(data.status == 1){
					this.titpDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  }

}
