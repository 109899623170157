<div class="boxed_wrapper">
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});margin-top: 69px;">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'japanese_training.Home' | translate }}</a></li>
                    <li>{{ 'japanese_training.Japanese Language Training' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>

    <div><br>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h4 style="color:#e23936; font-weight: 800;">{{ 'japanese_training.training32' | translate }}
                    </h4>
                    <p style="color:#0353a7 ;font-weight: 800
                    ;">{{ 'japanese_training.training33' | translate }}</p>
                </div>

            </div>
        </div>
    </div>
    <br>
    <!-- <section class="growth-style-two" id="japanese_training">
            <div class="auto-container">
                <div class="row align-items-center clearfix">
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title centred">
                            <span class="top-title">{{japaneseTrain.title}}</span>
                            <h2>{{japaneseTrain.subtitle}}</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div class="text">
                            <img src="{{japaneseTrain.image}}" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                        <div id="content_block_1">
                            <div class="content-box">
                                <div class="inner-box">
                                    <p [innerHTML]="transformHtml(japaneseTrain.description)"></p>
                                    <p [innerHTML]="transformHtml(japaneseTrain.description_1)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mrgn_top" [innerHTML]="transformHtml(japaneseTrain.description_2)">
                        <p><strong>Specifically, our Japanese courses are designed to help the students to</strong></p>
                        <ul class="list_6">
                            <li>securing a job and live/work in Japan</li>
                            <li>pursuing higher studies in Japan</li>
                            <li>to interact with Japanese customers and partners for business development etc.</li>
                        </ul>
                        <p>Japanese language proficiency levels vary from entry level (N5) through intermediate (N4, N3) to advanced levels (N2, N1). While N4 level proficiency is sufficient for semi-skilled workers, for high-skilled category such as IT, Engineering and other disciplines, the minimum expectation from Japan is N3 level and above.</p>
                        <p>Nihon Edutech provides Japanese language training through different programs and extend support to students for job placements in Japan. All the programs stress on conversational skills and teaches Japanese culture and etiquettes. The programs are conducted through a combination of on-line and physical classroom sessions (presently only on-line).</p>
                    </div>
                </div>
            </div>
        </section>
             -->
    <section class="">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                    <!-- <h3 style="color:#e23936; font-size: 25px; font-weight: 700;">Course Descriptions
                    </h3> -->
                    <p style="color:#0353a7 ;font-weight: 700; font-size: 19px
                        ;">{{ 'japanese_training.training' | translate }}
                    </p>
                    <div class="inner-box">

                        <div *ngFor="let item of paragraphs " class="accordion block">
                            <div class="acc-btn">
                                <div class="icon-outer"></div>
                                <!-- <h5>{{item.title}}</h5> -->
                            </div>
                            <div class="acc-content">
                                <div class="text">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-4 bdr_right">
                                            <img src="{{item.image}}" />
                                        </div>
                                        <div class="col-md-8 col-sm-8 panel_height1"
                                            [innerHTML]="transformHtml(item.description)">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <li class="accordion block active-block">
                                    <div class="acc-btn active">
                                        <div class="icon-outer"></div>
                                        <h5>Semi-Skilled (TITP/SSW)</h5>
                                    </div>
                                    <div class="acc-content current">
                                        <div class="text_bdr">
                                            <div class="row">
                                                <div class="col-md-4 col-sm-4 bdr_right">
                                                    <img src="assets/images/titp_img1.jpg" />
                                                </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                    <h3>Semi-Skilled (TITP/SSW)</h3>
                                                    <p><strong>Overview:</strong> This program is meant for full-time learners and is best suited for students of Technical Intern Trainee Program.</p>
                                                    <p><strong>Scope:</strong>  Japanese language skills to prepare students for the JLPT N4 level.</p>
                                                    <p><strong>Classes & Duration:</strong> 4 hours/day on all weekdays.  6 Months.</p>
                                                    <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N4 level.</p>
                                                    <p><strong>Cost :</strong></p>
                                                    <div class="table-responsive-md">	
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr class="table_bg">
                                                                    <th>(Fees exclude applicable taxes)</th>
                                                                    <th>Japanese Training</th>
                                                                    <th>Books</th>
                                                                    <th>Placement Cost</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th><strong>TITP</strong></th>
                                                                    <td>INR 75,000</td>
                                                                    <td>INR 15,000</td>
                                                                    <td>INR 2,10,000</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><strong>SSW</strong></th>
                                                                    <td>INR 75,000</td>
                                                                    <td>INR 15,000</td>
                                                                    <td>TBD</td>
                                                                </tr>
                                                            </tbody>	
                                                        </table>
                                                    </div>	
                                                    <ul class="list_4n">
                                                        <li>Fees exclude Exam Fees and applicable taxes.</li>
                                                        <li>Upfront payment: INR 30,000 for Diploma Holders and INR 40,000 for B.E Degree holders.</li>
                                                    </ul>
                                                    <p>Please click this <a href="pdf/semi_skilled.pdf">link</a> to download brochure</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="accordion block">
                                    <div class="acc-btn">
                                        <div class="icon-outer"></div>
                                        <h5>High-Skilled (Professionals)</h5>
                                    </div>
                                    <div class="acc-content">
                                        <div class="text_bdr">
                                            <div class="row">
                                                <div class="col-md-4 col-sm-4 bdr_right">
                                                    <img src="assets/images/titp_img1.jpg" />
                                                </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                    <h3>High-Skilled (Professionals)</h3>
                                                    <p><strong>Overview:</strong> Targeted for working professionals, the classes are conducted without disturbing the regular working time.</p>
                                                    <p><strong>Scope:</strong> Up to JLPT N3 level. Flexibility to enroll only for selective levels.</p>
                                                    <p><strong>Classes:</strong> Week-day Program - MON-FRI 2 hours/day (Morning or Evening) and 4 hours on Saturday. Week-end Program: 4 hours each on Saturday &amp; Sunday. </p>
                                                    <p><strong>Duration:</strong> 9-12 months (for all levels) depending on weekday or week-end programs. On an average each level takes 3-4 months</p>
                                                    <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N3 level.</p>
                                                    <p><strong>Cost: (to be paid in 2-3 installments) :</strong></p>
                                                    <div class="table-responsive-md">	
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr class="table_bg">
                                                                    <th>(Fees include applicable Taxes)</th>
                                                                    <th>N5</th>
                                                                    <th>N4</th>
                                                                    <th>N3</th>
                                                                    <th>All Levels</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th><strong>Japanese Training</strong></th>
                                                                    <td>INR 16,999</td>
                                                                    <td>INR 24,999</td>
                                                                    <td>INR 24,999</td>
                                                                    <td>INR 64,999</td>
                                                                </tr>
                                                            </tbody>	
                                                        </table>
                                                    </div>	
                                                    <ul class="list_4n">
                                                        <li>Fees exclude Books and Exam Fees</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="accordion block">
                                    <div class="acc-btn">
                                        <div class="icon-outer"></div>
                                        <h5>Colleges/Universities</h5>
                                    </div>
                                    <div class="acc-content">
                                        <div class="text_bdr">
                                            <div class="row">
                                                <div class="col-md-4 col-sm-4 bdr_right">
                                                    <img src="assets/images/titp_img1.jpg" />
                                                </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                    <h3>Colleges/Universities</h3>
                                                    <p><strong>Overview:</strong> Learning Japanese language in colleges allow students to choose multiple academic and career options after they complete the degree program. This program is conducted to cover up to JLPT N3 level over 3 years duration, covering approximately one JLPT level per year.</p>
                                                    <p><strong>Scope:</strong> Japanese language skills to prepare the students for the JLPT N3 level. This program stresses on conversational skills and teaches Japanese culture and etiquettes.</p>
                                                    <p><strong>Classes:</strong> 5 hours per week.</p>
                                                    <p><strong>Duration:</strong> 3 years (1 year for each JLPT level)</p>
                                                    <p><strong>Exams/Certifications:</strong> Internal tests and examinations are conducted. Assistance provided to prepare for JLPT and NAT exams up to N3 level</p>
                                                    <p><strong>Cost :</strong>Please <a href="contactus.html">contact us</a> for pricing</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="accordion block">
                                    <div class="acc-btn">
                                        <div class="icon-outer"></div>
                                        <h5>Business Leaders (Spoken Skills)</h5>
                                    </div>
                                    <div class="acc-content">
                                        <div class="text_bdr">
                                            <div class="row">
                                                <div class="col-md-4 col-sm-4 bdr_right">
                                                    <img src="assets/images/titp_img1.jpg" />
                                                </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                    <h3>Business Leaders (Spoken Skills)</h3>
                                                    <p><strong>Overview:</strong> This program focuses only on spoken skills, best suited for middle, senior management professionals and sales personnel in corporate who needs to interact with customers or those who need not possess Japanese read/write skills during the course of their work.</p>
                                                    <p><strong>Scope:</strong> Along with spoken skills, necessary Japanese culture and etiquettes are. </p>
                                                    <p><strong>Classes &amp; Duration :</strong> 2 hours/day on all weekdays, 4 months (all levels)</p>
                                                    <p><strong>Exams/Certifications:</strong>  Tests and examinations are conducted by Nihon Edutech teachers.</p>
                                                    <p><strong>Cost :</strong>
                                                    <div class="table-responsive-md">	
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr class="table_bg">
                                                                    <th>(Fees exclude applicable taxes)</th>
                                                                    <th>Basic</th>
                                                                    <th>Pre-intermediate</th>
                                                                    <th>Intermediate</th>
                                                                    <th>All Levels</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th><strong>Japanese Training</strong></th>
                                                                    <td>INR 5,000</td>
                                                                    <td>INR 10,000</td>
                                                                    <td>INR 15,000</td>
                                                                    <td>INR 25,000</td>
                                                                </tr>
                                                            </tbody>	
                                                        </table>
                                                    </div>	
                                                    <ul class="list_4n">
                                                        <li>Fees exclude Books and Exam Fees</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> -->

                    </div>
                </div>
            </div>

        </div>
    </section>
  
    <!-- <div class=" auto-container row" *ngIf="lng == 'en'">
        <div class="col-md-12 centred">
            <h4 >New Batches Begin in</h4><br>
           
        <p style="font-size: larger; line-height: 13px;" *ngFor="let item of training_center" > <span  style=" font-weight: bold;"  > {{item.district}} </span> - <span style="color: #0353a7; font-weight: bold;" > {{ item.date | date:'MMMM yyyy' }}</span></p>

        </div>
    </div> -->
    <div class="auto-container row" *ngIf="lng == 'en'">
    
        <div class="col-md-12 centred main-table-containter" style="margin-top: 75px;background-color: #0353a7;">
            <h4 style="font-weight: 700; color: white;">New Batches Begin in</h4><br>
    
            <p class="" style="font-size: larger; line-height: 13px; display: inline-block; margin-right: 10px;     padding: 12px;" *ngFor="let item of training_center">
                <span class="completado" style="font-weight: bold; ">{{item.district}}</span> &nbsp; <span style="color: white;" ><i  class="fa fa-minus"></i></span> &nbsp;
                <span style=" color: white; font-weight: bold;">{{item.date | date:'MMMM yyyy':'ja'}}</span>
            </p>
    
        </div>
    </div>
    <!-- <div class=" auto-container row" *ngIf="lng == 'ja'">
        <div class="col-md-12 centred" style=" margin-top: 75px;">
            <h4 >新しいバッチが開始されます</h4><br>
           
        <p style="font-size: larger; line-height: 13px;" *ngFor="let item of training_center" > <span  style=" font-weight: bold; "  > {{item.district}} </span> - <span style="color: #0353a7; font-weight: bold;" > {{ item.date | date:'MMMM yyyy' }}</span></p>

        </div>
    </div> -->
    <div class="auto-container row" *ngIf="lng == 'ja'">
        <div class="col-md-12 centred main-table-containter" style="margin-top: 75px;background-color: #0353a7;">
            <h4 style="font-weight: 700; color: white;">新バッチ開講予定日</h4><br>
    
            <p class="" style="font-size: larger; line-height: 13px; display: inline-block; margin-right: 10px;     padding: 12px;" *ngFor="let item of training_center">
                <span class="completado" style="font-weight: bold; ">{{item.district}}</span> &nbsp; <span style="color: white;" ><i  class="fa fa-minus"></i></span> &nbsp;
                <span style=" color: white; font-weight: bold;">{{item.date | date:'MMMM yyyy':'ja'}}</span>
            </p>
    
        </div>
    </div>
    

    <p *ngIf="lng == 'ja'" style="text-align: center; font-size: 13px; margin-top: 12px;"  >   新しいバッチの参加に興味がありますか？お問い合わせは <a href="/enquire">  こちらから！</a>  </p><br>
    <p *ngIf="lng == 'EN'" style="text-align: center; font-size: 13px;margin-top: 12px;" >Are you interested in joining our batch? <a href="/enquire"> Contact Us</a>  and let us know!</p><br>
    <section class="">
        <div class="auto-container">
            <div class="row">
                
                    <div class="col-md-6">
                        <p style="color:#0353a7; font-size: 22px; font-weight: 700;     margin-block-end: auto;">{{ 'japanese_training.training5' | translate }}


                        </p>
                        <ul style="margin-top: 12px;">         <li> {{ 'japanese_training.training1' | translate }}</li>


                            <li>
                                {{ 'japanese_training.training2' | translate }}
    
                            </li>
                          
                            <li>
                                {{ 'japanese_training.training3' | translate }}
    
                            </li>
                            <li>
                                {{ 'japanese_training.training4' | translate }}
    
                            </li></ul>
               

                        <p></p>


                    </div>

                    <div class="col-md-6" >  
                        <img src="../../../assets/images/2520.jpg" style="    height: 252px;
                        width: 500px;">
                        
                                   
                                         
                        
                        
                                        </div>
                </div><br>

              
                    <!-- <div class="col-md-4"></div> -->


            </div>





       
    </section>
    <section class="">
        <div class="auto-container">
            
            <p style="color:#0353a7 ;font-weight: 700; font-size: 22px
            ;">  {{ 'japanese_training.training6' | translate }}</p>
            <div class='main-table-containter'>
                <div class='title-table-container'>
                    <div class='subtitle'> </div>

                </div>
                <div>
                    <table >
                        <tbody>
                            <tr style="font-size: 22px; color: black;font-weight: bolder;">
                                <td style="       width:200px;">  {{ 'japanese_training.training7' | translate }}</td>
                                <td style="       width:200px;">  {{ 'japanese_training.training8' | translate }}</td>
                                <!-- <td>El grupo gutsky</td> -->
                                <td style="       width:200px;">  {{ 'japanese_training.training9' | translate }}</td>
                                <td style="       width:218px;;">  {{ 'japanese_training.training10' | translate }}</td>
                                <td style="       width:264px;;">  {{ 'japanese_training.training15' | translate }}</td>
                                <!-- <td><svg class='dot' viewBox="0 0 512 512" width="100" title="ellipsis-h">
                    <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
                  </svg></td> -->
                            </tr>

                            <tr style="font-size: 15px;">
                                <td><span class="completado">JLPT</span><br>
                                    {{ 'japanese_training.training11' | translate }}
                                </td>
                                <td>N1-N5
                                </td>
                                <!-- <td>El grupo gutsky</td> -->
                                <td>   {{ 'japanese_training.training12' | translate }}
                                </td>
                                <td>   {{ 'japanese_training.training13' | translate }}</td>
                                <td>   {{ 'japanese_training.training14' | translate }}
                                </td>
                                <!-- <td><svg class='dot' viewBox="0 0 512 512" width="100" title="ellipsis-h">
                   <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
                 </svg></td> -->
                            </tr>

                            <tr style="font-size: 15px;">
                                <td><span class="completado">NAT</span><br>
                                    {{ 'japanese_training.training16' | translate }}

                                </td>
                                <td>N1-N5
                                </td>
                                <!-- <td>El grupo gutsky</td> -->
                                <td>{{ 'japanese_training.training17' | translate }}

                                </td>
                                <td>{{ 'japanese_training.training18' | translate }}

                                </td>
                                <td>{{ 'japanese_training.training19' | translate }}
                                </td>
                                <!-- <td><svg class='dot' viewBox="0 0 512 512" width="100" title="ellipsis-h">
                   <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
                 </svg></td> -->
                            </tr>

                            <tr style="font-size: 15px;">
                                <td><span class="completado">JFT-Basic
                                    </span><br>
                                    {{ 'japanese_training.training20' | translate }}


                                </td>
                                <td>-
                                </td>
                                <!-- <td>El grupo gutsky</td> -->
                                <td>{{ 'japanese_training.training21' | translate }}

                                </td>
                                <td>{{ 'japanese_training.training22' | translate }}


                                </td>
                                <td>{{ 'japanese_training.training23' | translate }}
                                </td>
                                <!-- <td><svg class='dot' viewBox="0 0 512 512" width="100" title="ellipsis-h">
                   <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
                 </svg></td> -->
                            </tr>

                            <!-- <tr>
                            <td><div class='icono-texto'><DIV class='contenedor-svg'><svg class='dolar' viewBox="0 0 288 512" width="100" title="dollar-sign">
                    <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" />
                  </svg></DIV> <div>Salario</div></div></td>
                             <td> May 12, 2024</td>
                             <td>El grupo gutsky</td>
                             <td>#F6F6F4</td>
                            <td><div class='completado'>Completado</div></td>
                             <td>$9,000</td>
                            <td><svg class='dot' viewBox="0 0 512 512" width="100" title="ellipsis-h">
                    <path d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z" />
                  </svg></td>
                          </tr> -->
                        </tbody>
                    </table>
                </div>

            </div>


        </div>
    </section>

    <section class="mt-5">
        <div class="auto-container">
            <div class="row">
                <p style="color:#0353a7; font-size: 22px; font-weight: 700; " >{{ 'japanese_training.training24' | translate }}


                </p><br>
                <div class="col-md-12  ">
                


                       

                    {{ 'japanese_training.training25' | translate }}<br>
                    {{ 'japanese_training.training26' | translate }}<br>
                    {{ 'japanese_training.training27' | translate }}<br>
                    {{ 'japanese_training.training28' | translate }}  <span style="color: #e23936; font-weight: bolder; font-size: 12px;"> {{ 'japanese_training.training29' | translate }}</span><br>
                    {{ 'japanese_training.training30' | translate }}<br>
              

           
            </div> </div>





        </div>
    </section>



    <div class="auto-container mt-5">
        <div class="sec-title">
            <span class="top-title">{{ 'japanese_training.Gallery' | translate }}</span>
        </div>
        <owl-carousel-o [options]="customOptionsVideos">
            <ng-container *ngFor="let item of images">
                <ng-template carouselSlide>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url({{item.image}});">
                                </div>
                                <div class="lower-cont">
                                    <h3>{{item.title}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <!-- <div  class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div *ngFor = "let item of images" class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url({{item.image}});">
                            </div>
                            <div class="lower-cont">
                                <h3>{{item.title}}</h3>
                            </div>
                        </div>
                    </div>
                </div> -->
        <!-- <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img1.jpg);">
                            </div>
                            <div class="lower-cont">
                                <h3>Manufacturing Interns Training</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img2.jpg);">
                            </div>
                            <div class="lower-cont">
                                <h3>CareWorker Interns Training</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img3.jpg);">
                            </div>
                            <div class="lower-cont">
                                <h3>CareWorker Interns Training</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img4.jpg);">
                            </div>
                            <div class="lower-cont">
                                <h3>CareWorker Interns Training</h3>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img5.jpg);">
                            </div>
                            <div class="lower-cont">
                                <h3>CareWorker Interns Training</h3>
                            </div>
                        </div>
                    </div>
                </div>   -->
        <!-- </div> -->
    </div>
    <div class="auto-container">
        <div class="sec-title">
            <span class="top-title">{{ 'japanese_training.Videos' | translate }}</span>
        </div>
        <owl-carousel-o [options]="customOptionsVideos">
            <ng-container *ngFor="let item of videos">
                <ng-template carouselSlide>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url({{item.image}});">
                                    <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                            </div>
                            <div class="lower-cont">
                                <h3>{{item.title}}</h3>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div *ngFor = "let item of videos" class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url({{item.image}});">
                                <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>{{item.title}}</h3>
                        </div>
                    </div>
                </div> -->
        <!-- <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                                <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>Our Training Program</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>2021-Japan Job Opportunities</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Balaji</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                                <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                    <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                                <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Pankaj</h3>
                        </div>
                    </div>
                </div>                     -->
        <!-- </div> -->
    </div>
   

    <section class="testimonial-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title">
                <span class="top-title">{{ 'japanese_training.Student Testimonials' | translate }}</span>
            </div>
            <owl-carousel-o [options]="customOptionsStudent">
                <ng-container *ngFor="let item of studentTestimonial">
                    <ng-template carouselSlide>
                        <div class="testimonial-block-one">
                            <div class="inner-box">
                                <div class="border-shap"
                                    style="background-image: url(assets/images/shape/border-1.png);"></div>
                                <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                <div class="author-box">
                                    <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                    <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                </div>
                                <div class="text">
                                    <p>{{item.description1}}</p>
                                    <p>{{item.description2}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="testimonial-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                    
                    <div *ngFor="let item of studentTestimonial" class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                            <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                            <div class="author-box">
                                <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                <h3>{{item.name}}<span>{{item.training}}</span></h3>
                            </div>
                            <div class="text">
                                <p>{{item.description1}}</p>
                                <p>{{item.description2}}</p>
                            </div>
                        </div>
                    </div> -->
            <!-- <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                            <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                            <div class="author-box">
                                <figure class="author-thumb"><img src="assets/images/testimonials1.jpg" alt=""></figure>
                                <h3>Carmel Alexander<span>TITP Intern - 2018 batch</span></h3>
                            </div>
                            <div class="text">
                                <p>I have learnt Japanese language and culture in Nihon Edutech by native Japanese</p>
                                <p>私たちは日本語と日本語の文川日本一テックという会 社で日本人からおしえていただきましたしました</p>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                            <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                            <div class="author-box">
                                <figure class="author-thumb"><img src="assets/images/testimonials2.jpg" alt=""></figure>
                                <h3>Sheik Mohaideen Badhusha<span>TITP Intern - 2018 batch</span></h3>
                            </div>
                            <div class="text">
                                <p>Nihon Edutech is giving several jobs opportunities in Japan such as construction, nursing, agriculture and other sectors, and also providing language and culture training within a short duration.</p>
                                <p>日本語や文化などのトレーニングの短い期間内に日本でいくつかの仕事の機会を与えてくれてる</p>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                            <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                            <div class="author-box">
                                <figure class="author-thumb"><img src="assets/images/testimonials1.jpg" alt=""></figure>
                                <h3>Jeyaraman<span>TITP Intern - 2018 batch</span></h3>
                            </div>
                            <div class="text">
                                <p>I am in Japan, the land of rising sun, for almost 2 1/2 years. I am really glad to be here with very kind Japanese people.</p>
                                <p>約2年半位ここのぼる太陽の国に住んでますここでこんな優しい日本人といるのがとてもうれしいと思い</p>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-block-one">
                        <div class="inner-box">
                            <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                            <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                            <div class="author-box">
                                <figure class="author-thumb"><img src="assets/images/testimonials2.jpg" alt=""></figure>
                                <h3>Suthakar<span>TITP Intern - 2018 batch</span></h3>
                            </div>
                            <div class="text">
                                <p>I went to so many places and met so many people here in Japan. I have studied before that Japanese are serious on their work. Now I’m glad to actually experience it.</p>
                                <p>いろんな行ってきましたいろんな人と会いましたNE で勉強中で日本人が仕事で真面目と聞いたことありますけどそれを実際に経験することができていいなぁと思ってます</p>
                            </div>
                        </div>
                    </div> -->
            <!-- </div> -->
        </div>
    </section>
    <app-footer></app-footer>
    <!-- <footer class="main-footer style-two bg-color-1">
                <div class="auto-container">
                    <div class="footer-top">
                        <div class="widget-section">
                            <div class="row clearfix">
                                <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div class="footer-widget about-widget">
                                        <div class="widget-title">
                                            <h3>About Company</h3>
                                        </div>
                                        <div class="text">
                                            <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                                            <img src="assets/images/startupindia2.png" alt="" class="">
                                            <p class="bm">Recognized: (DIPP63113)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                                    <div class="footer-widget links-widget">
                                        <div class="widget-title">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <ul class="links-list clearfix">
                                            <li><a href="aboutus.html">About Us</a></li>
                                            <li><a href="contactus.html">Contact Us</a></li>
                                            <li><a href="learning.html">Learning</a></li>
                                            <li><a href="jobs_in_japan_semi_skilled.html">Jobs in Japan</a></li>	
                                            <li><a href="resourcing.html">Resourcing</a></li>
                                            <li><a href="technology.html">Technology</a></li>
                                            <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                            <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                            <li><a href="schedule_session.html">Schedule a Session</a></li>
                                        </ul>
                                        <div class="about-widget">
                                        <ul class="social-links clearfix">
                                            <li><h5>Connected:</h5></li>
                                            <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                        </ul>
                                        <img src="assets/images/veltech.jpg"> <a href="#">TBI Incubatee</a>
                                    </div></div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                                    <div class="footer-widget callback-widget">
                                        <div class="pattern-layer">
                                            <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                            <div class="pattern-2"></div>
                                            <div class="pattern-3"></div>
                                            <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                                        </div>
                                        <div class="widget-content">
                                            <form action="index-2.html" method="post" class="callback-form">
                                                <div class="row clearfix">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input type="text" name="name" placeholder="Name*" required="">
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input type="email" name="email" placeholder="Email*" required="">
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <input type="text" name="phone" placeholder="Phone*" required="">
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                        <div class="select-box">
                                                <select class="wide" style="display: none;">
                                                   <option data-display="Please Select">Please Select</option>
                                                   <option value="1">TITP</option>
                                                   <option value="2">SSW/SSP</option>
                                                   <option value="3">IT/Engineering</option>
                                                </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                            </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                        <button type="submit" class="theme-btn-one">Get Call Back</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-bottom clearfix">
                        <div class="copyright pull-left">
                            <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                        </div>
                        <ul class="footer-nav pull-right clearfix">
                            <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                        </ul>
                    </div>
                </div>
        </footer>
        <button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>
<!-- <div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header-two></app-header-two>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
   <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'japanese_training.Home' | translate }}</a></li>
                    <li>{{ 'japanese_training.Japanese Training' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>
    
    <section class="sidebar-page-container blog-details">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                    <div class="blog-details-content">
                        <div class="inner-box">
                            <div class="post-time"><h3>{{ 'japanese_training.Japanese Training' | translate }}</h3></div>
                            <div class="video-section">
                                <div class="auto-container">
                                    <div id="image_block_1">
                                        <div class="image-box">
                                            <div class="video-inner" style="background-image: url({{japaneseTrain.image}});">
                                                <a href="{{japaneseTrain.video_link}}" class="lightbox-image video-btn" data-caption="">
                                                    <i class="flaticon-play"></i>
                                                </a>
                                                <div class="border"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text" style="margin-top:46px;">
                                <p>{{japaneseTrain.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                    <div class="blog-sidebar">
                         <div class="sidebar-widget post-widget">
                            <div class="widget-title">
                                <h3>{{ 'japanese_training.Related Courses' | translate }}</h3>
                            </div>
                            <div class="widget-content">
                                <div class="post">
                                    <figure class="post-thumb"><a href="#"><img src="assets/images/news/phyton.jpg" alt=""></a></figure>
                                    <h5><a href="#">{{ 'japanese_training.Learning Python Generators' | translate }}</a></h5>
                                     <span class="post-date">{{ 'japanese_training.2 hrs Ago' | translate }}</span>
                                </div>
                                <div class="post">
                                    <figure class="post-thumb"><a href="#"><img src="assets/images/news/phyton2.jpg" alt=""></a></figure>
                                    <h5><a href="#">{{ 'japanese_training.Python: Programming Efficiently (2017)' | translate }}</a></h5>
                                    <span class="post-date">{{ 'japanese_training.21st june, 2020' | translate }}</span>
                                </div>
                                <div class="post">
                                    <figure class="post-thumb"><a href="#"><img src="assets/images/news/phyton3.jpg" alt=""></a></figure>
                                    <h5><a href="#">{{ 'japanese_training.What are the Staffing Trends in 2020' | translate }}</a></h5>
                                    <span class="post-date">{{ 'japanese_training.28th May, 2020' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="sidebar-widget categories-widget">
                            <div class="widget-title">
                                <h3>{{ 'japanese_training.Content' | translate }}</h3>
                                <i class="flaticon-note"></i>
                            </div>
                            <div class="widget-content">
                                <ul class="list clearfix">
                                    <li><a href="#">{{ 'japanese_training.Introduction' | translate }}<span><i class="fas fa-lock"></i></span></a></li>
                                    <li><a href="#">{{ 'japanese_training.Python anatomy' | translate }}<span>[3]</span></a></li>
                                    <li><a href="#">{{ 'japanese_training.Expressions and statements' | translate }}<span>[2]</span></a></li>
                                    <li><a href="#">{{ 'japanese_training.Whitespace and comments' | translate }}<span>[5]</span></a></li>
                                    <li><a href="#">{{ 'japanese_training.Conditionals' | translate }}<span><i class="fas fa-lock"></i></span></a></li>
                                    <li><a href="#">{{ 'japanese_training.Functions' | translate }}<span><i class="fas fa-lock"></i></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div> -->