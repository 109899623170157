import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  private isVisible: boolean = false;

  constructor() {}

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }

  getIsVisible(): boolean {
    return this.isVisible;
  }
}
