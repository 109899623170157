<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header-two></app-header-two>
    <!-- <header class="main-header style-two">
        <div class="header-top">
            <div class="top-inner">
                 <figure class="logo-box"><a href="index.html"><img src="assets/images/logo_careers.png" alt=""></a></figure>
                <div class="location-box">
                </div>
                <div class="right-info">
                    <ul class="list">
                        <li><a href="index.html">About Us</a></li>
                        <li><a href="index.html">Locations</a></li>
                        <li><a href="index.html">Resources</a></li>
                    </ul>
                    <ul class="social-links">
                        <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </header> -->

<div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
   <section class="page-title" style="background-image: url(assets/images/banner/banner-11.jpg);">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>IT</h1>
                    <p>The goal of education is understanding;<br> the goalof training is performance.</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">Home</a></li>
                    <li>IT</li>
                </ul>
            </div>
        </div>
    </section>
    
    <section class="sidebar-page-container blog-details">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                    <div class="blog-details-content">
                        <div class="inner-box">
                            <div class="post-time"><h3>IT</h3></div>
                           
                           
                            <div class="video-section">
        <div class="auto-container">
            <div id="image_block_1">
                <div class="image-box">
                    <div class="video-inner" style="background-image: url(assets/images/project/course_it3_b.jpg);">
                        <a href="https://www.youtube.com/watch?v=q3TSb_LwI6o" class="lightbox-image video-btn" data-caption="">
                            <i class="flaticon-play"></i>
                        </a>
                        <div class="border"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                           
                            <div class="text" style="margin-top:46px;">
                                <p>Information technology (IT) is the use of computers to store, retrieve, transmit, and manipulate data or information. IT is typically used within the context of business operations as opposed to personal or entertainment technologies. IT is considered to be a subset of information and communications technology (ICT).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                    <div class="blog-sidebar">
                         <div class="sidebar-widget post-widget">
                            <div class="widget-title">
                                <h3>Related Courses</h3>
                            </div>
                            <div class="widget-content">
                                <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/phyton.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">Learning Python Generators</a></h5>
                                     <span class="post-date">2 hrs Ago</span>
                                </div>
                                <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/phyton2.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">Python: Programming Efficiently (2017)</a></h5>
                                    <span class="post-date">21st june, 2020</span>
                                </div>
                                <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/phyton3.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">What are the Staffing Trends in 2020</a></h5>
                                    <span class="post-date">28th May, 2020</span>
                                </div>
                            </div>
                        </div>
                        <div class="sidebar-widget categories-widget">
                            <div class="widget-title">
                                <h3>Content</h3>
                                <i class="flaticon-note"></i>
                            </div>
                            <div class="widget-content">
                                <ul class="list clearfix">
                                    <li><a href="blog-details.html">Introduction<span><i class="fas fa-lock"></i></span></a></li>
                                    <li><a href="blog-details.html">Python anatomy<span>[3]</span></a></li>
                                    <li><a href="blog-details.html">Expressions and statements<span>[2]</span></a></li>
                                    <li><a href="blog-details.html">Whitespace and comments<span>[5]</span></a></li>
                                    <li><a href="blog-details.html">Conditionals<span><i class="fas fa-lock"></i></span></a></li>
                                    <li><a href="blog-details.html">Functions<span><i class="fas fa-lock"></i></span></a></li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
   <!-- <footer class="main-footer style-two bg-color-1">
        <div class="auto-container">
            <div class="footer-top">
                <div class="widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget about-widget">
                                <div class="widget-title">
                                    <h3>About Company</h3>
                                </div>
                                <div class="text">
                                    <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul class="links-list clearfix">
                                    <li><a href="aboutus.html">About Us</a></li>
                                    <li><a href="learning.html">Learning</a></li>
                                    <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>	
                                    <li><a href="resourcing.html">Resourcing</a></li>
                                    <li><a href="technology.html">Technology</a></li>
                                    <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                    <li><a href="contactus.html">Contact Us</a></li>
                                    <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                    <li><a href="learning.html#schedule">Schedule a Session</a></li>
                                </ul>
                                <div class="about-widget">
                                <ul class="social-links clearfix">
                                    <li><h5>Connected:</h5></li>
                                    <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div></div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                            <div class="footer-widget callback-widget">
                                <div class="pattern-layer">
                                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                    <div class="pattern-2"></div>
                                    <div class="pattern-3"></div>
                                    <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                                </div>
                                <div class="widget-content">
                                    <form action="index-2.html" method="post" class="callback-form">
                                        <div class="row clearfix">
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="name" placeholder="Name*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="email" name="email" placeholder="Email*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="phone" placeholder="Phone*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <div class="select-box">
                                        <select class="wide" style="display: none;">
                                           <option data-display="Please Select">Please Select</option>
                                           <option value="1">TITP</option>
                                           <option value="2">SSW/SSP</option>
                                           <option value="3">IT/Engineering</option>
                                        </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                    </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <button type="submit" class="theme-btn-one">Get Call Back</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom clearfix">
                <div class="copyright pull-left">
                    <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right clearfix">
                    <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                </ul>
            </div>
        </div>
    </footer>
    <button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>