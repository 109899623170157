import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './component/home/home.component';
import { JapaneseTrainingComponent } from './component/japanese-training/japanese-training.component';
import { RegisterComponent } from './component/register/register.component';
import { JobsInJapanComponent } from './component/jobs-in-japan/jobs-in-japan.component';
import { JobsInJapanSemiComponent } from './component/jobs-in-japan-semi/jobs-in-japan-semi.component';
import { JobsInJapanProfComponent } from './component/jobs-in-japan-prof/jobs-in-japan-prof.component';
import { ResourcingComponent } from './component/resourcing/resourcing.component';
import { ResourcingSemiComponent } from './component/resourcing-semi/resourcing-semi.component';
import { ResourcingProfComponent } from './component/resourcing-prof/resourcing-prof.component';
import { TechnologyComponent } from './component/technology/technology.component';
import { BlogsNewsComponent } from './component/blogs-news/blogs-news.component';
import { ScheduleSessionComponent } from './component/schedule-session/schedule-session.component';
import { LearningComponent } from './component/learning/learning.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { GetJobComponent } from './component/get-job/get-job.component';
import { CandidateDetailsComponent } from './component/candidate-details/candidate-details.component';
import { SoftskillsComponent } from './component/softskills/softskills.component';
import { ItSkillsComponent } from './component/it-skills/it-skills.component';
import { SswComponent } from './component/ssw/ssw.component';
import { LearnchainComponent } from './component/learnchain/learnchain.component';
import { SswSspComponent } from './component/ssw-ssp/ssw-ssp.component';
import { TechnicalTrainingComponent } from './component/technical-training/technical-training.component';
import { ItEngineersComponent } from './component/it-engineers/it-engineers.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { BrowseCandidatesComponent } from './component/browse-candidates/browse-candidates.component';
import { TechServicesComponent } from './component/tech-services/tech-services.component';
import { CustomerComponent } from './component/customer/customer.component';
import { PartnerComponent } from './component/partner/partner.component';
import { EnquireComponent } from './component/enquire/enquire.component';
import { RectPageComponent } from './component/rect-page/rect-page.component';


const routes: Routes = [
  // { path: 'index', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'japanese-training',component: JapaneseTrainingComponent },
  { path: 'register',component: RegisterComponent },
  { path: 'jobs_in_japan',component: JobsInJapanComponent },
  { path: 'jobs_in_japan_semi_skilled',component: JobsInJapanSemiComponent },
  { path: 'jobs_in_japan_professional',component: JobsInJapanProfComponent },
  { path: 'resourcing',component: ResourcingComponent },
  { path: 'resourcing_semi_skilled',component: ResourcingSemiComponent },
  { path: 'resourcing_professional',component: ResourcingProfComponent },
  { path: 'technology',component: TechnologyComponent },
  { path: 'blogs_news/:id',component: BlogsNewsComponent },
  { path: 'schedule_session',component: ScheduleSessionComponent },
  { path: 'learning',component: LearningComponent },
  { path: 'about_us',component: AboutUsComponent },
  { path: 'contact_us',component: ContactUsComponent },
  { path: 'privacy_policy',component: PrivacyPolicyComponent },
  { path: 'get_job',component: GetJobComponent },
  { path: 'candidate_details',component: CandidateDetailsComponent },
  { path: 'softskills',component: SoftskillsComponent },
  { path: 'itskills',component: ItSkillsComponent },
  { path: 'ssw',component: SswComponent },
  { path: 'learnchain',component: LearnchainComponent },
  { path: 'ssw_ssp',component: SswSspComponent },
  { path: 'technical_training',component: TechnicalTrainingComponent },
  { path: 'it_engineers',component: ItEngineersComponent },
  { path: 'gallery',component: GalleryComponent },
  { path: 'browse_candidates',component: BrowseCandidatesComponent },
  { path: 'tech_services',component: TechServicesComponent },
  { path: 'Customer',component: CustomerComponent },
  { path: 'Partner',component: PartnerComponent },
  { path: 'enquire',component: EnquireComponent },
  { path: 'rect',component: RectPageComponent },

  



  
  // {
  //   path: 'about-us',
  //   loadChildren: () => import('./component/about-us/about-us.module').then((m) => m.AboutUsModule)
  // },
  // {
  //   path: 'services',
  //   loadChildren: () => import('./component/services/services.module').then((m) => m.ServicesModule)
  // },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
