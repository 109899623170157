import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  selected_lng: string = "EN"
  constructor(
    private translate: TranslateService
  ) {
    // let lng = localStorage.getItem('lng');
    // if (lng == null || lng == '' || lng == undefined) lng = 'en';
    // translate.setDefaultLang(lng);
    // if (lng == 'en') lng = 'EN';
    // if (lng == 'ja') lng = '日本語';
    // this.selected_lng = lng.toUpperCase();
  }

  lngChangeHandler(lng: any) {
    localStorage.setItem('lng', lng);
    location.reload();
  }

  getPdfLink(type: string, defaultLanguage: string = 'en'): string {
    const currentLanguage = localStorage.getItem('lng');
    let pdfLink = '';
  
    if (currentLanguage === 'en' || !currentLanguage) {
      if (type === 'customer') {
        pdfLink = '/assets/pdf/English_brochure_customer.pdf';
      } else if (type === 'partner') {
        pdfLink = '/assets/pdf/English_Brouchure_agency_new may_23.pdf';
      }
    } else if (currentLanguage === 'ja') {
      // Set Japanese PDF links here
      if (type === 'customer') {
        pdfLink = '/assets/pdf/Japanese_Brouchure_customer.pdf';
      } else if (type === 'partner') {
        pdfLink = '/assets/pdf/English_Brouchure_agency_new may_23.pdf';
      }
    }
  
    return pdfLink;
  }

  ngOnInit(): void {
    let lng = localStorage.getItem('lng');
    if (lng == null || lng == '' || lng == undefined) lng = 'en';
    this.translate.setDefaultLang(lng);
    if (lng == 'en') lng = 'EN';
    if (lng == 'ja') lng = '日本語';
    this.selected_lng = lng.toUpperCase();
  }



}

