<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header-two></app-header-two>
    <!-- <header class="main-header style-two">
        <div class="header-top">
            <div class="top-inner">
                 <figure class="logo-box"><a href="index.html"><img src="assets/images/logo_careers.png" alt=""></a></figure>
                <div class="location-box">
                </div>
                <div class="right-info">
                    <ul class="list">
                        <li><a href="index.html">About Us</a></li>
                        <li><a href="index.html">Locations</a></li>
                        <li><a href="index.html">Resources</a></li>
                    </ul>
                    <ul class="social-links">
                        <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div> -->
        <!-- <div class="header-lower">
            <div class="outer-box clearfix">
               &nbsp;
            </div>
        </div> -->
<div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    
    <section class="banner-section style-two">
        <div class="banner-carousel owl-theme owl-carousel">
            <div class="slide-item">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-9.jpg)"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <span>Nihon Edutech Training</span>
                        <h1>Confidence comes from<br> discipline and training</h1>
                        <div class="btn-box">
                            <a href="hire_now.html" class="btn-one">Hire Now</a>
                            <a routerLink="/get_job" class="btn-two">Get job Now</a>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </section>
  
      <section class="findjob-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">Candidates</span>
                <h2>Work to become, not to acquire</h2>
                <p>Job training empowers people to realize their dreams and improve their lives</p>
            </div>
        
            <div class="post-jobs">
                <div class="single-job-post">
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <h3>Technical Intern Training Program (TITP)</h3>
                            <p><i class="flaticon-place"></i>Nihon Edutech</p>
                        </div>
                        <div class="salary-box">
                            <p>TITP was established by Japan with an aim to contribute to developing countries by accepting interns from these countries.</p>
                        </div>
                        <div class="experience-box">
                            <span>Course Fees</span>
                        </div>
                        <div class="apply-btn">
                            <a href="view_details.html">Details</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <h3>Japanese Language Training</h3>
                            <p><i class="flaticon-place"></i>Nihon Edutech</p>
                        </div>
                        <div class="salary-box">
                            <p>Japanese Language Proficiency Test is a test of Japanese ability for non-native students of Japanese.</p>
                        </div>
                        <div class="experience-box">
                            <span>Course Fees</span>
                        </div>
                        <div class="apply-btn">
                            <a href="view_details.html">Details</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <h3>Specified Skilled Worker (SSW)</h3>
                            <p><i class="flaticon-place"></i>Nihon Edutech</p>
                        </div>
                        <div class="salary-box">
                            <p>SSW is a new status of residence introduced by Japan in April 2019 to attract foreign talent with a certain level of expertise.</p>
                        </div>
                        <div class="experience-box">
                            <span>Course Fees</span>
                        </div>
                        <div class="apply-btn">
                            <a href="view_details.html">Details</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <h3>Technical Training</h3>
                            <p><i class="flaticon-place"></i>Nihon Edutech</p>
                        </div>
                        <div class="salary-box">
                            <p>Technical training is an integral part of nihoncareers platform and upskilling in technical and relevant IT skills.</p>
                        </div>
                        <div class="experience-box">
                            <span>Course Fees</span>
                        </div>
                        <div class="apply-btn">
                            <a href="view_details.html">Details</a>
                        </div>
                    </div>
                </div>
                <div class="single-job-post">
                    <div class="job-inner clearfix">
                        <div class="job-title">
                            <h3>Learnchain</h3>
                            <p><i class="flaticon-place"></i>Nihon Edutech</p>
                        </div>
                        <div class="salary-box">
                            <p>Learnchain is a robust Marketplace Platform for Trainers to launch and manage training programs.</p>
                        </div>
                        <div class="experience-box">
                            <span>Course Fees</span>
                        </div>
                        <div class="apply-btn">
                            <a href="view_details.html">Details</a>
                        </div>
                    </div>
                </div>
           
                <ul class="pagination">
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                  </ul>
            </div>
        </div>
    </section>
    <app-footer-two></app-footer-two>
</div>