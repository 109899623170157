import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-schedule-session',
  templateUrl: './schedule-session.component.html',
  styleUrls: ['./schedule-session.component.css']
})
export class ScheduleSessionComponent implements OnInit {
	loading = false;
	submitted = false;
	enqForm:any;
	selectedtype:any;
	errorMsg:any = "";
	successMsg:any = "";
  title = 'Nihon Edutech | Schedule a Session';
  bannerDetails:any={
    title:"Schedule a Session",
    description:"Book a Counselling Session with our Overseas and Training Counsellor",
    image:"assets/images/background/page-title.jpg",
  }
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private formBuilder: FormBuilder,
		private requestService: RequestService,
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getScheduleSessionSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
  }
  
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
	]);
	this.enqForm = this.formBuilder.group({
		first_name: ['', Validators.required],
		last_name: ['', Validators.required],
		email: ['', Validators.required],
		mobile: ['', Validators.required],
		experience: ['', Validators.required],
		job_role: ['', Validators.required],
		message: ['', Validators.required],
  });
  this.requestService.getScheduleSessionBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
  }

  get f() { return this.enqForm.controls; }
  onSubmit(){
    this.submitted = true;
    if (this.enqForm.invalid) {
        return;
    }
    this.loading = true;
    this.requestService.addDemo(this.enqForm.value)
    .pipe(first())
    .subscribe(
      data => {
        console.log('data',data);
        if(data.status == 1){
          this.successMsg = data.message;
          setTimeout(() => {
            this.successMsg = '';
          }, 1500);
        }else{
          this.errorMsg = data.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 1500);
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

}
