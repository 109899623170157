<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    
     <section class="page-title" style="background-image: url({{bannerDetails.image}});margin-top: 69px;">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'privacy-policy.Home' | translate }}</a></li>
                    <li>{{ 'privacy-policy.Privacy Policy' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>
    
    
    <section class="about-style-three" id="learnchain">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title centred">
                        <span class="top-title">{{ 'privacy-policy.Nihon Edutech' | translate }}</span>
                        <h2>{{ 'privacy-policy.Privacy Policy' | translate }}</h2>
                        <p>... {{ 'privacy-policy.Our teams have a combined 100+ years of work experience in Japan' | translate }}</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="job-details-content">
                                <div class="text" [innerHTML]="privacyData.description">
                                    <!-- <h3>PLEASE READ THIS POLICY TO LEARN MORE ABOUT HOW NIHON EDUTECH TREATS PERSONALLY IDENTIFIABLE INFORMATION AND USES CERTAIN INFORMATION</h3>
                                    <p>Nihon Edutech Private Limited ("Nihon Edutech", "us" , "our", or "we") respects the privacy and personal information of the individuals that visit our website ("Website"). This privacy policy ("Privacy Policy") applies to information we receive when you use the Website and does not cover information Nihon Edutech collects in other ways. This Privacy Policy is an agreement between Nihon Edutech and you, as the user of the Website. We share your personal information only with your consent or as described in this Privacy Policy.</p>
                                    <h3>Changes and Modifications:</h3>
                                    <p>Changes will become effective thirty (30) days after they are posted to the Website or we otherwise notify you of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information was collected. We may, in our sole discretion, modify the Privacy Policy at any time, and your continued use of the Website after we post any such modifications will signify your agreement to be bound by such modifications.</p>
                                    <h3>TERMS OF USE</h3>
                                    <p>Any location in the Website you wish visit is purely voluntary. You are not required to provide any personal information to us unless you choose to access features of the Website which require such information.</p>
                                    <h3>POLICY APPLICABLE TO ONLINE ACTIVITIES ONLY</h3>
                                    <p>Any location in the Website you wish visit is purely voluntary. You are not required to provide any personal information to us unless you choose to access features of the Website which require such information.</p>
                                    <h3>PERSONAL INFORMATION ABOUT YOU<br>
                                    Types Of Information Collected:</h3>
                                    <p>As a condition to using certain components of the Website, you may be required to establish an account ("Account") with us. We may ask you for certain information, referred to as "personal information," which includes information that pertains to your identity. Such information may include, but is not limited to, items such as your name, home or other physical address, a telephone number, an email address, or any information that uniquely identifies you.</p>
                                    <h3>Personal Information You Provide to Us:</h3>
                                    <p>You may be required to provide personal information to access or use certain parts or features of the Website. For example, you will be asked to provide your name and a valid email address to establish an Account. If you do not provide the requested personal information, you may not be able to access or use the particular component of the Website where such information is required.</p>
                                    <h3>INFORMATION WE MAY COLLECT</h3>
                                    <p>The information collected is not limited to text characters and may include audio, video and graphic information formats you send us. We use your e-mail address to answer your questions, address your concerns, or forward to someone else for a response. Survey information, if any, is used for much the same purposes.</p>
                                    <h3>Types Of Information Collected:</h3>
                                    <p>We automatically collect the following type of information each time you visit: (a) The Internet Protocol (IP) Address and domain name used. The IP Address is a numerical identifier assigned either to your Internet service provider or directly to your computer. The IP Address helps us direct Internet traffic to you. (b) The type of browser and operating system you used. (c) The date and time you visited. (d) The Internet pages you visited, along with file names and sizes. (e) The key words entered into our search engine. (f) System settings at the time you reported a bug.</p>
                                    <p>If you send an e-mail, subscribe to e-mail newsletters, participate in an online survey, or provide other information using the Website, the following may also be collected: (a) Your e-mail address and contents of your e-mail message (including attachments and signature blocks). (b) Contact information (address, telephone). (c) Organization that you represent. (d) Information volunteered in response to a survey. (e) Information volunteered through an on-line form for any other purpose.</p>
                                    <p>Such information, including the Click Stream Data and cookies as mentioned below, may be collected from you whenever you access or use the Website, whether or not you provide us with any other personal information.</p>
                                    <h3>Click Stream Data:</h3>
                                    <p>When you use the Website we may collect "click stream data" (server address, domain name, etc.). This information can be combined with information you have provided to us, which will enable us to analyze and better customize your visits. We may use click stream data for traffic analysis or e-commerce analysis of our services, to determine which features within the Website are most or least effective or useful to you.</p>
                                    <h3>Cookies:</h3>
                                    <p>A cookie is a small text file of information that the Website you visit transfers to your computer for identification purposes. Cookies can be used to follow your activity throughout the Website and that information helps us to understand your preferences and improve your experience. Website cookies do not collect personal information from you, and we do not combine the general information collected through cookies with other personal information you may provide to personally identify you. You can turn off all cookies, if you prefer not to receive them. You can also have your computer warn you whenever cookies are being used. Please refer to documentation provider with your web browser (e.g., Firefox, Safari, Chrome, or Internet Explorer) for information on enabling these options. There are also software products available that can manage cookies for you. Please be aware, however, that when you choose to reject cookies, this choice may limit the functionality of the Website and you may lose access to some of its features.</p>
                                    <h3>USE AND SHARING OF INFORMATION<br>
                                        To Provide Products and Services to You:</h3>
                                        <p>We will use the personal information you provide and any other information we receive from you to provide products and services to you, which include:</p>
                                    <ul class="list clearfix">
                                        <li>To assist those who help us in designing and operating the Website or help us analyze collected data. These parties will only use your data for the purpose of carrying out the work as agreed with us and will be required to keep the information confidential.</li>
                                        <li>To send you information or materials via email about products, offers, and news we think might interest you. This information may relate to products, offers, and news of Nihon Edutech or its licensees or selected commercial partners. (You will have the option of modifying your personal data or having your personal data be deleted from our database if you no longer wish to receive any emails from us.)</li>
                                        <li>To analyze visits to the Website and learn about the interests of our visitors in the aggregate and also on a personal level to better understand your interests and needs, so we can improve our products and services and deliver to you the type of content, features, and promotions that you are most interested in.</li>
                                        <li>To publish and/or distribute any feedback, suggestions, or any communications regarding the Website. For example, we may share your comments with our team members or publish them in customer-information materials.</li>
                                    </ul>
                                    <h3>Business Transfers:</h3>
                                    <p>In some cases, we may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that is transferred. Moreover, if Nihon Edutech or substantially all of its assets were acquired, customer information would likely be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Nihon Edutech may continue to use your personal information as set forth in this policy.</p>
                                    <h3>Protection of Nihon Edutech Information:</h3>
                                    <p>We may release personal information when we believe in good faith that release is necessary to comply with any law, enforce or apply other agreements, or protect the rights, property, or safety of our employees, our customers, or others. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful, as determined in our sole discretion.</p>
                                    <h3>With Your Consent:</h3>
                                    <p>Except as set forth herein, we will notify you or obtain your consent before your personal information may be shared with third parties, and will be able to prevent the sharing of this information. You may, at any time, elect to stop receiving emails from us.</p>
                                    <h3>Retention of Your Information:</h3>
                                    <p>We will only retain your information to the extent: (a) it is needed for the purposes which we have conveyed to you; (b) we are required by law to maintain such information; or (c) it is needed for us to address any issues or inquiries that you may have. When your information is no longer required, we will dispose of such information by destroying it or erasing it.</p>
                                    <h3>PROTECTION OF YOUR INFORMATION<br>
                                    Security Measures:</h3>
                                    <p>In order to protect both the personal information and the general information that we receive from you through your use of the Website, we follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.</p>
                                    <h3>Risk of Interception:</h3>
                                    <p>Please keep in mind, however, that whenever you give out personal information online there is a risk that third parties may intercept and use that information. While Nihon Edutech strives to protect your personal information and privacy, we cannot guarantee the security of any information you disclose online. By using the Website, you expressly acknowledge and agree that we do not guarantee the security of any data provided to or received by us through your use of the Website and that any personal information, general information, or other data or information received from you through your use of the Website is provided to us at your own risk, which you expressly assume.</p>
                                    <h3>USE OF THE WEBSITE BY MINORS PERMITTED</h3>
                                    <p>Any general information provided by minor and gathered (for example, through the use of cookies) during his or her visit may be used as indicated in this privacy policy. Nihon Edutech is committed to protecting the privacy of children and has no intention of collecting personal data from minors. We encourage parents, guardians, and teachers of minors to regularly check and monitor their children's use of email and other activities online.</p>
                                    <h3>LINKS TO THIRD PARTIES</h3>
                                    <p>For your convenience and to improve the usage of the Website we may insert links to third party websites. This privacy policy does not apply to such third party websites. These links take you outside our Website and are beyond our control. This includes links from partners that may use Nihon Edutech's logo as part of a co-branding agreement. The sites those links can take you to have their own separate privacy policy and although we seek to protect the integrity of our Website, Nihon Edutech is not liable for the content and activities of those sites. Your visits and access to such websites is at your own risk. Please note that those other sites may send their own cookies to users, collect data, or solicit personal information.</p>
                                    <h3>CANCELING YOUR ACCOUNT, OPTING OUT OF EMAIL, AND MODIFYING PERSONAL INFORMATION</h3>
                                    <p>You may cancel your Account and you may opt out of receiving any emails from Nihon Edutech at any time. You may cancel your account, opt out of, or modify your personal information by visiting the Website and following the instructions provided or by emailing us. We encourage you promptly to update your personal information when it changes. Information concerning your past behavior with the Website may be retained indefinitely by Nihon Edutech.</p>
                                    <p>Please note that although you may opt out of receiving certain emails, Nihon Edutech reserves the right to communicate with you via email regarding specific products and services you have inquired about.</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  <app-footer></app-footer>  
</div>