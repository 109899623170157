<div class="boxed_wrapper">
    <app-header></app-header>        
     <div class="mobile-menu">
         <div class="menu-backdrop"></div>
         <div class="close-btn"><i class="fas fa-times"></i></div>
             <nav class="menu-box">
                 <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                 <div class="menu-outer"></div>
             </nav>
         </div>
         <section class="page-title" style="background-image: url({{bannerDetails.image}}); margin-top: 69px;">
             <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
             <div class="auto-container">
                 <div class="content-box">
                     <div class="title-box centred">
                         <h1>{{bannerDetails.title}}</h1>
                         <p>{{bannerDetails.description}}</p>
                     </div>
                     <ul class="bread-crumb clearfix">
                         <li><a routerLink="/">{{ 'it_engineering.Home' | translate }}</a></li>
                         <li>{{ 'resourcing_semi_skilled.Semi Skilled' | translate }}</li>
                     </ul>
                 </div>
             </div>
         </section><br>
         <section class="mt-2" id="japanese_training">
             <div class="auto-container">
                 <div class="row align-items-center clearfix">
                     <div class="col-xl-12 col-lg-12 col-sm-12">
                         <div class="sec-title centred">
                          
                             <h2>{{ 'resourcing_semi_skilled.LOOKING FOR A TALENT POOL?' | translate }}
                            </h2>
                            <span class="top-title">{{ 'resourcing_semi_skilled.PUT AN END TO YOUR HASSLES HERE!' | translate }}
                            </span>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                         <div class="text">
                             <img src="{{sswData.image}}" />
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                         <div id="content_block_1">
                             <div class="content-box">
                                 <div class="inner-box">
                                    <p >{{ 'resourcing_semi_skilled.nihon' | translate }}</p>
                                        <strong>{{ 'resourcing_semi_skilled.nihon2' | translate }}</strong>
                                            <div class="row">
                                                <div class="col-md-6"> <img src="assets/images/nsdc.png"></div>
                                                <div class="col-md-6"> <img src= "assets/images/125-years-logo-3-Black-2 1.png"></div>
                                            </div>
                                     <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                     <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-2">
                        <div id="content_block_1">
                            <div class="content-box">
                                <div class="inner-box">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon3' | translate }}
                                    </h5>
                                
                                        
                                            <ul>
                                                <li>{{ 'resourcing_semi_skilled.nihon4' | translate }}</li>
                                                <li>{{ 'resourcing_semi_skilled.nihon5' | translate }}</li>

                                                <li>{{ 'resourcing_semi_skilled.nihon6' | translate }}</li>

                                                <li>{{ 'resourcing_semi_skilled.nihon7' | translate }}</li>

                                                <li>{{ 'resourcing_semi_skilled.nihon8' | translate }}</li>

                                                <li>{{ 'resourcing_semi_skilled.nihon9' | translate }}</li>


                                        
                                              </ul>
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>
                                <div class="inner-box">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon10' | translate }}
                                    </h5>
                                
                                        
                                            <ul>
                                                <li>{{ 'resourcing_semi_skilled.nihon11' | translate }}</li>
                                                <li>{{ 'resourcing_semi_skilled.nihon12' | translate }}
                                                </li>     
                                                <li>{{ 'resourcing_semi_skilled.nihon14' | translate }}</li>                                        
                                                <li>{{ 'resourcing_semi_skilled.nihon15' | translate }}</li>
                                                <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                                              </ul>
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                                <div class="inner-box">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon16' | translate }}

                                    </h5><br>

                                    <!-- <div class="row" >
                                        <owl-carousel-o [options]="customOptionsClient">
                                            <ng-container *ngFor="let item of partner">
                                                <ng-template carouselSlide>
                                                    <div class="col-md-3"> <img src="{{item.logo}}" style="height: 200px;" ></div>
                                                </ng-template>
                                            </ng-container>
                                        </owl-carousel-o>
                                      
                                      
                                    </div><br> -->
                                    <section class=" alternet-2" style="background: #ffffff;
                                    /* border-right: 1px solid #d9cec7; */
                                    margin-bottom: 28px;
                                    margin-top: 8px;">
                                        <div class="outer-container row">
                                            <!-- <div class="col-xl-12 col-lg-12 col-sm-12">
                                                <h2 class="clients_ttl">{{ 'Home.Our Partners' | translate }}</h2>
                                            </div> -->
                                         
                                                <ng-container *ngFor="let item of client">
                                                   
                                                        <figure class="col-md-4" style="background: #ffffff;
                                                        /* border-right: 1px solid #d9cec7; */
                                                        margin-bottom: 28px;
                                                        margin-top: 8px;" >
                                                          <img src="{{item.logo}}" alt=""
                                                                    class="mt-2" style="height: 100px;">
                                                            <!-- <span class="logo-title"><a href="{{item.website_url}}" target="_blank">{{ 'Home.Visit
                                                                    Website' | translate
                                                                    }}</a></span> -->
                                                        </figure>
                                          
                                                </ng-container>
                                     
                                            <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                                                <figure class="clients-logo-box">
                                                    <a href="https://www.securekloud.com/" target="_blank"><img src="assets/images/clients/partnr_img1.png" alt=""></a>
                                                </figure>
                                                <figure class="clients-logo-box">
                                                    <a href="https://www.steepgraph.com/" target="_blank"><img src="assets/images/clients/partnr_img2.png" alt=""></a>
                                                </figure>
                                                <figure class="clients-logo-box">
                                                    <a href="http://www.rajasri.com/" target="_blank"><img src="assets/images/clients/partnr_img3.png" alt=""></a>
                                                </figure>
                                                <figure class="clients-logo-box">
                                                    <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/partnr_img4.png" alt=""></a>
                                                </figure>
                                                <figure class="clients-logo-box">
                                                    <a href="https://www.avvanz.com/"><img src="assets/images/clients/partnr_img5.png" alt=""></a>
                                                </figure>
                                            </div> -->
                                        </div>
                                    </section>

                                    <p class="text-center">{{ 'resourcing_semi_skilled.nihon17' | translate }}</p>
                                
                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>



                                <div class="inner-box">
                                    <h5 style="font-weight: 700;font-size: 20px;"> {{ 'resourcing_semi_skilled.nihon18' | translate }}

                                    </h5>

                                    <section class="">
                                        <div class="">
                                            <div class='main-table-containter' >
                                                <div class='title-table-container'>
                                                    <!-- <div class='subtitle'>Transacciones</div> -->
                                
                                                </div>
                                                <div>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th style="color: #0353a7;    font-size: 20px;" colspan="6">{{ 'resourcing_semi_skilled.nihon19' | translate }}</th>
                                                                
                                                                 <th style="color: #0353a7;    font-size: 20px;">{{ 'resourcing_semi_skilled.nihon20' | translate }}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>{{ 'resourcing_semi_skilled.nihon21' | translate }}</th>
                                                                <th>{{ 'resourcing_semi_skilled.nihon22' | translate }}</th>
                                                                <th>N5</th>
                                                                <th>N4</th>
                                                                <th>N3</th>
                                                                <th>{{ 'resourcing_semi_skilled.nihon23' | translate }}</th>
                                                                <th>{{ 'resourcing_semi_skilled.nihon24' | translate }}</th>
                                                              
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr style="font-size: 15px;" *ngFor="let item of waiting">
                                                                <td><span class="completado">{{item.content}}</span></td>
                                                                <td>{{ item.content5 + item.content6 + item.content7 +  item.content2 }}</td>
                                                                <td>{{item.content7}}</td>
                                                                <td>{{item.content6}}</td>
                                                                <td>{{item.content5}}</td>
                                                                <td>{{item.content3}}</td>
                                                                <td>{{item.content2}}</td>
                                                            
                                                                <td>{{item.content4}}</td>
                                                            </tr>
                                                            <!-- Add more rows as needed -->
                                                        </tbody>
                                                    </table>
                                                    <!-- <table class="" >
                                                     
                                                        <tbody>
                                                            <tr style="font-size: 22px; color: black;font-weight: bolder;">
                                                                <td>Occupation/Education </td>

                                            
                                                                
                                                                    <td>N5</td>
                                                                    <td>N4</td>
                                                                    <td>N3</td>
                                                            
                                                                 
                                                                    <td>SSW ready</td>
                                                                    <td>candidates </td>
                                                                
                                                                    <td>Placed Candidates</td>
                                                 
                                                            </tr>
                                
                                                            <tr style="font-size: 15px;" *ngFor="let item of waiting">
                                                                <td><span class="completado">{{item.content}}</span><br>
                                                                  
                                                                </td>
                                                                <td>{{item.content7}}
                                                                </td>
                                                                <td>{{item.content6}}</td>
                                                                <td>{{item.content5}}
                                                                </td>
                                                                <td>{{item.content3}}
                                                                </td>
                                                                <td>{{ item.content5 + item.content6 + item.content7 }}</td>
                                                                <td>{{item.content4}}
                                                                </td>
                                                             
                                                               
                                                          
                                                              
                                                 
                                                            </tr>
                                
                                
                                                        
                                                        </tbody>
                                                    </table> -->
                                                </div>
                                               
                                
                                            </div><br>
                                            <div  >
                                                <p style="text-align: end;" *ngIf="lng == 'en'">Total no of candidates placed:<strong > {{ getTotalPlacement()}}</strong></p>
                                            </div>
                                            <div  >
                                                <p style="text-align: end;" *ngIf="lng == 'ja'">送り出し総数:<strong > {{ getTotalPlacement()}}人</strong></p>
                                            </div>
                                        </div>
                                        <div  >
                                            <p style="text-align: end;" *ngIf="lng == 'en'">Data as of<strong *ngFor="let item of waiting"> {{item.content9}}</strong></p>
                                        </div>
                                        <div  >
                                            <p style="text-align: end;"  *ngIf="lng == 'ja'">最終更新日<strong *ngFor="let item of waiting"> {{item.content9}}</strong></p>
                                        </div>
                                        <p class="text-center "  *ngIf="lng == 'ja'">私たちのデータベースには、N1やN2を所有するITやエンジニアの候補者もいます。</p>
   
                                        <p  class="text-center " *ngIf="lng == 'en'"><strong>Note:</strong> For IT and Engineering requirments  our database has candidates  with the N1/N2 certifications</p>
                                       
                                 

                                        <p class="text-center mt-4" *ngIf="lng == 'ja'">ぴったりの候補者が見つかりましたか？ <a href="/enquire">こちら</a>をクリックして、ぜひお問い合わせください</p>
                                </section><br>

                                <div class="">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon27' | translate }}


                                    </h5>

                                    <section class="" id="japanese_training">
            
   

                                        
                                         <div class=" row" >
                                            <div class="card3 col-lg-5 col-md-8 " *ngFor="let item of titp"  style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="{{item.image}}" style="width: 100px; max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1; padding: 0px 0px 0px 6px; display: flex; flex-direction: column; justify-content: center;">
                                                    <p class="card-title" style="font-size: 17px; color: #0353a7; font-weight: 800; cursor: default;">{{item.title}}</p>
                                                    <p class="card-des">
                                                        <span *ngIf="item.placement"><strong>{{ 'resourcing_semi_skilled.nihon65' | translate }} </strong> {{item.placement}} </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- <div class="card3 col-lg-5 col-md-8 "  style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="assets/images/125-years-logo-3-Black-2.png" style="  width: 100px;      max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1;padding: 0px 0px 0px 6px;">
                                                    <p class="card-title"><strong>Machine Inspection/Maintenance</strong> </p>
                                                    <p class="card-des">
                                                     
                                                      <span>{{ 'resourcing_semi_skilled.nihon65' | translate }} :</span>
                                                
                                                </div>
                                            </div> -->
                                        
                                         </div>
                                         
                                     </section><br>

                                

                                
                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                                <div class="">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon28' | translate }}


                                    </h5>

                                    <section class="" id="japanese_training">
            
   

                                        
                                         <div class=" row" >
                                            <!-- <div class="card3 col-lg-5 col-md-8 " *ngFor="let item of ssw"  style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="{{item.image}}" style="  width: 100px;      max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1;padding: 0px 0px 0px 6px;">
                                                    <p class="card-title" style="font-size: 17px;
                                                    color: #0353a7;
                                                    font-weight: 800;
                                                    cursor: default;" > {{item.title}} </p>
                                                    <p class="card-des">
                                                     
                                                        <span *ngIf="item.placement" > <strong>{{ 'resourcing_semi_skilled.nihon65' | translate }} :</strong>   {{item.placement}}</span>
                                              
                                                </div>
                                            </div> -->
                                            <div class="card3 col-lg-5 col-md-8" *ngFor="let item of ssw" style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="{{item.image}}" style="width: 100px; max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1; padding: 0px 0px 0px 6px; display: flex; flex-direction: column; justify-content: center;">
                                                    <p class="card-title" style="font-size: 17px; color: #0353a7; font-weight: 800; cursor: default;">{{item.title}}</p>
                                                    <p class="card-des">
                                                        <span *ngIf="item.placement"><strong>{{ 'resourcing_semi_skilled.nihon65' | translate }} </strong> {{item.placement}} </span>
                                                        
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            
                                        
                                         </div>
                                         
                                     </section><br>

                                

                                
                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                                
                                <div class="">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon29' | translate }}


                                    </h5>

                                    <section class="" id="japanese_training">
            
   

                                        
                                         <div class=" row" >
                                            <div class="card3 col-lg-5 col-md-8 " *ngFor="let item of it"  style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="{{item.image}}" style="width: 100px; max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1; padding: 0px 0px 0px 6px; display: flex; flex-direction: column; justify-content: center;">
                                                    <p class="card-title" style="font-size: 17px; color: #0353a7; font-weight: 800; cursor: default;">{{item.title}}</p>
                                                    <p class="card-des">
                                                        <span *ngIf="item.placement"><strong>{{ 'resourcing_semi_skilled.nihon65' | translate }} </strong> {{item.placement}} </span>
                                                    </p>
                                                </div>
                                            </div>
                                           
                                        
                                         </div>
                                         
                                     </section><br>

                                

                                
                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                                <div class="">
                                    <!-- <h5 style="font-weight: 700;"><strong>IT PROFESSIONS:</strong>  Sectors in which we offer placements


                                    </h5> -->

                                    <section class="" id="japanese_training">
            
   

                                        
                                         <!-- <div class=" row" >
                                            <div class="card3 col-lg-5 col-md-8 " *ngFor="let item of it"  style="display: flex; flex-direction: row; margin-top: 12px;">
                                                <div class="" style="display: flex;">
                                                    <img src="{{item.image}}" style="width: 100px; max-width: none;">
                                                </div>
                                                <div style="flex-grow: 1; padding: 0px 0px 0px 6px; display: flex; flex-direction: column; justify-content: end;">
                                                    <p class="card-title" style="font-size: 17px; color: #0353a7; font-weight: 800; cursor: default;">{{item.title}}</p>
                                                    <p class="card-des">
                                                        <span *ngIf="item.placement"><strong>Candidates Placed:</strong> {{item.placement}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                           
                                          
                                        
                                         </div> -->
                                         
                                     </section>
                                     <div class="col-lg-12 col-md-12 col-sm-12 inner-column mt-4">
                                        <div id="content_block_1">
                                            <div class="content-box">
                                                <div class="inner-box">
                                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon0' | translate }}


                                                    </h5>
                                                    <div class="row" *ngIf="lng == 'en'">
                                                        <div class="col-md-12"> <img src="assets/images/Mask group (1).png"></div>
                                                        <!-- <div class="col-md-6"> <img src= "assets/images/125-years-logo-3-Black-2 1.png"></div> -->
                                                    </div>
                                                      
                                                           <div class="row" *ngIf="lng == 'ja'">
                                                               <div class="col-md-12"> <img src="../../../assets/images/Screenshot 2024-03-13 111506.png"></div>
                                                               <!-- <div class="col-md-6"> <img src= "assets/images/125-years-logo-3-Black-2 1.png"></div> -->
                                                           </div>
                                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                        

                                

                                
                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                                <div class="inner-box">
                                    <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon30' | translate }}
                                    </h5>
                                
                                        
                                            <ul>
                                                <li><strong>{{ 'resourcing_semi_skilled.nihon31' | translate }}</strong>  {{ 'resourcing_semi_skilled.nihon32' | translate }}</li>
                                                <li><strong>{{ 'resourcing_semi_skilled.nihon33' | translate }} </strong> {{ 'resourcing_semi_skilled.nihon34' | translate }}
                                                </li>
                                                <li><strong>{{ 'resourcing_semi_skilled.nihon35' | translate }}</strong>{{ 'resourcing_semi_skilled.nihon36' | translate }}</li>
                                                <li><strong>{{ 'resourcing_semi_skilled.nihon37' | translate }}</strong> {{ 'resourcing_semi_skilled.nihon38' | translate }}</li>
                                                <!-- <li>Highly skilled professional</li> -->
                                                <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                                              </ul>
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div><br>

                            

                                        
                                        
                                    
                                          
                                    <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
                                    <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
                                </div>
                                
                            </div>
                        </div>
                    </div>
                   
                 </div>
             </div>
         </section>
         <div class="inner-box">
            <div class="auto-container"> <h5 class="resourse" >{{ 'resourcing_semi_skilled.nihon39' | translate }}

            </h5></div><br>
           
            <div class="row large-container">
                <div class="card3 col-lg-3 col-md-4 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/image-1633965846241.jpg" style="width: 350px;height: 200px;">
                    </div>
                    <p class="card-title">{{ 'resourcing_semi_skilled.nihon40' | translate }}
                    </p>
                    <p class="card-des">
                        {{ 'resourcing_semi_skilled.nihon41' | translate }}
                        
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="card3 col-lg-3 col-md-4 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/miu.avif" style="width: 350px; height: 200px;">
                    </div>
                    <p class="card-title"> {{ 'resourcing_semi_skilled.nihon42' | translate }}
                    </p>
                    <p class="card-des">
                        {{ 'resourcing_semi_skilled.nihon43' | translate }}
                        
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="card3 col-lg-3 col-md-4 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/pmo.jpeg" style="width: 350px;height: 200px;">
                    </div>
                    <p class="card-title"> {{ 'resourcing_semi_skilled.nihon44' | translate }}
                    </p>
                    <p class="card-des">
                        {{ 'resourcing_semi_skilled.nihon45' | translate }}
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <p class="auto-container mt-4 cl" > {{ 'resourcing_semi_skilled.nihon46' | translate }}</p>
                  <p class="auto-container cl" > {{ 'resourcing_semi_skilled.nihon47' | translate }}</p><br>
                  <h5 class="auto-container " style="text-align: justify; color: #eb011c; margin-top: 50px;   font-weight: 700;"> {{ 'resourcing_semi_skilled.nihon48' | translate }}</h5>

            </div>
          
                
        
                
                
            
                  
            <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
            <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
        </div><br>
<div class="auto-container">
    <div class="inner-box">
        <h5 style="font-weight: 700;">{{ 'resourcing_semi_skilled.nihon49' | translate }}

        </h5>
    

        
                <ul  class="mt-2" >
                    <li style="    margin-left: 19px;"> <strong> {{ 'resourcing_semi_skilled.nihon50' | translate }}</strong>      {{ 'resourcing_semi_skilled.nihon55' | translate }}</li>
                    <li style="    margin-left: 19px;"><strong>{{ 'resourcing_semi_skilled.nihon51' | translate }}</strong>      {{ 'resourcing_semi_skilled.nihon56' | translate }}

                    </li>
                    <li style="    margin-left: 19px;">  <strong> {{ 'resourcing_semi_skilled.nihon52' | translate }}</strong>     {{ 'resourcing_semi_skilled.nihon57' | translate }}
                    </li>
                    <li style="    margin-left: 19px;">  <strong> {{ 'resourcing_semi_skilled.nihon53' | translate }}</strong>     
                        {{ 'resourcing_semi_skilled.nihon58' | translate }}
                    </li>
                    <li style="    margin-left: 19px;">  <strong>{{ 'resourcing_semi_skilled.nihon54' | translate }}</strong>   
                        {{ 'resourcing_semi_skilled.nihon59' | translate }}



                
                    <!-- <li>We facilitate a smooth onboarding, handling everything from visas to housing.</li> -->
                  </ul><br>
                  <p>{{ 'resourcing_semi_skilled.nihon60' | translate }}</p>

                    <p class="mt-4">{{ 'resourcing_semi_skilled.nihon61' | translate }} <a href="mailto:info@nihonedutech.com">  info@nihonedutech.com</a>

                        {{ 'resourcing_semi_skilled.nihon62' | translate }}<a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank"> {{ 'resourcing_semi_skilled.nihon63' | translate }}</a> {{ 'resourcing_semi_skilled.nihon64' | translate }}</p>
            
        
              
        <!-- <p [innerHTML]="transformHtml(sswData.description)"></p> -->
        <!-- <p [innerHTML]="transformHtml(sswData.description2)">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                  -->
    </div><br>
</div>
   
<!--              
         <section class="faq-section">
             <div class="auto-container">
                 <div class="row clearfix">
                     <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                         <div class="inner-box">
                             <ul class="accordion-box">
                                 <li *ngFor="let item of paragraphs; let i = index" class="accordion block">
                                     <div class="acc-btn">
                                         <div id="pera_expand{{i}}" class="icon-outer"></div>
                                         <h5>{{item.title}}</h5>
                                     </div>
                                     <div class="acc-content">
                                         <div class="text">
                                             <div class="row">
                                                 <div class="col-md-4 col-sm-4 bdr_right">
                                                     <img src="{{item.image}}" />
                                                 </div>
                                                 <div class="col-md-8 col-sm-8 panel_height" [innerHTML]="transformHtml(item.description)">
                                                     
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                             </ul>
                         </div>
                     </div>
                 </div>
             </div>
         </section> -->
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'it_engineering.Gallery' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of images">
                     <ng-template carouselSlide>
                         <div  class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                     </div>
                                     <div class="lower-cont">
                                         <h3>{{item.title}}</h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </ng-template>
                   </ng-container>
             </owl-carousel-o>
         </div>
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'it_engineering.Videos' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of videos">
                     <ng-template carouselSlide>
                         <div class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                         <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                             <i class="flaticon-play"></i>
                                         </a>
                                         <div class="border"></div>
                                     </div>
                                 </div>
                                 <div class="lower-cont">
                                     <h3>{{item.title}}</h3>
                                 </div>
                             </div>
                         </div>
                     </ng-template>  
                 </ng-container>
             </owl-carousel-o>
         </div>
                 
         <!-- <section class="testimonial-section bg-color-2">
             <div class="auto-container">
                 <div class="sec-title">
                     <span class="top-title">{{ 'it_engineering.Student Testimonials' | translate }}</span>
                 </div>
                 <owl-carousel-o [options]="customOptionsStudent">
                     <ng-container *ngFor="let item of studentTestimonial">
                         <ng-template carouselSlide>
                             <div class="testimonial-block-one">
                                 <div class="inner-box">
                                     <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                                     <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                     <div class="author-box">
                                         <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                         <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                     </div>
                                     <div class="text">
                                         <p>{{item.description1}}</p>
                                         <p>{{item.description2}}</p>
                                     </div>
                                 </div>
                             </div> 
                         </ng-template>  
                     </ng-container>
                 </owl-carousel-o>
             </div>
         </section> -->
        <app-footer></app-footer>
 </div>
