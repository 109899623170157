<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>

    <app-header-two></app-header-two>

    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
            <div class="close-btn"><i class="fas fa-times"></i></div>
            <nav class="menu-box">
                <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                <div class="menu-outer"></div>
            </nav>
    </div>
    <!-- <section class="banner-section style-two">
        <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
            <div class="slide-item">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-5.jpg)"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <span>The personnel touch</span>
                        <h1>Shining a Light on Your <br>HR &amp; Employment Law Issues</h1>
                        <div class="btn-box">
                            <a href="hire_now.html" class="btn-one">Hire Now</a>
                            <a href="get_job.html" class="btn-two">Get job Now</a>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="slide-item">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-4.jpg)"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <span>Engaged with Staffing</span>
                        <h1>Partnering You for <br>Your Human Resource Require</h1>
                        <div class="btn-box">
                            <a href="hire_now.html" class="btn-one">Hire Now</a>
                            <a href="get_job.html" class="btn-two">Get job Now</a>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="slide-item">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-6.jpg)"></div>
                <div class="auto-container">
                    <div class="content-box">
                        <span>Choose the best from rest</span>
                        <h1>NihonCareer Provide <br>Employment &amp; Opportunities </h1>
                        <div class="btn-box">
                            <a href="hire_now.html" class="btn-one">Hire Now</a>
                            <a href="get_job.html" class="btn-two">Get job Now</a>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </section> -->
  
    <section class="portfolio-wide-1 placejob-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">Nihoncareers</span>
                <h2>Register</h2>
                <p>Nihoncareers.com is as integrated AI enabled learning cum job portal targetted to train<br> and send top technical talent to Japanese companies in Japan</p>
            </div>
        </div>
        <div class="items-container row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <figure><img src="assets/images/login_left_courses.jpg" alt="" class="brds"></figure>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo5.png" alt=""></figure>
                            <div class="inner">
                                <h3>Python Developer</h3>
                                <p><i class="flaticon-place"></i>Kanagawa, Japan</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/get_job">Apply</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo3.png" alt=""></figure>
                            <div class="inner">
                                <h3>Administrative Coordinator</h3>
                                <p><i class="flaticon-place"></i>Kanagawa, Japan</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/get_job">Apply</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/icon_logo4.png" alt=""></figure>
                            <div class="inner">
                                <h3>Purchase Head and Director</h3>
                                <p><i class="flaticon-place"></i>Kanagawa, Japan</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/get_job">Apply</a>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 style="text-align:center; margin-top: 20px;"><a class="login_ttl button_lg" routerLink="/get_job">More Jobs</a></h2>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 form-column">
                <div class="form-inner">
                    <div class="title-inner">
                        <figure class="icon-box"><img src="assets/images/icons/icon-59.png" alt=""></figure>
                        <h2>Let's get started now!</h2>
                        
                    </div>
                    <form action="place-job.html" method="post" class="job-form">
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input type="text" name="fname" placeholder="Email or Username" required="">
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input type="text" name="lname" placeholder="Password" required="">
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input type="text" name="Phone" placeholder="Phone" required="">
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input type="text" name="email" placeholder="Email" required="">
                            </div>
                            
                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <div class="ttl_bdr">
                        <div class="above_text">Role</div>
                        <div class="inside_text">  
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="defaultGroupExample2" name="groupOfDefaultRadios" checked>
                                    <label class="custom-control-label" for="defaultGroupExample2">Candidate</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="defaultGroupExample1" name="groupOfDefaultRadios">
                                    <label class="custom-control-label" for="defaultGroupExample1">Recruiter</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="defaultGroupExample3" name="groupOfDefaultRadios">
                                    <label class="custom-control-label" for="defaultGroupExample3">Instructor</label>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <div class="form-group">
                                
                                </div>
                            </div>
                            </div>
                                    </div></div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <div class="form-group">
                                    <button type="submit" class="theme-btn-one">Register Now</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <figure><img src="assets/images/login_right_jobs.jpg" alt="" class="brds"></figure>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/candidate_img1.png" alt=""></figure>
                            <div class="inner">
                                <h3>Carmel Alexander</h3>
                                <p><i class="far fa-address-book"></i>TITP Intern - 2018 batch</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/candidate_details">Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/candidate_img1.png" alt=""></figure>
                            <div class="inner">
                                <h3>Sheik Mohaideen Badhusha</h3>
                                <p><i class="far fa-address-book"></i>TITP Intern - 2018 batch</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/candidate_details">Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-details-content">
                    <div class="upper-box">
                        <div class="inner-box">
                            <figure class="company-logo"><img src="assets/images/icons/candidate_img1.png" alt=""></figure>
                            <div class="inner">
                                <h3>Jeyaraman</h3>
                                <p><i class="far fa-address-book"></i>TITP Intern - 2018 batch</p>
                            </div>
                            <div class="info clearfix">
                                <a class="login_ttl2 button_lg2" routerLink="/candidate_details">Details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 style="text-align:center; margin-top: 20px;"><a class="login_ttl button_lg" routerLink="/browse_candidates">More Candidates</a></h2>
            </div>
        </div>
    </section>
    
    <section class="portfolio-grid-2 protfolio-page">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">Nihon Edutech</span>
                <h2>Top Courses</h2>
                <p>Choose the right course for Learning</p>
            </div>
        
        </div>
        <div class="auto-container">		
            <div class="sortable-masonry">
                <div class="filters">
                    <ul class="filter-tabs filter-btns centred clearfix">
                        <li class="active filter" data-role="button" data-filter=".all">View All</li>
                        <li class="filter" data-role="button" data-filter=".consulting">Japanese Training</li>
                        <li class="filter" data-role="button" data-filter=".market">Technical Training</li>
                        <li class="filter" data-role="button" data-filter=".presentation">Engineering</li>
                        <li class="filter" data-role="button" data-filter=".recruitment">IT</li>
                        <li class="filter" data-role="button" data-filter=".technology">Soft Skills</li>
                    </ul>
                </div>
                <div class="items-container row clearfix"> 
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="video-section">
                        <div class="auto-container">
                            <div id="image_block_1">
                                <div class="image-box">
                                    <div class="video-inner" style="background-image: url(assets/images/project/course_japan_lang.jpg);">
                                        <a routerLink="/japanese-training" class="video-btn" data-caption="">
                                            <i class="flaticon-play"></i>
                                        </a>
                                        <div class="border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="c_ttl">Japanese Training<span> - Sahni</span></h2>
                    </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all consulting presentation technology">
                         <div class="video-section">
                        <div class="auto-container">
                            <div id="image_block_1">
                                <div class="image-box">
                                    <div class="video-inner" style="background-image: url(assets/images/project/course_soft_skills.jpg);">
                                        <a routerLink="/softskills" class="video-btn" data-caption="">
                                            <i class="flaticon-play"></i>
                                        </a>
                                        <div class="border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="c_ttl">Soft Skills<span> - Alisha</span></h2>
                    </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all presentation market recruitment">
                         <div class="video-section">
                        <div class="auto-container">
                            <div id="image_block_1">
                                <div class="image-box">
                                    <div class="video-inner" style="background-image: url(assets/images/project/course_it3.jpg);">
                                        <a routerLink="/itskills" class="video-btn" data-caption="">
                                            <i class="flaticon-play"></i>
                                        </a>
                                        <div class="border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="c_ttl">IT<span> - Chrysalis</span></h2>
                    </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_technical.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">Technical Training</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_engineering.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">Engineering</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all consulting presentation Recruitment technology">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_language2.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">Japanese Training</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_softskills2.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">Soft Skills</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_language3.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">Japanese Training</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 masonry-item small-column all consulting presentation Recruitment technology">
                        <div class="project-block-two">
                            <div class="inner-box">
                                <figure class="image-box"><img src="assets/images/project/course_it2.jpg" alt=""></figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>NihonEdutech</span>
                                        <h3><a href="portfolio-2.html">IT</a></h3>
                                    </div>
                                    <div class="link"><a href="portfolio-2.html"><i class="flaticon-right-arrow-angle"></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer-two></app-footer-two>
</div>