<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header-two></app-header-two>
    <!-- <header class="main-header style-two">
        <div class="header-top">
            <div class="top-inner">
                 <figure class="logo-box"><a href="index.html"><img src="assets/images/logo_careers.png" alt=""></a></figure>
                <div class="location-box">
                </div>
                <div class="right-info">
                    <ul class="list">
                        <li><a href="index.html">About Us</a></li>
                        <li><a href="index.html">Locations</a></li>
                        <li><a href="index.html">Resources</a></li>
                    </ul>
                    <ul class="social-links">
                        <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="index.html"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </header> -->

<div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
   <section class="page-title" style="background-image: url(assets/images/banner/banner-11.jpg);">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>Candidate Details</h1>
                    <p>The goal of education is understanding;<br> the goalof training is performance.</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">Home</a></li>
                    <li>Candidate Detailsr</li>
                </ul>
            </div>
        </div>
    </section>
    <section class="job-details">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-10 col-md-12 col-sm-12 content-side">
                    <div class="job-details-content">
                        <div class="upper-box">
                                <div class="inner-box">
                                    <figure class="company-logo"><img src="assets/images/icons/candidate_img1.png" alt=""></figure>
                                    <div class="inner">
                                        <h3>Carmel Alexander</h3>
                                        <p><i class="far fa-address-book"></i>TITP Intern - 2018 batch</p>
                                    </div>
                                </div>
                            </div>
                        <div class="text">
                            <h2>Carmel Alexander</h2>
                            <p>I have learnt Japanese language and culture in Nihon Edutech by native Japanese</p> 
                            <p><strong>About Me:</strong></p>
                            
                            <p><strong>Personal Characteristics:</strong></p>
                            <ul class="list clearfix">
                                <li>Excellent customer service skills, communication skills, and a happy, smiling attitude are essential.</li>
                                <li>Available to work required shifts including weekends, evenings and holidays.</li>
                                <li>I have great time management skills.</li>
                                <li>I take constructive criticism well and I am comfortable voicing opinions.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
              
            </div>
        </div>
    </section>
    <app-footer></app-footer>
   <!-- <footer class="main-footer style-two bg-color-1">
        <div class="auto-container">
            <div class="footer-top">
                <div class="widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget about-widget">
                                <div class="widget-title">
                                    <h3>About Company</h3>
                                </div>
                                <div class="text">
                                    <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul class="links-list clearfix">
                                    <li><a href="aboutus.html">About Us</a></li>
                                    <li><a href="learning.html">Learning</a></li>
                                    <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>	
                                    <li><a href="resourcing.html">Resourcing</a></li>
                                    <li><a href="technology.html">Technology</a></li>
                                    <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                    <li><a href="contactus.html">Contact Us</a></li>
                                    <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                    <li><a href="learning.html#schedule">Schedule a Session</a></li>
                                </ul>
                                <div class="about-widget">
                                <ul class="social-links clearfix">
                                    <li><h5>Connected:</h5></li>
                                    <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div></div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                            <div class="footer-widget callback-widget">
                                <div class="pattern-layer">
                                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                    <div class="pattern-2"></div>
                                    <div class="pattern-3"></div>
                                    <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                                </div>
                                <div class="widget-content">
                                    <form action="index-2.html" method="post" class="callback-form">
                                        <div class="row clearfix">
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="name" placeholder="Name*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="email" name="email" placeholder="Email*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="phone" placeholder="Phone*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <div class="select-box">
                                        <select class="wide" style="display: none;">
                                           <option data-display="Please Select">Please Select</option>
                                           <option value="1">TITP</option>
                                           <option value="2">SSW/SSP</option>
                                           <option value="3">IT/Engineering</option>
                                        </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                    </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <button type="submit" class="theme-btn-one">Get Call Back</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom clearfix">
                <div class="copyright pull-left">
                    <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right clearfix">
                    <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                </ul>
            </div>
        </div>
    </footer>
    <button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>