<div class="boxed_wrapper">
    <app-header></app-header>        
     <div class="mobile-menu">
         <div class="menu-backdrop"></div>
         <div class="close-btn"><i class="fas fa-times"></i></div>
             <nav class="menu-box">
                 <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                 <div class="menu-outer"></div>
             </nav>
         </div>
         <section class="page-title" style="background-image: url({{bannerDetails.image}});margin-top: 69px;">
             <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
             <div class="auto-container">
                 <div class="content-box">
                     <div class="title-box centred">
                         <h1>{{ 'job_in_japan_semi_skill.job' | translate }}
                        </h1>
                         <p>{{ 'job_in_japan_semi_skill.job1' | translate }}</p>
                     </div>
                     <ul class="bread-crumb clearfix">
                         <!-- <li><a routerLink="/">{{ 'job_in_japan_semi_skill.Home' | translate }}</a></li> -->
                         <!-- <li>{{ 'job_in_japan_semi_skill.Semi Skilled' | translate }}</li>s -->
                     </ul>
                 </div>
             </div>
         </section><br>

         <section>
            <div class="large-container">
                <div class="content-box">
                
                    
                    <div class="title-box centred">
                        <h4 style="color:#e23936; font-weight: 800;">{{ 'job_in_japan_semi_skill.job' | translate }}
                        </h4>
                        <p style="color:#0353a7 ;font-weight: 700; font-size: 18px
                        ;">{{ 'job_in_japan_semi_skill.job1' | translate }}</p>
                    </div>
    
                </div>
            </div>
         </section>
 
         

         <section class="mt-2" id="japanese_training">
            
          

            <p class="para">{{ 'job_in_japan_semi_skill.job2' | translate }}</p>
             <div class="large-container row">
                <div class="card3 col-lg-3 col-md-8 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/download3.jpg" style="width: 350px;">
                    </div>
                    <p class="card-title" style="  text-align: center;" >{{ 'job_in_japan_semi_skill.job3' | translate }}
                    </p>
                    <p class="card-des">
                        {{ 'job_in_japan_semi_skill.job4' | translate }}
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="card3 col-lg-3 col-md-8 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/download22.jpg" style="width: 350px;">
                    </div>
                    <p class="card-title" style="  text-align: center;" >{{ 'job_in_japan_semi_skill.job5' | translate }}
                    </p>
                    <p class="card-des">
                        {{ 'job_in_japan_semi_skill.job6' | translate }}
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="card3 col-lg-3 col-md-8 mt-2">
                    <div class="card-image-container">
                        <img src="assets/images/download 4.jpg" style="width: 350px;">
                    </div>
                    <p class="card-title" style="  text-align: center;" >{{ 'job_in_japan_semi_skill.job7' | translate }}

                    </p>
                    <p class="card-des">
                        {{ 'job_in_japan_semi_skill.job8' | translate }}
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="col-lg-2 col-md-8"></div>
                  <div class="card3 col-lg-3 col-md-8 mt-4">
                    <div class="card-image-container">
                        <img src="assets/images/japan-4k.jpg" style="width: 350px;">
                    </div>
                    <p class="card-title" style="  text-align: center;" >{{ 'job_in_japan_semi_skill.job9' | translate }}

                    </p>
                    <p class="card-des">
                        {{ 'job_in_japan_semi_skill.job10' | translate }}
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
                  <div class="card3 col-lg-3 col-md-8 mt-4">
                    <div class="card-image-container">
                        <img src="assets/images/download2.jpg" style="width: 350px;">
                    </div>
                    <p class="card-title" style="  text-align: center;" >{{ 'job_in_japan_semi_skill.job11' | translate }}
                    <p class="card-des">
                        {{ 'job_in_japan_semi_skill.job12' | translate }}
                        
                    </p>
                    <!-- <div class="card-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                      </svg>
                      <span class="card-btn-text">Watch Video</span>
                    </div> -->
                  </div>
             </div>
         </section>

    
         <section class="growth-style-two" id="japanese_training">
            
   

            <p class="para">{{ 'job_in_japan_semi_skill.job13' | translate }}
            </p>
             <div class="large-container row" >
                <div class="card4 col-lg-5 col-md-8 "  *ngFor="let item of titp" style="display: flex; flex-direction: row; margin-top: 12px;">
                    <div class="" style="display: flex;">
                        <img src="{{item.image}}" style="  width: 100px;      max-width: none;">
                    </div>
                    <div style="flex-grow: 1;padding: 0px 0px 0px 6px;">
                        <p class="card-title">{{item.title}}</p>
                        <p class="card-des">
                            <span style="font-weight: bold;">{{ 'job_in_japan_semi_skill.job16' | translate }}</span> {{item.Salary}}<br>
                   <span  style="font-weight: bold;"  *ngIf="item.placement" > {{ 'job_in_japan_semi_skill.job17' | translate }}  {{item.placement}}</span>
                        <!-- Uncomment the following if you want to keep the card-btn -->
                        <!--
                        <div class="card-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                            </svg>
                            <span class="card-btn-text">Watch Video</span>
                        </div>
                        -->
                    </div>
                </div>
            
             </div>
             
         </section>
         <div class="large-container">
            <div class="content-box">
            
                
                <div class="title-box centred" *ngIf="lng == 'en'">
                    <p>Click <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> and learn more about what kinds of jobs are listed.<br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*Indicative salary is the Gross Salary per month and may differ based on location in Japan and type of industry/profession.
</span></p>
                </div>

                <div class="title-box centred" *ngIf="lng == 'ja'">
                    <p>掲載求人の詳細は <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">こちら</a><br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*想定給与は1ヶ月あたりの総支給額であり、日本国内の勤務地や業種・職種によって異なる場合があります。
</span></p>
                </div>

            </div>
        </div>

         
    
         <section class="" id="japanese_training">
            
   

            <p class="para">{{ 'job_in_japan_semi_skill.job14' | translate }}
            </p>
            <div class="large-container row" >
                <div class="card4 col-lg-5 col-md-8 "  *ngFor="let item of ITint" style="display: flex; flex-direction: row; margin-top: 12px;">
                    <div class="" style="display: flex;">
                        <img src="{{item.image}}" style="  width: 100px;  max-width: none;">
                    </div>
                    <div style="flex-grow: 1;padding: 0px 0px 0px 6px;">
                        <p class="card-title">{{item.title}}</p>
                        <p class="card-des">
                            <span style="font-weight: bold;">{{ 'job_in_japan_semi_skill.job16' | translate }}</span> {{item.Salary}}<br>
                   <span *ngIf="item.placement"  style="font-weight: bold;"> {{ 'job_in_japan_semi_skill.job17' | translate }}  {{item.placement}}</span>
                        <!-- Uncomment the following if you want to keep the card-btn -->
                        <!--
                        <div class="card-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                            </svg>
                            <span class="card-btn-text">Watch Video</span>
                        </div>
                        -->
                    </div>
                </div>
            
             </div>
             
         </section><br>
         <div class="large-container">
            <div class="content-box">
            
                
                <div class="title-box centred" *ngIf="lng == 'en'">
                    <p>Click <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> and learn more about what kinds of jobs are listed.<br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*Indicative salary is the Gross Salary per month and may differ based on location in Japan and type of industry/profession.
</span></p>
                </div>

                <div class="title-box centred" *ngIf="lng == 'ja'">
                    <p>掲載求人の詳細は <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">こちら</a><br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*想定給与は1ヶ月あたりの総支給額であり、日本国内の勤務地や業種・職種によって異なる場合があります。
</span></p>
                </div>

            </div>
        </div>

         
    
         <section class="" id="japanese_training">
            
   

            <p class="para">{{ 'job_in_japan_semi_skill.job15' | translate }}

            </p>
            <div class="large-container row" >
                <div class="card4 col-lg-5 col-md-8 "  *ngFor="let item of Sswtitp" style="display: flex; flex-direction: row; margin-top: 12px;">
                    <div class="" style="display: flex;">
                        <img src="{{item.image}}" style="  width: 100px;  max-width: none;">
                    </div>
                    <div style="flex-grow: 1;padding: 0px 0px 0px 6px;">
                        <p class="card-title">{{item.title}}</p>
                        <p class="card-des">
                            <span style="font-weight: bold;">{{ 'job_in_japan_semi_skill.job16' | translate }}</span> {{item.Salary}}<br>
                   <span  style="font-weight: bold;" *ngIf="item.placement" > {{ 'job_in_japan_semi_skill.job17' | translate }}  {{item.placement}}</span>
                        <!-- Uncomment the following if you want to keep the card-btn -->
                        <!--
                        <div class="card-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512" stroke-width="0" fill="currentColor" stroke="currentColor">
                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                            </svg>
                            <span class="card-btn-text">Watch Video</span>
                        </div>
                        -->
                    </div>
                </div>
            
             </div>
             
         </section><br>
         <div class="large-container">
            <div class="content-box">
            
                <div class="title-box centred" *ngIf="lng == 'en'">
                    <p>Click <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> and learn more about what kinds of jobs are listed.<br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*Indicative salary is the Gross Salary per month and may differ based on location in Japan and type of industry/profession.
</span></p>
                </div>

                <div class="title-box centred" *ngIf="lng == 'ja'">
                    <p>掲載求人の詳細は <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">こちら</a><br>
                 
                    <span style=" font-weight: 600 ; font-size: 12px
                    ;">*想定給与は1ヶ月あたりの総支給額であり、日本国内の勤務地や業種・職種によって異なる場合があります。
</span></p>
                </div>

            </div>
        </div><br>

        <section class="" id= "jobs_in_japan_semi_skilled" *ngIf="lng == 'en'">
            <div class="large-container row none ">
                <section>
                    <h4 style="font-weight: 700;">Technical Intern Training Program (TITP)</h4><br>
                    <strong>What is the technical intern training program(TITP)?</strong> 
                    <p>
                      TITP, Japan's Technical Intern Training Program, offers 3 to 5 years of on-the-job training to foreign interns from developing nations, addressing skill development and labor shortages in Japan. In India, an MOU with Japan designates the NSDC to oversee program implementation.
                    </p>
                    <p>
                      <strong>Required Certification:</strong><span style="color: red;"> None</span> 
                    </p>
                    <p>
                      Securing a TITP visa doesn't mandate specific certifications. Yet, Japanese companies seek JLPT N5 proficiency for interviews and JLPT N4 as you embark on your professional journey in Japan.
                    </p>
                    <p class="text-center"><strong> Explore learning Japanese!</strong> <a routerLink="/japanese-training"> Click here to get started.</a><br><strong style="font-size: 13px;"> Curious about this program?</strong><a style="font-size: 13px;" routerLink="/enquire"> Get in touch with us today!
                    </a></p>
                   

                  </section>
                
                  <section>
                    <h4 style="font-weight: 700;">Specified Skilled Workers (SSW)</h4><br>
                    <strong>Who are the Specified Skilled Workers?</strong> 

                    <p>
                      SSW is Japan's new status for foreign talent, aiming to address sector skill shortages due to an aging population. Skilled individuals in designated sectors have a chance to work in Japan for up to 5 years if they possess technical expertise and learn Japanese.
                    </p>
                    <p>
                        <strong>Required Certification:</strong><span style="color: red;"></span> 
                    </p>
                    <ul>
                      <li style="margin-left: 12px;">JLPT N4 / JFT basics</li>
                      <li  style="margin-left: 12px;">Technical Certification / Completion of TITP</li>
                    </ul>
                    <p>
                      To apply for the SSW visa, you need either the industry's designated technical certification or the completion certification from TITP.
                    </p>
                    <p>
                      <strong>NOTE: After completing 3 years of TITP program, candidates become eligible to covert to SSW scheme/visa in Japan
                    </strong></p>
                    <p class="text-center"><strong>Ready to learn?</strong> <a routerLink="/japanese-training"> Click here</a><strong> to explore our courses.</strong><br><strong style="font-size: 13px;"> Need more information?</strong><a style="font-size: 13px;" routerLink="/enquire"> Reach out to us!

                    </a></p>
                
                  </section>
            </div>
        </section><br>

        <section class="" id= "jobs_in_japan_semi_skilled" *ngIf="lng == 'ja'">
            <div class="auto-container row  ">
                <section>
                    <h4 style="font-weight: 700;">技能実習制度（TITP）
                    </h4><br>
                    <strong>技能実習制度とは？</strong> 
                    <p>
                        日本の技能実習制度であるTITPは、開発途上国からの外国人実習生に3年から5年の実地研修を提供し、日本における技能開発と労働力不足に対処するものです。インドでは、日本との覚書により、NSDCがプログラムの実施を監督することになっています。
                        
                    </p>
                    <p>
                      <strong>必要な資格:</strong><span style="color: red;"> なし</span> 
                    </p>
                    <p>
                        TITPビザの取得には、特定の資格の取得は義務付けられていません。しかし企業は、候補者が面接の際にJLPT N5を、日本で仕事を始める際にJLPT N4を持っていることを望みます。
                        
                    </p>
                    <p class="text-center"><strong> 一緒に日本語を学びましょう! まずは</strong> <a routerLink="/japanese-training"> クリック</a><br><strong style="font-size: 13px;"> このプログラムに興味がありますか？</strong><a style="font-size: 13px;" routerLink="/enquire"> 今すぐご連絡ください！
                    </a></p>
                   

                  </section>
                
                  <section>
                    <h4 style="font-weight: 700;">特定技能（SSW）</h4><br>
                    <strong>特定技能とは？</strong> 

                    <p>
                        特定技能とは、高齢化に伴う各分野の労働者不足に対応することを目的とした、新しいビザのステータスです。指定された分野で働いた経験のある者は、技術的専門知識を持ち、一定レベルの日本語を習得すれば、最長5年間日本で働くチャンスがあります。
                    </p>
                    <p>
                        <strong>必要な資格</strong><span style="color: red;"></span> 
                    </p>
                    <ul>
                      <li style="margin-left: 12px;">JLPT（日本語能力試験） N4 / JFT- Basic (日本語基礎テスト）</li>
                      <li  style="margin-left: 12px;">技能試験合格または技能実習２号の完了
                    </li>
                    </ul>
                    <p>
                        特定技能（SSW）ビザを申請するには、業界指定の技術認定かTITPの修了認定が必要です。
                    </p>
                    <p>
                      <strong>技能実習プログラムを3年修了すると、候補者は特定技能ビザに切り替える資格を獲得することができます。
                    </strong></p>
                    <p class="text-center"><strong>一緒に日本語を学びましょう! まずは</strong> <a routerLink="/japanese-training"> こちらをクリック</a><br><strong style="font-size: 13px;"> このプログラムに興味がありますか？</strong><a style="font-size: 13px;" routerLink="/enquire"> 今すぐご連絡ください！


                    </a></p>
                
                  </section>
            </div>
        </section><br>
            

          

        <div class="large-container " *ngIf="lng == 'en'">
            <div class="content-box">
            
                
                <div class="title-box centred">
                 
                    <h4 style=" font-weight: 800 
                    ;">Distinguishing Features: TITP VS SSW

                </h4>
                </div>

            </div>
        </div><br>
          <section class="" *ngIf="lng == 'en'">
            <div class="auto-container ">
                
     
                <div class='main-table-containter'>
                    <div class='title-table-container'>
        
    
                    </div>
                    <div>
                        <table class="">
                            <tbody>
                                <tr style="font-size: 15px; color: black;font-weight: bolder;">
                                    <td></td>
                                    <td>Duration
                                        </td>
                    
                                    <td>Required Language Certification</td>
                                    <td>Required Certification 
                                        </td>
                                    <td>Mobility
                                        </td>
                                        <td>Accompanied
                                            </td>
                                            <td>Avg. Monthly Wage</td>
                       
                                </tr>
    
                                <tr style="font-size: 15px;" >
                                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                                        
                                     TITP

                                    
                                   
                                    </td>
                                    <td>3 Years</td>
                                    <td>Not Required </td>
                                    <td>Not Required</td>
                                    <td>Unable to Go Back to India</td>
                                    <td>Unable to take anyone </td>
                                    <td>JPY 178,000 </td>
                                </tr>
                                
    
                                <tr style="font-size: 15px;">
                                    <td style="font-size: 15px; color: black;font-weight: bolder;">SSW
    
    
                                    </td>
                                    <td>10 Years
                                    </td>
                               
                                    <td>JLPT N4/ JFT basics

                                    </td>
                                    <td>Skill Test/TITP Completion
                                    </td>
                                    <td>Able to Go Back to India

                                    </td>
                                    <td>Able to take their Family <br>(after completing 5 years)
                                    </td>
                                    <td>JPY 206,000
                                    </td>
                  
                                </tr>
    
                           
    
                    
                            </tbody>
                        </table><br>
                        <div class="title-box centred">
                            <button style="font-weight: 700;" type="button" class="btn btn-primary centred" data-toggle="modal" data-target="#staticBackdrop">
                                Click for more details
                               </button>
                        </div>
                      

                    </div>
    
                </div>
    
    
            </div>
          
        </section>


        
        <div class="large-container" *ngIf="lng == 'ja'">
            <div class="content-box">
            
                
                <div class="title-box centred">
                 
                    <h4 style=" font-weight: 800 
                    ;">技能実習と特定技能の違い

                </h4>
                </div>

            </div>
        </div><br>
          <section class="" *ngIf="lng == 'ja'">
            <div class="auto-container ">
                
     
                <div class='main-table-containter'>
                    <div class='title-table-container'>
        
    
                    </div>
                    <div>
                        <table class="">
                            <tbody>
                                <tr style="font-size: 15px; color: black;font-weight: bolder;">
                                    <td></td>
                                    <td>期間
                                        </td>
                    
                                    <td>応募に必要な言語資格</td>
                                    <td>応募に必要な資格 
                                        </td>
                                    <td>帰国
                                        </td>
                                        <td>帯同者
                                            </td>
                                            <td>平均月収</td>
                       
                                </tr>
    
                                <tr style="font-size: 15px;" >
                                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                                        
                                        技能実習

                                    
                                   
                                    </td>
                                    <td>３年</td>
                                    <td>なし </td>
                                    <td>なし</td>
                                    <td>不可</td>
                                    <td>不可 </td>
                                    <td>¥178,000 </td>
                                </tr>
                                
    
                                <tr style="font-size: 15px;">
                                    <td style="font-size: 15px; color: black;font-weight: bolder;">特定技能
    
    
                                    </td>
                                    <td>5年
                                    </td>
                               
                                    <td>JLPT N4/ JFT-Basic


                                    </td>
                                    <td>技能試験・技能実習の完了
                                    </td>
                                    <td>帰国することが可能

                                    </td>
                                    <td>家族を連れて行くことが可能 
                                    </td>
                                    <td>¥206,000
                                    </td>
                  
                                </tr>
    
                           
    
                    
                            </tbody>
                        </table><br>
                        <div class="title-box centred">
                            <button style="font-weight: 700;" type="button" class="btn btn-primary centred" data-toggle="modal" data-target="#staticBackdrop">
                                詳細はこちらをクリックしてください
                               </button>
                        </div>
                      

                    </div>
    
                </div>
    
    
            </div>
          
        </section>

        <section class="mt-4" id="japanese_training" *ngIf="lng == 'en'">
            
   

            <p class="para"> IT/Engineering Professionals

            </p>
            <p class="para1">Understanding the Job Hunting Process</p>
            <div class="large-container row" >
             
            
  
<div id="timeline">
    <div class="FadeIn">          
  <div class=" d-md-block d-none">
    <div class="page" style="display: flex; justify-content: center">
  
    
        
        <div class="arrow arrow--1">
            Register on Ncareers as
            a Job seeker
        </div>
        <div style="margin-top: 25px;
        color: red;
        font-size: 50px;
    ">
        <i class="fa-solid fa-arrow-right"></i>

        </div>
        <div class="arrow arrow--1">
            Upload your resume 
            <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a>
          </div>
          <div style="margin-top: 25px;
          color: red;
          font-size: 50px;
      ">
          <i class="fa-solid fa-arrow-right"></i>
  
          </div>
          <div class="arrow arrow--1">
            Update your job preferences 
          </div>
          <div style="margin-top: 25px;
          color: red;
          font-size: 50px;
      ">
          <i class="fa-solid fa-arrow-right"></i>
  
          </div>
          <div class="arrow arrow--1">
            Browse and apply today!!
    
          </div>


        
     
      
       
        
        
      </div>
  </div>
  <p class=" d-md-block d-none" style="margin-left: 63px;">To know and understand where you fit in, please email us at<a href="mailto:info@nihonedutech.com">  info@nihonedutech.com</a> You can also upload your CV <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> for a free analysis and advisory session.
</p> 
<p class=" d-md-block d-none" style="margin-left: 63px;">Our platform Ncareers help acheiving seamless execution of recruitment and placement activities.</p>



  <div class="page d-block d-md-none">
  
    
    <!-- Right: Outside -->
    <div class="arrow arrow--3">
        Register on Ncareers as
        a Job seeker
    </div>
    <div class="arrow arrow--3">
        Upload your resume 
        <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a>
      </div>
      <div class="arrow arrow--3">
        Update your job preferences 
      </div>
      <div class="arrow arrow--1">
        Browse and apply today!!

      </div>

     
        <p class="">To know and understand where you fit in, please email us at <a href="mailto:info@nihonedutech.com">info@nihonedutech.com</a> You can also upload your CV  <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> for a free analysis and advisory session.
        </p>
      
    
 
    
    <!-- Bottom: Outside -->
   
    
    
  </div>
 
    
     
   
    <section class="">
        <div class="auto-container ">

           <h4 style="font-weight: 700;"> What is Ncareers?</h4>
             Ncareers is a comprehensive online platform designed to facilitate lifelong learning and career advancement.<br>
            <strong>1. Free Enrollment:</strong>  Start your journey at no cost.<br>
            <strong>2. Limitless Learning:</strong>    Dive into diverse upskilling programs.<br>
            <strong>3. Variety of Opportunities:</strong>   Access a myriad of job openings in Japan.<br>
            <strong> 4. Complimentary Learning:</strong>  Enjoy access to a plethora of free learning programs.<br>
            <strong>5. Tailored Job Openings: </strong>  Find job listings tailored to your preferences.<br>
            <strong>6. Bilingual Resume Creation: </strong> Create your detailed bilingual resume for free. <br>

          
        </div></section>
                
    
		
            
    <!-- <div class="timeline-item seven">
        <div class="timeline-content">
            <h1>Deployment</h1>
          
        </div>
    </div>				
            
    <div class="timeline-item eight">
            <div class="timeline-content right">
            <h1>Release</h1>
            <p>
                Ahead of release we work closely with our partners to incorporate final changes and carry out final testing. At the release stage we'll normally ask you to sign a Service Level Agreement (costed in the Product Quote), detailing the provision for ongoing hosting and maintenance of your project. When everything is ready we'll agree a final release date with you, including any support needed for launch activities.
            </p>
        </div>
    </div>				
    
    
    <div class="timeline-item nine">
            <div class="timeline-content">
            <h1>Post Project</h1>
            <p>
                All of our projects incorporate consideration of long-term hosting and maintenance needs. The individual project requirements are outlined in the project's Service Level Agreement, and we will contact you toward the end of this period to discuss the future of your project. 
            </p>
        </div>
    </div>		 -->
      </div>  
            
             </div>
            </div>

             
         </section>



         <section class="mt-4" id="japanese_training" *ngIf="lng == 'ja'">
            
   

            <p class="para"> IT＆エンジニア

            </p>
            <p class="para1">就職活動のプロセス</p>
            <div class="large-container row" >
             
            
  
<div id="timeline">
    <div class="FadeIn">          
  <div class=" d-md-block d-none">
    <div class="page" style="display: flex; justify-content: center">
  
    
        
        <div class="arrow arrow--1">
            Ncareersに登録
            
        </div>
        <div style="margin-top: 14px;
        color: red;
        font-size: 50px;
    ">
        <i class="fa-solid fa-arrow-right"></i>

        </div>
        <div class="arrow arrow--1">
            履歴書をアップロード
         
          </div>
          <div style="margin-top: 14px;
          color: red;
          font-size: 50px;
      ">
          <i class="fa-solid fa-arrow-right"></i>
  
          </div>
          <div class="arrow arrow--1">
            情報を記入
          </div>
          <div style="margin-top: 14px;
          color: red;
          font-size: 50px;
      ">
          <i class="fa-solid fa-arrow-right"></i>
  
          </div>
          <div class="arrow arrow--1">
            希望の企業に応募！
    
          </div>


        
     
      
       
         
      </div>
  </div>
  <p class=" d-md-block d-none" style="margin-left: 63px;">あなたの適性を理解するために、<a href="mailto:info@nihonedutech.com">  info@nihonedutech.com</a> までEメールをお送りください。また、<a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">履歴書をアップロード</a>すれば、無料でご相談いただけます。</p>
  <!-- <p class=" d-md-block d-none" style="margin-left: 63px;"> あなたの適性を理解するために,<a href="mailto:info@nihonedutech.com">  info@nihonedutech.com</a> You can also upload your CV <a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a> for a free analysis and advisory session.
</p>  -->
<!-- <p class=" d-md-block d-none" style="margin-left: 63px;">Our platform Ncareers help acheiving seamless execution of recruitment and placement activities.</p> -->



  <div class="page d-block d-md-none">
  
    
    <!-- Right: Outside -->
    <div class="arrow arrow--3">
        Ncareersに登録
    </div>
    <div class="arrow arrow--3">
        履歴書をアップロード
        <br><a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">here</a>
      </div>
      <div class="arrow arrow--3">
        情報を記入
      </div>
      <div class="arrow arrow--1">
        希望の企業に応募！
    
      </div>

     
      <p class=" ">あなたの適性を理解するために、<a href="mailto:info@nihonedutech.com">  info@nihonedutech.com</a> までEメールをお送りください。また、<a href="https://nihon.ncareers.org/nCareers/landing-page" target="_blank">履歴書をアップロード</a>していただければ、無料でご相談いただけます。</p>

      
    
 
    
    <!-- Bottom: Outside -->
   
    
    
  </div>
 
    
     
   
    <section class="">
        <div class="auto-container ">

           <h4 style="font-weight: 700;">Ncareersとは?</h4>
           Ncareersは、生涯学習とキャリアアップを促進するために設計されたオンラインプラットフォームです。<br>
            <strong>1. 登録費用0</strong>  <br>
            <strong>2. 豊富な日本語教材</strong>   <br>
            <strong>3. 数多くの日本における就労機会の提供</strong>   <br>
            <strong> 4. 多数の無料日本語教材の提供</strong> <br>
            <strong>5. あなたの好みに合った求人の紹介</strong>  <br>
            <!-- <strong>6. Bilingual Resume Creation: </strong> Create your detailed bilingual resume for free. <br> -->

          
        </div></section>
                
    
		
            
    <!-- <div class="timeline-item seven">
        <div class="timeline-content">
            <h1>Deployment</h1>
          
        </div>
    </div>				
            
    <div class="timeline-item eight">
            <div class="timeline-content right">
            <h1>Release</h1>
            <p>
                Ahead of release we work closely with our partners to incorporate final changes and carry out final testing. At the release stage we'll normally ask you to sign a Service Level Agreement (costed in the Product Quote), detailing the provision for ongoing hosting and maintenance of your project. When everything is ready we'll agree a final release date with you, including any support needed for launch activities.
            </p>
        </div>
    </div>				
    
    
    <div class="timeline-item nine">
            <div class="timeline-content">
            <h1>Post Project</h1>
            <p>
                All of our projects incorporate consideration of long-term hosting and maintenance needs. The individual project requirements are outlined in the project's Service Level Agreement, and we will contact you toward the end of this period to discuss the future of your project. 
            </p>
        </div>
    </div>		 -->
      </div>  
            
             </div>
            </div>

             
         </section>
   

  <div *ngIf="lng == 'ja'" class="modal fade " id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-xl">
      <div class="modal-content main-table-containter" style="margin-top: 75px;">
        <div class="modal-header">
          <h5 class="modal-title" style="color: black; font-weight: 700;" id="staticBackdropLabel">
        </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block d-md-none">
            <img src="assets/images/Screenshot 2024-03-20.png" class="img-fluid"style="height:100%; width: 100%;">
         
          </div>
        <div class="modal-body  d-md-block d-none">
          <!-- <img src="assets/images/Screenshot 2024-02-13 172045.png" class="img-fluid"style="height:100%; width: 100%;"> -->
          <table class="">
            <tbody>
                <tr style="font-size: 15px; color: black;font-weight: bolder; width: 200px;">
                    <td></td>
                    <td style="font-size: 23px;">技能実習
                        </td>
    
                    <td  style="font-size: 23px;">特定技能</td>
                    <!-- <td>Required Certification 
                        </td>
                    <td>Mobility
                        </td>
                        <td>Accompanied
                            </td> -->
                            <!-- <td>Ave. Monthly Wage</td> -->
       
                </tr>

                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder; width: 200px;" title="">
                        
                     期間

                    
                   
                    </td>
                    <td>実習生は、技能実習（TITP）のビザにより、3年間日本で働くことができます。1年以上日本で働き続けるためには、試験に合格する必要があります。合格しなければ就労を続けることはできません。滞在を延長するには、技能実習から特定技能（SSW）に変更し、特定技能ビザを取得する必要があります。
                        </td>
                        <td>特定技能（SSW）のビザを取得すると、最長5年間日本で働くことができます。この5年間は、入国からではなく、特定技能のビザを取得した時点からの5年間です。もっと長く働きたい場合は、特定技能 2号のビザを申請すれば、さらに5年間働くことができます。
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder; width: 200px;" title="">
                        
                        応募に必要な言語資格

                    
                   
                    </td>
                    <td>技能実習生は、ビザ申請に日本語能力証明書は必要ありません。ただし、日本企業との面接では、JLPTN5レベルの能力が求められます。就職後は通常、JLPTN4レベルの能力が求められます。
                        </td>
                        <td>特定技能のビザを申請する場合は、少なくともJLPTN4またはJFT-Basicを取得している必要があります。
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder; width: 200px;" title="">
                        
                        応募に必要な資格

                    
                   
                    </td>
                    <td>技能実習ビザの申請には特定の資格は必要ありません。</td>
                        <td>特定技能のビザを取得するには、特定の業種のスキルテストに合格するか、技能実習を修了する必要があります。これらのいずれかがなければ、特定技能のビザを申請することはできません。
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder; width: 200px;" title="">
                        
                        帰国 

                    
                   
                    </td>
                    <td>技能実習生として日本にいるときは、家族の不幸など緊急の場合を除き、インドへ帰国することはできません。
                        </td>
                        <td>特定技能のビザでは休暇を取ることが許されているため、休暇中にインドに帰国することが可能です。
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder; width: 200px;" title="">
                        
                        帯同者

                    
                   
                    </td>
                    <td>技能実習生は、配偶者、子供、家族を含め、日本に帯同者を連れて行くことはできません。</td>
                        <td>特定技能2号であれば、配偶者と子供を連れて日本に行くことができます。
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                

            

           

    
            </tbody>
        </table>
        </div>
    
      </div>
    </div>
  </div>


 
  <div *ngIf="lng == 'en'" class="modal fade " id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-xl">
      <div class="modal-content main-table-containter" style="margin-top: 75px;">
        <div class="modal-header">
          <h5 class="modal-title" style="color: black; font-weight: 700;" id="staticBackdropLabel">
        </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block d-md-none">
            <img src="assets/images/Screenshot 2024-02-13 172045.png" class="img-fluid"style="height:100%; width: 100%;">
         
          </div>
        <div class="modal-body  d-md-block d-none">
          <!-- <img src="assets/images/Screenshot 2024-02-13 172045.png" class="img-fluid"style="height:100%; width: 100%;"> -->
          <table class="">
            <tbody>
                <tr style="font-size: 15px; color: black;font-weight: bolder;">
                    <td></td>
                    <td style="font-size: 23px;">TITP
                        </td>
    
                    <td  style="font-size: 23px;">SSW</td>
                    <!-- <td>Required Certification 
                        </td>
                    <td>Mobility
                        </td>
                        <td>Accompanied
                            </td> -->
                            <!-- <td>Ave. Monthly Wage</td> -->
       
                </tr>

                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                        
                     Duration

                    
                   
                    </td>
                    <td>The TITP visa allows you to work in Japan for precisely 3
                        years. After the first year, passing an exam is necessary to
                        continue working there. Without passing, you cannot stay
                        employed. To extend your stay, you will need an SSW visa,
                        changing from TITP to SSW status.</td>
                        <td>With the SSW visa, you can work in Japan for up to 5 years.
                            You start counting these 5 years once you get the SSW visa. If
                            you wish to work longer, you can apply for the SSW-2 visa,
                            allowing you to work for another 5 years.
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                        
                        Required Language Certification

                    
                   
                    </td>
                    <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                        </td>
                        <td>If you are applying for an SSW visa, you must have at least
                            JLPT N4 or JFT basics.
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                        
                        Required Certification

                    
                   
                    </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                        <td>To get the SSW visa, you need to pass skill tests for certain
                            industries or complete the TITP. Without one of these, you cannot
                            apply for an SSW visa.
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                        
                        Mobility 

                    
                   
                    </td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.</td>
                        <td>You are allowed to take holidays under the SSW, and you can
                            return to India during the holidays.
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                <tr style="font-size: 15px;" >
                    <td class="icon-content" style="font-size: 15px; color: black; font-weight: bolder;" title="">
                        
                        Accompanied

                    
                   
                    </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you.</td>
                        <td>While you are under the SSW-2, not SSW-1, you can take your
                            spouse and kids to Japan with you.
                            
                        </td>
                    <!-- <td>TITP trainees do not need a Japanese language certificate to
                        apply for the visa. However, Japanese companies expect you
                        to have JLPT N5 proficiency during interviews with them.
                        Once you start working, JLPT N4-level proficiency is usually
                        required.
                         </td>
                    <td>No technical certifications are needed to apply for a TITP visa.</td>
                    <td>When you are in Japan under the TITP, you cannot go back to
                        India unless it is an emergency, such as a family loss.
                        </td>
                    <td>While you are under the TITP, you cannot take anyone,
                        including your spouse, kids, and family, to Japan with you. </td> -->
                    <!-- <td>JPY 178,000 </td> -->
                </tr>
                

            

           

    
            </tbody>
        </table>
        </div>
    
      </div>
    </div>
  </div>


             
         <!-- <section class="faq-section">
             <div class="auto-container">
                 <div class="row clearfix">
                     <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                         <div class="inner-box">
                             <ul class="accordion-box">
                                 <li *ngFor="let item of paragraphs; let i = index" class="accordion block">
                                     <div class="acc-btn">
                                         <div id="pera_expand{{i}}" class="icon-outer"></div>
                                         <h5>{{item.title}}</h5>
                                     </div>
                                     <div class="acc-content">
                                         <div class="text">
                                             <div class="row">
                                                 <div class="col-md-4 col-sm-4 bdr_right">
                                                     <img src="{{item.image}}" />
                                                 </div>
                                                 <div class="col-md-8 col-sm-8 panel_height" [innerHTML]="transformHtml(item.description)">
                                                     
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                             </ul>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'job_in_japan_semi_skill.Gallery' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of images">
                     <ng-template carouselSlide>
                         <div  class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                     </div>
                                     <div class="lower-cont">
                                         <h3>{{item.title}}</h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </ng-template>
                   </ng-container>
             </owl-carousel-o>
         </div> -->
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'job_in_japan_semi_skill.Videos' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of videos">
                     <ng-template carouselSlide>
                         <div class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                         <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                             <i class="flaticon-play"></i>
                                         </a>
                                         <div class="border"></div>
                                     </div>
                                 </div>
                                 <div class="lower-cont">
                                     <h3>{{item.title}}</h3>
                                 </div>
                             </div>
                         </div>
                     </ng-template>  
                 </ng-container>
             </owl-carousel-o>
         </div>
                 
         <section class="testimonial-section bg-color-2">
             <div class="auto-container">
                 <div class="sec-title">
                     <span class="top-title">{{ 'job_in_japan_semi_skill.Student Testimonials' | translate }}</span>
                 </div>
                 <owl-carousel-o [options]="customOptionsStudent">
                     <ng-container *ngFor="let item of studentTestimonial">
                         <ng-template carouselSlide>
                             <div class="testimonial-block-one">
                                 <div class="inner-box">
                                     <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                                     <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                     <div class="author-box">
                                         <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                         <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                     </div>
                                     <div class="text">
                                         <p>{{item.description1}}</p>
                                         <p>{{item.description2}}</p>
                                     </div>
                                 </div>
                             </div> 
                         </ng-template>  
                     </ng-container>
                 </owl-carousel-o>
             </div>
         </section>
        <app-footer></app-footer>
 </div>
