import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | About Us';
  bannerDetails:any={
    title:"",
    description:"",
    image:"",
  }
  aboutData:any;
  teamData:any;
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
    private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
    this.requestService.getPrivacyPolicySeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}

  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getAboutUsBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getAboutUs()
		.pipe(first())
		.subscribe(
			data => {
        console.log('dada',data)
				if(data.status == 1){
          this.aboutData = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getAllTeam()
		.pipe(first())
		.subscribe(
			data => {
        console.log('dada',data)
				if(data.status == 1){
          this.teamData = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
  }

}
