import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Gallery';
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
	}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
  }

}
