import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tech-services',
  templateUrl: './tech-services.component.html',
  styleUrls: ['./tech-services.component.css']
})
export class TechServicesComponent implements OnInit {

	lng = localStorage.getItem('lng');
  title = 'Nihon Edutech Private Limite | Tech Services';
	meta_desc = 'Nihon';
	meta_keyword = 'Nihon';
	slider1_title = "";
	slider1_desc1 = "";
	slider1_img   = "";
	slider1_btn_link = "";

	slider2_title = "";
	slider2_desc1 = "";
	slider2_img   = "";
	slider2_btn_link = "";

	slider3_title = "";
	slider3_desc1 = "";
	slider3_img   = "";
	slider3_btn_link = "";

  partner:any;
  constructor(
    private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService,
		private sanitizer: DomSanitizer,
  ) { 
    let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
    this.requestService.getTechServiceSlider()
			.pipe(first())
			.subscribe(
        data => {
        if(data.status == 1){
          data.data.forEach((item:any)=>{
            if(item.slug == 1){
              this.slider1_title = item.title;
              this.slider1_desc1 = item.description1;
              this.slider1_img =	item.image;
              this.slider1_btn_link =	item.link_button;
            }
            if(item.slug == 2){
              this.slider2_title = item.title;
              this.slider2_desc1 = item.description1;
              this.slider2_img =	item.image;
              this.slider2_btn_link =	item.link_button;
            }
            if(item.slug == 3){
              this.slider3_title = item.title;
              this.slider3_desc1 = item.description1;
              this.slider3_img =	item.image;
              this.slider3_btn_link =	item.link_button;
            }
          })
        }else{
			  }
			},
			error => {
			}
		);
    this.requestService.getHomeSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
    this.requestService.getTechServicePartner()
			.pipe(first())
			.subscribe(
        data => {
          if(data.status == 1){
            this.partner = data.data;
          }else{
          }
			},
			error => {
			}
		);
  }
  transformHtml(value:any) {
	return this.sanitizer.bypassSecurityTrustHtml(value);
}
  	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
	this.metaService.addTags([
		{name: 'description', content: this.meta_desc},
		{name: 'keywords', content: this.meta_keyword},
	]);
  }

}
