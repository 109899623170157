import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;

@Component({
  selector: 'app-get-job',
  templateUrl: './get-job.component.html',
  styleUrls: ['./get-job.component.css']
})
export class GetJobComponent implements OnInit {
  title = 'NihonCareers | Get job Now';
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
	}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
  }

}
