import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';


@Component({
  selector: 'app-jobs-in-japan-prof',
  templateUrl: './jobs-in-japan-prof.component.html',
  styleUrls: ['./jobs-in-japan-prof.component.css']
})
export class JobsInJapanProfComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | IT/Engineering';
  bannerDetails:any={
    title:"",
    description:"",
    image:"",
  }
  engDetails:any={
    image:"",
    description1:"",
    description2:"",
    description3:"",
  }
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
  ) {
    let lng = localStorage.getItem('lng');
      if(lng == null || lng == '' || lng == undefined)lng = 'en';
      translate.setDefaultLang(lng);
      this.requestService.getJIJProfessionalSeo()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.updateTitle(data.data.title);
            this.updateMetaDesc(data.data.description);
            this.updateMetaKey(data.data.keywords);
          }else{
          
          }
        },
        error => {
        }
      );
  }
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getJIJProfessionalBanner()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.bannerDetails = data.data;
          }else{
          
          }
        },
        error => {
        }
      );
    this.requestService.getJIJProfessionalEngineering()
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.engDetails = data.data;
          }else{
          
          }
        },
        error => {
        }
      );
  }

}
