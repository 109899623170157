<!-- <div class="preloader"> -->
    <!-- Add your preloader HTML markup here -->
    <!-- <div class="spinner"></div>
</div> -->

<ul class="loading">
    <li>N</li>
    <li>I</li>
    <li>H</li>
    <li>O</li>
    <li>N</li>
    <li>E</li>
    <li>D</li>
    <li>U</li>
    <li>T</li>
    <li>E</li>
    <li>C</li>
    <li>H</li>
</ul>