import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-ssw-ssp',
  templateUrl: './ssw-ssp.component.html',
  styleUrls: ['./ssw-ssp.component.css']
})
export class SswSspComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Specified Skilled Worker (SSW)';
  bannerDetails:any={
    title:"Specified Skilled Worker (SSW)",
    description:"Bridging for Career in Japan",
    image:"assets/images/background/page-title.jpg",
  }
  sswData:any={
    description:"SSW, the new status of residence introduced by Japan is aimed at addressing the labor shortage in Japan in 14 different trades by accepting foreign nationals with the specific skills and expertise. Following are the different trades and job categories under SSW.",
    image:"assets/images/ssw_img1.jpg"
  }
  sswProcessData:any = {
    description1: "Foreign professionals pass the skills exam and Japanese language examinations.",
    description2: "The professionals then needs to sign an employment contract with an employer (Accepting organization) in Japan.",
    description3: "Accepting organization conducts a pre-departure orientation for the selected candidates.",
    description4: "Accepting organization applies to regional immigration Bureau for the candidate’s certificate of eligibility (COE)", 
    description5: "Based on the COE, the Japanese embassies and Consulates-General in the originating countries issue visa for entry into Japan",
    image: "assets/images/ssw_img2.jpg"
  }
	constructor(
		private titleService: Title,
		private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getSemiSkilledJobSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
	  );
	}
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getSemiSkilledJobBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
    this.requestService.getSemiSkilledJobSSW()
    .pipe(first())
    .subscribe(
      data => {
        if(data.status == 1){
          this.sswData = data.data;
        }else{
        
        }
      },
      error => {
      }
    );
      this.requestService.getSemiSkilledJobProcess()
    .pipe(first())
    .subscribe(
      data => {
        if(data.status == 1){
          this.sswProcessData = data.data;
        }else{
        
        }
      },
      error => {
      }
    );
  }

}
